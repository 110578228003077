import Form from 'react-bootstrap/Form'

function Select({ label, name, children, ...props }) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Select name={name} {...props}>
        {children}
      </Form.Select>
    </Form.Group>
  )
}

export default Select
