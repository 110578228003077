import { DataStore } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { FoiSetting } from '../models'

const useSetting = () => {
  const [setting, setSetting] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchSetting = async () => {
    setLoading(true)
    try {
      const response = await DataStore.query(FoiSetting)

      setSetting(response[0])
    } catch (error) {
      console.log(error)
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchSetting()
  }, [])

  return { setting, error, loading, setSetting, fetchSetting }
}

export default useSetting
