import { Form, Col } from 'react-bootstrap'
import { useState, useEffect } from 'react'

const TextArea = ({
  name,
  label,
  column = 12,
  required = false,
  state,
  validated = false,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (state.length > 1) {
      document.querySelector(`[name="${name}-feedback"]`).style.display = 'none'
      setStatus({ valid: true, invalid: false })
    } else if (state.length < 1) {
      document.querySelector(`[name="${name}-feedback"]`).style.display =
        'block'
      setStatus({ valid: false, invalid: true })
    }
  }, [state, validated, required, name])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <Form.Control
        as="textarea"
        name={name}
        required={required}
        isValid={status.valid}
        isInvalid={status.invalid}
        {...props}
      />
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        This field is required.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default TextArea
