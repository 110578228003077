import React from 'react'

function Hero() {
  return (
    <header className="hero">
      <span className="beta-version">BETA VERSION</span>
      <div className="hero-details">
        <div className="hero-details-wrapper">
          <h1 className="hero-title">People's Corner</h1>
          <h2 className="hero-subtitle">Be Heard! Be Involved!</h2>
          <p className="hero-paragraph">
            Help the city imporve its services by providing us with your
            feedback.
          </p>
          <p className="hero-paragraph">
            All data obtained will be confidential.
          </p>
        </div>
      </div>
    </header>
  )
}

export default Hero
