import moment from 'moment/moment'
import Image from 'react-bootstrap/Image'
import { HiDownload } from 'react-icons/hi'
import Field from '../Field/Field'

const imgExtensions = ['png', 'jpeg', 'jpg']

function Report({
  report,
  departments,
  attachment,
  zoom,
  handleMouseMove,
  view,
  edit = false,
}) {
  return (
    <>
      {(report?.remarks || report?.actionTaken) && !edit && (
        <>
          <Field name="Remarks / Action Taken">
            {report?.remarks && (
              <span className="important">{report?.remarks}</span>
            )}
          </Field>
          {!attachment?.action?.file ? (
            <></>
          ) : imgExtensions.includes(attachment?.action?.extension) ? (
            <figure
              onMouseMove={(e) => handleMouseMove(e, 'action')}
              style={zoom.action}
            >
              <Image
                src={attachment.action.file}
                alt="Report action"
                fluid={true}
              />
            </figure>
          ) : (
            <a
              className="download mb-3"
              href={attachment?.proof?.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              {`${
                report?.actionTaken.split('/').length > 1
                  ? report?.actionTaken.split('/')[1]
                  : report?.actionTaken
              }`}
              <HiDownload />
            </a>
          )}
        </>
      )}
      <Field name="Reference Number">{report?.transactionId}</Field>
      <Field name="Report Date">
        {moment(report?.createdAt).format('lll')}
      </Field>
      {view === 'watch' && (
        <>
          <Field name="Complaint">
            {
              departments?.find((x) => x.id === report?.qcwatchdepartmentID)
                ?.violationName
            }
          </Field>
          <Field name="Voilations"></Field>
        </>
      )}
      {view === 'action' && (
        <Field name="Administrative Complaint / Case against a government employee or official"></Field>
      )}
      <ul>
        {report?.complaints.map((complaint, index) => (
          <li key={index}>{complaint}</li>
        ))}
      </ul>
      <Field name="Details of Complaint">{report?.detailsOfComplaint}</Field>
      <Field name="Proofs/Evidences/Attachments"></Field>
      {!attachment?.proof?.file ? (
        <p>None</p>
      ) : imgExtensions.includes(attachment?.proof?.extension) ? (
        <figure
          onMouseMove={(e) => handleMouseMove(e, 'proof')}
          style={zoom.proof}
        >
          <Image src={attachment.proof.file} alt="Report proof" fluid={true} />
        </figure>
      ) : (
        <a
          className="download mb-3"
          href={attachment?.proof.file}
          target="_blank"
          rel="noopener noreferrer"
        >
          {`${
            report?.attachment.split('/').length > 1
              ? report?.attachment.split('/')[1]
              : report?.attachment
          }`}
          <HiDownload />
        </a>
      )}
    </>
  )
}

export default Report
