import React from 'react'
import LoadingIcon from '../../assets/Spinner.svg'

function Loading() {
  return (
    <div className="loading">
      <img src={LoadingIcon} alt="React Logo" />
      <p>Please wait while loading...</p>
    </div>
  )
}

export default Loading
