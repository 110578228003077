import { HiOutlineRefresh } from 'react-icons/hi'
import Button from './Button'

function Refresh({ refresh }) {
  return (
    <Button color="blue" icon={<HiOutlineRefresh />} onClick={refresh}>
      Refresh
    </Button>
  )
}

export default Refresh
