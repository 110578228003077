import { Storage } from 'aws-amplify'
import { useReducer } from 'react'
import { NotificationManager } from 'react-notifications'
import { ACTION, INITIAL_STATE, ReportReducer } from '../reducer/ReportReducer'
import { setCheckBox } from '../utilities/Helpers'
import { FILE_EXTENSIONS } from '../utilities/Constants'

const useReport = (report = {}, complaints = []) => {
  const [state, dispatch] = useReducer(ReportReducer, INITIAL_STATE)

  const handleFile = async (property, name) => {
    dispatch({
      type: ACTION.HANDLE_ATTACHMENT,
      payload: {
        property: name,
        value: { extension: report[property].split('.').pop().toLowerCase() },
      },
    })
    try {
      const response = await Storage.get(report[property])
      dispatch({
        type: ACTION.HANDLE_ATTACHMENT,
        payload: {
          property: name,
          value: { file: response },
        },
      })
      dispatch({
        type: ACTION.HANDLE_ZOOM,
        payload: {
          property: name,
          value: { backgroundImage: `url(${response})` },
        },
      })
    } catch (error) {
      NotificationManager.error(
        `Problems occurred when retrieving attachment. Please try again later.`
      )
    }
  }

  const handleView = async () => {
    if (report.attachment) {
      handleFile('attachment', 'proof')
    }
    if (report.actionTaken) {
      handleFile('actionTaken', 'action')
    }
    dispatch({ type: ACTION.HANDLE_VIEW })
  }

  const handleCreate = () => {
    dispatch({
      type: ACTION.HANDLE_CREATE,
    })
  }

  const handleUpdate = () => {
    dispatch({
      type: ACTION.HANDLE_UPDATE,
      payload: report,
    })
    handleView()
  }

  const handleCancel = () => {
    dispatch({
      type: ACTION.HANDLE_CANCEL,
    })
  }

  const handleClose = () => {
    dispatch({
      type: ACTION.HANDLE_CLOSE,
    })
  }

  const setAlert = (alert) => {
    dispatch({
      type: ACTION.SET_ALERT,
      payload: alert,
    })
  }

  const handleInput = (e) => {
    if (e.target.name === 'qcwatchdepartmentID') {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: e.target.value,
        },
      })
      dispatch({
        type: ACTION.SET_COMPLAINTS,
        payload: complaints.filter(
          (complaint) => complaint.qcwatchdepartmentID === e.target.value
        ),
      })
    } else if (e.target.name === 'complaints') {
      const newList = setCheckBox(e.target.value, state.report.complaints)
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: newList,
        },
      })
    } else if (
      e.target.name === 'attachment' ||
      e.target.name === 'actionTaken'
    ) {
      const [extension] = e.target.files[0].name.split('.').slice(-1)
      if (!FILE_EXTENSIONS.includes(extension)) {
        setAlert({
          variant: 'danger',
          message: 'The file type is invalid.',
        })
        e.target.value = null
      } else {
        dispatch({
          type: ACTION.HANDLE_INPUT,
          payload: {
            property: e.target.name,
            value: e.target.files[0],
          },
        })
      }
    } else {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: e.target.value,
        },
      })
    }
  }

  const setLoading = () => {
    dispatch({
      type: ACTION.SET_LOADING,
    })
  }

  const setValidated = (value) => {
    dispatch({
      type: ACTION.SET_VALIDATED,
      payload: value,
    })
  }

  const handleMouseMove = (e, name) => {
    const { left, top, width, height } = e.target.getBoundingClientRect()
    const x = ((e.pageX - left) / width) * 100
    const y = ((e.pageY - top) / height) * 100
    dispatch({
      type: ACTION.HANDLE_ZOOM,
      payload: {
        property: name,
        value: { backgroundPosition: `${x}% ${y}%` },
      },
    })
  }

  const checkInputValidity = (required) => {
    for (let item of required) {
      if (item === 'complaints') {
        if (state.report[item].length <= 0) {
          return false
        }
      }

      if (state.report[item] === '') {
        return false
      }
    }
    return true
  }

  return {
    state,
    dispatch,
    handleView,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleClose,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    handleMouseMove,
    checkInputValidity,
  }
}

export default useReport
