import moment from 'moment/moment'
import { useContext, useEffect, useState } from 'react'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { useLocation } from 'react-router-dom'
import Refresh from '../../components/Button/Refresh'
import Filter from '../../components/Filter/Admin/Filter'
import Export from '../../components/QCWatch/Export/Export'
import Edit from '../../components/QCWatch/Form/Edit'
import View from '../../components/QCWatch/Form/View'
import Table from '../../components/Table/Table'
import { UserContext } from '../../context'
import {
  getQCWatchByFilter,
  getQCWatchDepartment,
  getQCitizenById,
} from '../../database'
import { INITIAL_FILTER } from '../../utilities/Constants'

function AdminQCWatch() {
  const { currentUser } = useContext(UserContext)

  const location = useLocation()
  let temp = location.state
  let initialFilter

  if (temp) {
    initialFilter = {
      ...INITIAL_FILTER,
      ...temp,
    }
  } else {
    initialFilter = {
      ...INITIAL_FILTER,
    }
  }

  const [reports, setReports] = useState([])
  const [departments, setDepartments] = useState([])
  const [filter, setFilter] = useState({
    ...initialFilter,
    department: '',
  })

  useEffect(() => {
    if (departments.length < 1) {
      getQCWatchDepartment().then((value) => setDepartments(value))
    } else {
      fetchReports()
    }
  }, [departments])

  const fetchReports = async () => {
    let department = ''
    if (currentUser.attributes['custom:department']) {
      department = departments.find(
        (x) => x.qcdepartmentID === currentUser.attributes['custom:department']
      )?.id
    } else {
      department = filter.department
    }
    try {
      const response = await getQCWatchByFilter({
        ...filter,
        department: department,
      })
      const reports = await Promise.all(
        response.map(async (report) => {
          const citizen = await getQCitizenById(report.qcitizenID)
          return {
            citizen: citizen,
            report: report,
          }
        })
      )
      setReports(reports)
    } catch (error) {
      NotificationManager.error(
        `Problems occurred when retrieving reports. Please try again later.`
      )
    }
  }

  const handleInput = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const columns = [
    {
      name: 'Reference Number',
      width: '200px',
      selector: (row) => row.report.id,
      format: (row) => (
        <View
          report={row.report}
          citizen={row.citizen}
          departments={departments}
          action={false}
        />
      ),
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.citizen.lastName,
      sortable: true,
      format: (row) => (
        <span>{`${row.citizen.lastName}, ${row.citizen.firstName} ${
          row.citizen.middleName ? row.citizen.middleName.charAt(0) + '.' : ''
        }`}</span>
      ),
    },
    {
      name: 'Email',
      selector: (row) => row.citizen.email,
      sortable: true,
    },
    {
      name: 'Remarks / Action Taken',
      selector: (row) => row.report.remarks,
      format: (row) =>
        row.report.remarks
          ? row.report.remarks?.length > 50
            ? row.report.remarks?.substring(0, 50) + '...'
            : row.report.remarks
          : row.report.actionTaken
          ? row.report.actionTaken?.split('/').length > 1
            ? row.report?.actionTaken?.split('/')[1]?.substring(0, 50) + '...'
            : row.report?.actionTaken?.substring(0, 50) + '...'
          : '',
    },
    {
      id: 'createDate',
      name: 'Report Date',
      width: '180px',
      selector: (row) => row.report.createdAt,
      format: (row) => moment(row.report.createdAt).format('lll'),
      sortable: true,
    },
    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <div className="action-wrapper">
          <Edit
            report={row.report}
            citizen={row.citizen}
            departments={departments}
            setReports={setReports}
          />
          <View
            report={row.report}
            citizen={row.citizen}
            departments={departments}
            action={true}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">QCWatch Reports</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchReports} />
          <Export
            reports={reports}
            filename={`QCWATCH_${filter.start}_${filter.end}_Reports`}
            departments={departments}
          />
        </div>
        <Filter
          onChange={handleInput}
          onClick={fetchReports}
          list={departments}
          property="violationName"
          filter={filter}
        />
      </div>
      <Table
        columns={columns}
        data={reports.filter((item) => {
          if (filter.search === '') {
            return item
          } else if (
            item.report.transactionId
              .toLowerCase()
              .includes(filter.search.toLowerCase()) ||
            item.citizen.email
              .toLowerCase()
              .includes(filter.search.toLowerCase())
          ) {
            return item
          }
          return ''
        })}
      />
    </section>
  )
}

export default AdminQCWatch
