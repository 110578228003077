export const PasswordReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_INPUT':
      return {
        ...state,
        password: {
          ...state.password,
          [action.payload.property]: action.payload.value,
        },
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
        validated: false,
        alert: INITIAL_STATE.alert,
      }
    case 'SET_ALERT':
      return {
        ...state,
        loading: false,
        alert: {
          show: true,
          message: action.payload?.message,
          variant: action.payload?.variant,
        },
      }
    case 'SET_VALIDATED':
      return {
        ...state,
        validated: action?.payload ? action.payload : !state.validated,
      }
    case 'CLEAR_PASSWORD':
      return {
        ...state,
        password: {
          current: '',
          new: '',
          confirm: '',
        },
      }
    default:
      return state
  }
}

export const INITIAL_STATE = {
  alert: {
    show: false,
    variant: '',
    message: '',
  },
  loading: false,
  validated: false,
  password: {
    current: '',
    new: '',
    confirm: '',
  },
}

export const ACTION = {
  HANDLE_INPUT: 'HANDLE_INPUT',
  SET_LOADING: 'SET_LOADING',
  SET_ALERT: 'SET_ALERT',
  SET_VALIDATED: 'SET_VALIDATED',
  CLEAR_PASSWORD: 'CLEAR_PASSWORD',
}
