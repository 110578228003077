export const ReportReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_VIEW':
      return {
        ...state,
        loading: false,
        show: true,
      }
    case 'HANDLE_CREATE':
      return {
        ...INITIAL_STATE,
        show: true,
      }
    case 'HANDLE_UPDATE':
      return {
        ...INITIAL_STATE,
        show: true,
        report: action.payload,
      }
    case 'HANDLE_CANCEL':
      return {
        ...state,
        show: false,
      }
    case 'HANDLE_CLOSE':
      return {
        ...state,
        show: false,
      }
    case 'HANDLE_INPUT':
      return {
        ...state,
        report: {
          ...state.report,
          [action.payload.property]: action.payload.value,
        },
      }
    case 'SET_COMPLAINTS':
      return {
        ...state,
        complaints: action.payload,
        report: {
          ...state.report,
          complaints: [],
        },
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
        validated: false,
        alert: INITIAL_STATE.alert,
      }
    case 'SET_ALERT':
      return {
        ...state,
        loading: false,
        alert: {
          show: true,
          message: action.payload?.message,
          variant: action.payload?.variant,
        },
      }
    case 'SET_VALIDATED':
      return {
        ...state,
        validated: action?.payload ? action.payload : !state.validated,
      }
    case 'HANDLE_ATTACHMENT':
      return {
        ...state,
        attachment: {
          ...state.attachment,
          [action.payload.property]: {
            ...state.attachment[action.payload.property],
            ...action.payload.value,
          },
        },
      }
    case 'HANDLE_ZOOM':
      return {
        ...state,
        zoom: {
          ...state.zoom,
          [action.payload.property]: {
            ...state.zoom[action.payload.property],
            ...action.payload.value,
          },
        },
      }
    default:
      return state
  }
}

export const INITIAL_STATE = {
  show: false,
  alert: {
    show: false,
    variant: '',
    message: '',
  },
  loading: false,
  validated: false,
  report: {
    complaints: [],
    detailsOfComplaint: '',
    attachment: '',
    qcwatchdepartmentID: '',
    remarks: '',
    actionTaken: '',
  },
  complaints: [],
  attachment: {},
  zoom: {},
}

export const ACTION = {
  HANDLE_VIEW: 'HANDLE_VIEW',
  HANDLE_CREATE: 'HANDLE_CREATE',
  HANDLE_UPDATE: 'HANDLE_UPDATE',
  HANDLE_CANCEL: 'HANDLE_CANCEL',
  HANDLE_CLOSE: 'HANDLE_CLOSE',
  HANDLE_INPUT: 'HANDLE_INPUT',
  SET_COMPLAINTS: 'SET_COMPLAINTS',
  SET_LOADING: 'SET_LOADING',
  SET_ALERT: 'SET_ALERT',
  SET_VALIDATED: 'SET_VALIDATED',
  HANDLE_ATTACHMENT: 'HANDLE_ATTACHMENT',
  HANDLE_ZOOM: 'HANDLE_ZOOM',
}
