import { memo } from 'react'

function Disclaimer() {
  return (
    <section className="disclaimer">
      <h3 className="disclaimer-title">QCitizen Disclaimer</h3>
      <h4 className="disclaimer-subtitle">
        Disclaimer Regarding Accuracy of Information and Usage of Data
      </h4>
      <hr />
      <div className="disclaimer-content">
        <p>
          Visitors and Users of the Quezon City Government Portal are advised
          that information contained within the Quezon City Government Portal is
          assumed to be accurate. However, errors can occur even with
          computer-generated information. Quezon City Government Portal makes no
          representation regarding the completeness, accuracy, or timeliness of
          such information and data, or that such information and data will be
          error-free. Visitors are encouraged to review the official version of
          all documents on which they plan to rely on.
        </p>
        <p>
          The contents of this Quezon City Government Portal are provided for
          information purposes only. They are not intended and should not be
          construed as business, legal or any form of advice, unless expressly
          stated otherwise. The Quezon City Government Portal manages all
          data/information postings in this Portal but does not guarantee,
          represent, warrant or assume any legal liability or responsibility for
          the accuracy, completeness, reliability, usefulness or timeliness of
          any information, apparatus, product or process provided herein at the
          time of access. Further, Quezon City Government Portal does not accept
          any liability to any person for the direct, indirect, incidental,
          special, or consequential damages that result from the use or misuse
          of any information provided in this Quezon City Government Portal. The
          user shall have sole responsibility for assessing the relevance and
          accuracy of the Quezon City Government Portal’s contents.
        </p>
        <p>
          If the information on Quezon City Government’s official printed
          documents differs from the information contained on the Quezon City
          Government Portal, the information on Quezon City Government’s
          official printed documents will take precedence.
        </p>
        <p>
          The information posted on the Quezon City Government Portal may be
          quoted or reproduced in whole or in part provided that Quezon City
          Government is duly informed/credited. Users are restricted from
          reselling, redistributing, or creating derivative works for commercial
          purposes without the expressed or written consent of Quezon City
          Government.
        </p>
        <p>
          Although the Quezon City Government Portal Team tries to ensure broken
          links do not exist, links to other Internet sites may have changed. If
          you encounter broken links, please report immediately to the website
          administrator for proper action.
        </p>
      </div>
      <hr />
    </section>
  )
}

export default memo(Disclaimer)
