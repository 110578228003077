import { useEffect, useState, useContext } from 'react'
import { Form } from 'react-bootstrap'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import moment from 'moment/moment'
import { UserContext } from '../../context'
import {
  getQCDepartment,
  getQCWatchDepartment,
  getUsers,
} from '../../database'
import Create from '../../components/Account/Form/Create'
import Edit from '../../components/Account/Form/Edit'
import Enable from '../../components/Account/Enable/Enable'
import Disable from '../../components/Account/Disable/Disable'
import Refresh from '../../components/Button/Refresh'
import Table from '../../components/Table/Table'
import View from '../../components/Account/Form/View'

function Account() {
  const { currentUser } = useContext(UserContext)

  const [search, setSearch] = useState('')
  const [users, setUsers] = useState([])
  const [violations, setViolations] = useState([])
  const [departments, setDepartment] = useState([])
  const [loading, setLoading] = useState(true)

  const columns = [
    {
      name: 'Email',
      width: '300px',
      selector: (row) => row.email,
      format: (row) => (
        <View account={row} departments={departments} action={false} />
      ),
      sortable: true,
    },
    {
      name: 'Role',
      selector: (row) => row.role,
      sortable: true,
    },
    {
      name: 'Department',
      selector: (row) => (row.departmentName ? row.departmentName : 'N/A'),
      sortable: true,
    },
    {
      id: 'createDate',
      width: '180px',
      name: 'Create Date',
      selector: (row) => moment(row.createdAt).format('lll'),
      sortable: true,
    },
    {
      name: 'Action',
      width: '160px',
      cell: (row) => (
        <div className="action-wrapper">
          {row.enabled ? (
            <Disable account={row} setUsers={setUsers} />
          ) : (
            <Enable account={row} setUsers={setUsers} />
          )}
          <Edit
            account={row}
            departments={departments}
            setUsers={setUsers}
            violations={violations}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  useEffect(() => {
    fetchAccounts()
    getQCDepartment().then((value) => setDepartment(value))
    getQCWatchDepartment().then((value) => setViolations(value))
  }, [])

  const fetchAccounts = async () => {
    try {
      if (
        !currentUser.attributes['custom:department'] &&
        !currentUser.attributes['custom:group']
      ) {
        const response = await getUsers()
        setUsers(response)
      }

      if (
        currentUser.attributes['custom:department'] &&
        currentUser.attributes['custom:group'] === 'QCFoiAdmin'
      ) {
        const response = await getUsers({
          group: currentUser.attributes['custom:group'],
          department: currentUser.attributes['custom:department'],
          sub: currentUser.attributes.sub
        })
        setUsers(response)
      }
    } catch (error) {
      NotificationManager.error(
        `Problems occurred when retrieving accounts. Please try again later.`
      )
    }
    setLoading(false)
  }

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">Accounts</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchAccounts} />
        </div>
        <div className="table-filter reverse">
          <Form.Group controlId="to">
            <Form.Label>Search</Form.Label>
            <Form.Control
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Email"
            />
          </Form.Group>
          <Form.Group className="margin-top action-wrapper admin-nav">
            <Create
              departments={departments}
              setUsers={setUsers}
              violations={violations}
            />
          </Form.Group>
        </div>
      </div>
      <Table
        columns={columns}
        data={users.filter((item) => {
          if (search === '') {
            return item
          } else if (item.email.toLowerCase().includes(search.toLowerCase())) {
            return item
          }
          return ''
        })}
        loading={loading}
      />
    </section>
  )
}

export default Account
