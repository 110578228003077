import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

const Email = ({
  name,
  label,
  column = 12,
  state,
  validated = false,
  required = false,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })
  const feedback = document.querySelector(`[name="${name}-feedback"]`)

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (
      state.match(
        '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
      ) === null
    ) {
      feedback.style.display = 'block'
      setStatus({ valid: false, invalid: true })
    } else {
      feedback.style.display = 'none'
      setStatus({ valid: true, invalid: false })
    }
  }, [state, validated, required])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <Form.Control
        type="email"
        name={name}
        isValid={status.valid}
        isInvalid={status.invalid}
        required={required}
        {...props}
      />
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        Please use a valid email.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Email
