import { memo } from 'react'
import { HiOutlineExternalLink } from 'react-icons/hi'

function Declaration() {
  return (
    <>
      <p className="text-center">
        <strong>Declaration</strong>
      </p>
      <p>
        <strong>Privacy Notice.</strong> The information submitted in this
        request shall be used by the City to resolve your request in accordance
        with{' '}
        <a
          className="external"
          href="https://quezoncity.gov.ph/public-notice/sp-2863-s-2019an-ordinance-operationalizing-freedom-of-information-in-the-quezon-city-government-and-providing-guidelines-therefor/"
          target="_blank"
          rel="noopener noreferrer"
        >
          City Ordinance No. SP-2863, series of 2019
          <HiOutlineExternalLink />
        </a>
        , or the Quezon City Freedom of Information Ordinance, and its
        Implementing Rules and Regulations. Should the request be granted, the
        information submitted herein, including the name of the Requesting
        Party, may be published online in the City’s official website.
      </p>
      <p>
        Any documents/papers/data/information released by virtue by this request
        shall be used only and exclusively for purposes mentioned by the
        requesting party and as approved by the concerned office. Unauthorized
        use of said documents/papers/data/information shall subject the
        requesting party to appropriate sanctions e.g. administrative, criminal
        or civil liability, in addition to the penalties provided for by the
        Freedom of Information Ordinance (Ordinance No. SP-2863, S-2019) and Its
        Implementing Rules and Regulations and other relevant laws.
      </p>
      <p>
        Some documents may need additional fees for reproduction or issuance of
        certified true copy subject to the rules and regulations of the specific
        agency/office/department concerned.
      </p>
    </>
  )
}

export default memo(Declaration)
