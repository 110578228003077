import {
  useState,
  useEffect,
  useContext
} from 'react'
import moment from 'moment/moment';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import {
  STATUS,
  STATUS_CLASS,
  INITIAL_FILTER
} from '../../utilities/Constants';
import {
  getQCDepartment,
  getQCFoiHistory
} from '../../database'
import { UserContext } from '../../context';
import Filter from '../../components/Filter/Admin/Filter';
import Refresh from '../../components/Button/Refresh';
import History from '../../components/QCFoi/Form/History';
import Table from '../../components/Table/Table';

function QCFoiHistory() {

  const { currentUser } = useContext(UserContext);

  const [history, setHistory] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    ...INITIAL_FILTER,
    department: "",
    status: ""
  });


  useEffect(() => {
    if(departments.length < 1) {
      getQCDepartment()
      .then((value) => setDepartments(value));
    } else {
      fetchHistory();
    }
  }, [departments]);

  const fetchHistory = async () => {
    let department = "";
    if(currentUser.attributes['custom:department']) {
      department = currentUser.attributes['custom:department'];
    } else {
      department = filter.department;
    }
    try {
      const response = await getQCFoiHistory({
        ...filter,
        department: departments.find(x => x.id === department)?.name
      });
      setHistory(response);
    } catch (error) {
      NotificationManager.error(`Problems occurred when retrieving reports. Please try again later.`);
    }
  }

  const handleInput = (e) => {
    setFilter(prevState => ({...prevState, [e.target.name]: e.target.value}));
  }

  const columns = [
    {
      id: 'createDate',
      name: 'Date',
      // width: "180px",
      sortable: true,
      selector: row => row.createdAt,
      format: (row) => moment(row.createdAt).format('lll')
    },
    {
      name: 'Email',
      selector: row => row.initiator,
      sortable: true,
    },
    {
      id: 'Action',
      name: 'Action',
      // width: "180px",
      sortable: true,
      selector: row => row.action
    },
    {
      name: 'Reference Number',
      selector: row => row.transactionId,
      // width: "200px",
      sortable: true,
      format: (row) => <History
        request={ row }
        action={ false }
        admin={ true }
        departments={ departments }
      />,
    },
    {
      name: 'Status',
      width: "150px",
      sortable: true,
      selector: row => row.status,
      format: (row) => STATUS[row.status],
      cell: (row) => <span className={`status ${STATUS_CLASS[row.status]}`}>
        {STATUS[row.status]}
      </span>,
      button: true,
    },
  ];

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">FOI Request History</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchHistory} />
        </div>
        <Filter
          onChange={handleInput}
          list={departments}
          onClick={fetchHistory}
          property="name"
          filter={filter}
        />
      </div>
      <Table
        columns={columns}
        data={history.filter((item) => {
          if (filter.search === '') {
            return item
          } else if (
            item.transactionId
              .toLowerCase()
              .includes(filter.search.toLowerCase()) ||
            item.initiator.toLowerCase().includes(filter.search.toLowerCase())
          ) {
            return item
          }
          return ''
        })}
      />
    </section>
  )
}

export default QCFoiHistory
