import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import moment from 'moment/moment'
import {
  ExtendHistory,
  FileCounter,
  FileLog,
  FoiSetting,
  Message,
  QCAction,
  QCActionComplaintTypes,
  QCDepartment,
  QCFoi,
  QCFoiHistory,
  QCWatch,
  QCWatchComplaintTypes,
  QCWatchDepartment,
  QCitizen,
  User,
} from '../models'

/******************************
 * QCitizen Start              *
 ******************************/
async function createQCitizen(data) {
  const response = await DataStore.save(new QCitizen(data))
  return response
}

async function updateQCitizen(id, data) {
  const citizen = await DataStore.query(QCitizen, id)
  const response = await DataStore.save(
    QCitizen.copyOf(citizen, (item) => {
      item.authority = data.authority ? data.authority : item.authority
      item.mobileNumber = data.mobileNumber
        ? data.mobileNumber
        : item.mobileNumber
      item.title = data.title ? data.title : item.title
      item.orgName = data.orgName ? data.orgName : item.orgName
      item.idPresented = data.idPresented ? data.idPresented : item.idPresented
      item.occupation = data.occupation ? data.occupation : item.occupation
    })
  )
  return response
}

async function getQCitizenById(id) {
  const response = await DataStore.query(QCitizen, id)
  return response
}

async function getQCitizenByUserId(userId) {
  const response = await DataStore.query(
    QCitizen,
    (c) => c.userId('eq', userId),
    Predicates.ALL,
    {
      sort: (s) => s.createdAt(SortDirection.ASCENDING),
    }
  )
  return response
}
/******************************
 * QCitizen End                *
 ******************************/

/******************************
 * QCAction Start              *
 ******************************/
async function createQCAction(data) {
  const report = await DataStore.query(QCAction)
  const response = await DataStore.save(
    new QCAction({
      ...data,
      transactionId: 'QCA-' + moment().format('YYYYMMDD') + (report.length + 1),
    })
  )
  return response
}

async function getQCAction(start = '', end = '', userId = '') {
  if (userId !== '' && start !== '' && end !== '') {
    const response = await DataStore.query(QCAction, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
        .userId('eq', userId)
    )
    return response
  }
  if (start !== '' && end !== '') {
    const response = await DataStore.query(QCAction, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
    )
    return response
  }
  const response = await DataStore.query(QCAction)
  return response
}

async function updateQCAction(id, data) {
  const report = await DataStore.query(QCAction, id)
  const response = await DataStore.save(
    QCAction.copyOf(report, (item) => {
      item.remarks = data.remarks ? data.remarks : item.remarks
      item.actionTaken = data.actionTaken ? data.actionTaken : item.actionTaken
    })
  )
  return response
}

async function getQCActionComplaintTypes() {
  const response = await DataStore.query(QCActionComplaintTypes)
  return response
}
/******************************
 * QCAction End                *
 ******************************/

/******************************
 * QCFoi Start                 *
 ******************************/
async function createQCFoi(data) {
  const request = await DataStore.query(QCFoi)
  const response = await DataStore.save(
    new QCFoi({
      ...data,
      transactionId:
        data.transactionId + moment().format('YYYYMMDD') + (request.length + 1),
      originalDepartment: data.qcdepartmentID,
      forwardCount: 0,
      receivedAt: moment().format('YYYY-MM-DD'),
      status: '0',
    })
  )
  return response
}

async function getQCFoiByUserId(userId) {
  const response = await DataStore.query(QCFoi, (c) =>
    c.qcitizenID('eq', userId)
  )
  return response
}

async function updateQCFoi(oldData, newData) {
  const request = await DataStore.query(QCFoi, oldData.id)
  const response = await DataStore.save(
    QCFoi.copyOf(request, (item) => {
      item.status = newData.status
      item.remarks = newData.remarks
      item.copy = newData.copy
      item.attachment = newData.attachment
      item.completeDate = ['3', '5', '6'].includes(newData.status)
        ? moment().format('YYYY-MM-DD')
        : null
    })
  )
  return response
}

async function updateQCFoiStatus(id, status, message) {
  const request = await DataStore.query(QCFoi, id)
  const response = await DataStore.save(
    QCFoi.copyOf(request, (item) => {
      item.status =
        status === null || status === undefined ? item.status : status
      item.message = message === undefined ? item.message : message
      item.completeDate = ['3', '5', '6'].includes(status)
        ? moment().format('YYYY-MM-DD')
        : null
    })
  )
  return response
}

async function updateQCFoiDeadline(oldData, newData) {
  const request = await DataStore.query(QCFoi, oldData.id)
  const response = await DataStore.save(
    QCFoi.copyOf(request, (item) => {
      item.deadline = newData.deadline
    })
  )
  return response
}

async function updateQCFoiDepartment(id, department, deadline) {
  const request = await DataStore.query(QCFoi, id)
  const response = await DataStore.save(
    QCFoi.copyOf(request, (item) => {
      item.qcdepartmentID = department
      item.forwardCount = item.forwardCount + 1
      item.receivedAt = moment().format('YYYY-MM-DD')
      item.deadline = deadline ? deadline : item.deadline
      item.status = '2'
      item.message = 'citizen'
    })
  )
  return response
}

async function getQCFoi(start = '', end = '', department = '', userId = '') {
  if (userId !== '' && start !== '' && end !== '') {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
        .userId('eq', userId)
    )
    return response
  }
  if (start !== '' && end !== '' && department !== '') {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
        .qcdepartmentID('eq', department)
    )
    return response
  }
  if (start !== '' && end !== '') {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
    )
    return response
  }
  const response = await DataStore.query(QCFoi)
  return response
}

async function getQCFoiByFilter(filter, processor = false) {
  if (!filter.department && !filter.status) {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
    )
    return response
  }

  if (filter.department && filter.status) {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .qcdepartmentID('eq', filter.department)
        .status('eq', filter.status)
    )
    return response
  }

  if (filter.department && !filter.status) {
    if (processor) {
      const response = await DataStore.query(QCFoi, (c) =>
        c
          .createdAt(
            'ge',
            moment(`${filter.start}T00:00:00.000Z`)
              .subtract(8, 'hours')
              .toISOString()
          )
          .createdAt(
            'le',
            moment(`${filter.end}T23:59:59.999Z`)
              .subtract(8, 'hours')
              .toISOString()
          )
          .qcdepartmentID('eq', filter.department)
          .status('ge', 4)
      )
      return response
    }

    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .qcdepartmentID('eq', filter.department)
    )
    return response
  }

  if (!filter.department && filter.status) {
    const response = await DataStore.query(QCFoi, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .status('eq', filter.status)
    )
    return response
  }
}

async function checkUserQCFoi(id) {
  const today = moment().format('YYYY-MM-DD')
  const response = await DataStore.query(QCFoi, (c) =>
    c
      .createdAt(
        'ge',
        moment(`${today}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
      )
      .createdAt(
        'le',
        moment(`${today}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
      )
      .qcitizenID('eq', id)
  )
  return response.length
}

async function checkUserQCAction(id) {
  const today = moment().format('YYYY-MM-DD')
  const response = await DataStore.query(QCAction, (c) =>
    c
      .createdAt(
        'ge',
        moment(`${today}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
      )
      .createdAt(
        'le',
        moment(`${today}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
      )
      .qcitizenID('eq', id)
  )
  return response.length
}

async function checkUserQCWatch(id) {
  const today = moment().format('YYYY-MM-DD')
  const response = await DataStore.query(QCWatch, (c) =>
    c
      .createdAt(
        'ge',
        moment(`${today}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
      )
      .createdAt(
        'le',
        moment(`${today}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
      )
      .qcitizenID('eq', id)
  )
  return response.length
}
/******************************
 * QCFoi End                   *
 ******************************/

/******************************
 * QCDepartment Start          *
 ******************************/
async function createQCDepartment(data) {
  const response = await DataStore.save(new QCDepartment(data))
  return response
}

async function updateQCDepartment(id, data) {
  const department = await DataStore.query(QCDepartment, id)
  const response = await DataStore.save(
    QCDepartment.copyOf(department, (item) => {
      item.code = data.code ? data.code : item.code
      item.name = data.name ? data.name : item.name
    })
  )
  return response
}

async function getQCDepartment() {
  const response = await DataStore.query(QCDepartment, Predicates.ALL, {
    sort: (s) => s.name(SortDirection.ASCENDING),
  })
  return response
}
/******************************
 * QCDepartment End            *
 ******************************/

/******************************
 * QCWatch Start               *
 ******************************/
async function createQCWatch(data) {
  const report = await DataStore.query(QCWatch)
  const response = await DataStore.save(
    new QCWatch({
      ...data,
      transactionId:
        'QCW-' + +moment().format('YYYYMMDD') + (report.length + 1),
    })
  )
  return response
}

async function updateQCWatch(id, data) {
  const report = await DataStore.query(QCWatch, id)
  const response = await DataStore.save(
    QCWatch.copyOf(report, (item) => {
      item.remarks = data.remarks ? data.remarks : item.remarks
      item.actionTaken = data.actionTaken ? data.actionTaken : item.actionTaken
    })
  )
  return response
}

async function getQCWatch(start = '', end = '', userId = '') {
  if (userId !== '' && start !== '' && end !== '') {
    const response = await DataStore.query(QCWatch, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
        .userId('eq', userId)
    )
    return response
  }
  if (start !== '' && end !== '') {
    const response = await DataStore.query(QCWatch, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
        )
        .createdAt(
          'le',
          moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
        )
    )
    return response
  }
  const response = await DataStore.query(QCWatch)
  return response
}

async function getQCWatchDepartment() {
  const response = await DataStore.query(QCWatchDepartment, Predicates.ALL, {
    sort: (s) => s.createdAt(SortDirection.ASCENDING),
  })
  return response
}

async function getQCWatchByUserId(userId) {
  const response = await DataStore.query(QCWatch, (c) =>
    c.qcitizenID('eq', userId)
  )
  return response
}

async function getQCWatchComplaintTypes() {
  const response = await DataStore.query(QCWatchComplaintTypes)
  return response
}

async function getQCWatchByFilter(filter) {
  if (filter.department === '') {
    const response = await DataStore.query(QCWatch, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
    )
    return response
  }
  const response = await DataStore.query(QCWatch, (c) =>
    c
      .createdAt(
        'ge',
        moment(`${filter.start}T00:00:00.000Z`)
          .subtract(8, 'hours')
          .toISOString()
      )
      .createdAt(
        'le',
        moment(`${filter.end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
      )
      .qcwatchdepartmentID('eq', filter.department)
  )
  return response
}

/******************************
 * QCWatch End                 *
 ******************************/

/******************************
 * Message Start          *
 ******************************/
async function createMessage(data) {
  const response = await DataStore.save(new Message(data))
  return response
}

async function getMessage(id) {
  const response = await DataStore.query(Message, (c) => c.qcfoiID('eq', id))
  return response
}
/******************************
 * Message End            *
 ******************************/

/******************************
 * QCFoiHistory Start          *
 ******************************/
async function createQCFoiHistory(action, initiator, department, data) {
  let newData = { ...data }
  newData.action = action
  newData.initiator = initiator
  newData.department = department
  // newData.department = oldData?.department
  // ? (oldData?.department !== department
  //   ? oldData?.department + " -> " + department
  //   : department)
  // : department;
  // newData.status = oldData?.status
  // ? (oldData?.status !== data.status
  //   ? oldData?.status + " -> " + data.status
  //   : data.status)
  // : data.status;
  // newData.copy = oldData?.copy
  // ? (oldData?.copy !== data.copy
  //   ? oldData?.copy + " -> " + data.copy
  //   : data.copy)
  // : data.copy;
  newData.qcfoiID = data.id
  delete newData.id
  delete newData._deleted
  delete newData._version
  delete newData._lastChangedAt
  delete newData.qcitizenID
  delete newData.userId
  delete newData.createdAt
  delete newData.updatedAt
  const response = await DataStore.save(new QCFoiHistory(newData))
  return response
}

async function getQCFoiHistory(filter, processor = false) {
  if (!filter.department && !filter.status) {
    const response = await DataStore.query(QCFoiHistory, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
    )
    return response
  }

  if (filter.department && filter.status) {
    const response = await DataStore.query(QCFoiHistory, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .department('eq', filter.department)
        .status('eq', filter.status)
    )
    return response
  }

  if (filter.department && !filter.status) {
    if (processor) {
      const response = await DataStore.query(QCFoiHistory, (c) =>
        c
          .createdAt(
            'ge',
            moment(`${filter.start}T00:00:00.000Z`)
              .subtract(8, 'hours')
              .toISOString()
          )
          .createdAt(
            'le',
            moment(`${filter.end}T23:59:59.999Z`)
              .subtract(8, 'hours')
              .toISOString()
          )
          .department('eq', filter.department)
          .status('ge', 4)
      )
      return response
    }

    const response = await DataStore.query(QCFoiHistory, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .department('eq', filter.department)
    )
    return response
  }

  if (!filter.department && filter.status) {
    const response = await DataStore.query(QCFoiHistory, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .status('eq', filter.status)
    )
    return response
  }
}
/******************************
 * QCFoiHistory End            *
 ******************************/

/******************************
 * ExtendHistory Start          *
 ******************************/
async function createExtendRecord(data) {
  const response = await DataStore.save(
    new ExtendHistory({
      ...data,
      days: parseInt(data.days),
    })
  )
  return response
}

async function getExtendHistory(id) {
  if (id !== undefined) {
    const response = await DataStore.query(ExtendHistory, (c) => c.id('eq', id))
    return response
  }

  const response = await DataStore.query(ExtendHistory)
  return response
}
/******************************
 * ExtendHistory End            *
 ******************************/

/******************************
 * FileLog Start          *
 ******************************/
async function createFileLog(data) {
  const response = await DataStore.save(new FileLog(data))
  const record = await DataStore.query(FileCounter, (c) =>
    c.filename('eq', response.filename)
  )

  if (record.length === 1) {
    DataStore.save(
      FileCounter.copyOf(record[0], (item) => {
        item.count = item.count + 1
      })
    )
  }

  if (record.length === 0) {
    DataStore.save(
      new FileCounter({
        filename: response.filename,
        count: 1,
      })
    )
  }

  return response
}

async function getFileLog(filter) {
  if (filter.department) {
    const response = await DataStore.query(FileLog, (c) =>
      c
        .createdAt(
          'ge',
          moment(`${filter.start}T00:00:00.000Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .createdAt(
          'le',
          moment(`${filter.end}T23:59:59.999Z`)
            .subtract(8, 'hours')
            .toISOString()
        )
        .department('eq', filter.department)
    )
    return response
  }

  const response = await DataStore.query(FileLog, (c) =>
    c
      .createdAt(
        'ge',
        moment(`${filter.start}T00:00:00.000Z`)
          .subtract(8, 'hours')
          .toISOString()
      )
      .createdAt(
        'le',
        moment(`${filter.end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
      )
  )
  return response
}

/******************************
 * FileLog End            *
 ******************************/

/******************************
 * FileLog Start          *
 ******************************/
async function getFoiSetting() {
  const response = await DataStore.query(FoiSetting)
  return response[0]
}

async function updateFoiSetting(id, data) {
  const request = await DataStore.query(FoiSetting, id)
  const response = await DataStore.save(
    FoiSetting.copyOf(request, (item) => {
      item.limit = data.limit ? data.limit : item.limit
      item.expected = data.expected ? data.expected : item.expected
      item.extension = data.extension ? data.extension : item.extension
      item.expiration = data.expiration ? data.expiration : item.expiration
    })
  )
  return response
}
/******************************
 * FileLog End            *
 ******************************/
/******************************
 * Utilities Start          *
 ******************************/
async function createQCActionComplaintTypes(data) {
  const response = await DataStore.save(new QCActionComplaintTypes(data))
  return response
}

async function createQCWatchComplaintTypes(data) {
  const response = await DataStore.save(new QCWatchComplaintTypes(data))
  return response
}
/******************************
 * Utilities End            *
 ******************************/

/******************************
 * Users Start          *
 ******************************/
async function getUsers(filter) {
  if (filter) {
    const response = await DataStore.query(User, (c) =>
      c
        .or((p) => p.group('eq', 'QCFoiApprover').group('eq', 'QCFoiProcessor'))
        .department('eq', filter.department)
        .sub('ne', filter.sub)
    )
    return response
  }

  const response = await DataStore.query(User)
  return response
}

async function getUser(sub) {
  const users = await DataStore.query(User, (c) => c.sub('ge', sub))
  return users[0]
}

async function createUser(data) {
  const response = await DataStore.save(new User(data))
  return response
}

async function updateUser(id, data) {
  const user = await DataStore.query(User, id)
  const response = await DataStore.save(
    User.copyOf(user, (item) => {
      item.role = data.role ? data.role : item.role
      item.group = data.group ? data.group : item.group
      item.status = data.status ? data.status : item.status
      item.enabled = data.enabled
      item.department = data.department ? data.department : item.department
      item.departmentName = data.departmentName
        ? data.departmentName
        : item.departmentName
    })
  )
  return response
}
/******************************
 * Users End            *
 ******************************/

export {
  checkUserQCAction,
  checkUserQCFoi,
  checkUserQCWatch,
  createExtendRecord,
  createFileLog,
  createMessage,
  createQCAction,
  createQCActionComplaintTypes,
  createQCDepartment,
  createQCFoi,
  createQCFoiHistory,
  createQCWatch,
  createQCWatchComplaintTypes,
  createQCitizen,
  createUser,
  getExtendHistory,
  getFileLog,
  getFoiSetting,
  getMessage,
  getQCAction,
  getQCActionComplaintTypes,
  getQCDepartment,
  getQCFoi,
  getQCFoiByFilter,
  getQCFoiByUserId,
  getQCFoiHistory,
  getQCWatch,
  getQCWatchByFilter,
  getQCWatchByUserId,
  getQCWatchComplaintTypes,
  getQCWatchDepartment,
  getQCitizenById,
  getQCitizenByUserId,
  getUser,
  getUsers,
  updateFoiSetting,
  updateQCAction,
  updateQCDepartment,
  updateQCFoi,
  updateQCFoiDeadline,
  updateQCFoiDepartment,
  updateQCFoiStatus,
  updateQCWatch,
  updateQCitizen,
  updateUser,
}
