import moment from 'moment'
import { useState } from 'react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import { useLocation, useNavigate } from 'react-router-dom'
import Appointment from '../components/Appointment/Appointment'
import Menu from '../components/Button/Menu'
import Refresh from '../components/Button/Refresh'
import Filter from '../components/Filter/Citizen/Filter'
import Create from '../components/QCFoi/Form/Create'
import Message from '../components/QCFoi/Form/Message'
import View from '../components/QCFoi/Form/View'
import Table from '../components/Table/Table'
import useCitizen from '../hooks/useCitizen'
import useDepartment from '../hooks/useDepartment'
import useFoi from '../hooks/useFoi'
import useSetting from '../hooks/useSetting'
import { INITIAL_FILTER, STATUS, STATUS_CLASS } from '../utilities/Constants'

function QCFoi() {
  const location = useLocation()
  let temp = location.state

  const { citizen, error, setCitizen } = useCitizen(temp)
  const { departments } = useDepartment('foi')
  const { setting } = useSetting()
  const [filter, setFilter] = useState({
    ...INITIAL_FILTER,
    userId: temp?.userId,
  })
  const { foi, loading, setFoi, fetchFoi } = useFoi(filter)

  const navigate = useNavigate()

  const columns = [
    {
      name: 'Reference Number',
      selector: (row) => row.transactionId,
      width: '200px',
      sortable: true,
      format: (row) => (
        <View
          request={row}
          action={false}
          departments={departments}
          setting={setting}
        />
      ),
    },
    {
      name: 'Information Requested',
      selector: (row) => row.requestedInformation,
      format: (row) =>
        row.requestedInformation.length > 50
          ? row.requestedInformation.substring(0, 50) + '...'
          : row.requestedInformation,
    },
    {
      name: 'Specific Purpose',
      selector: (row) => row.purposeOfRequest,
      format: (row) =>
        row.purposeOfRequest.length > 50
          ? row.purposeOfRequest.substring(0, 50) + '...'
          : row.purposeOfRequest,
    },
    {
      id: 'createDate',
      name: 'Request Date',
      width: '180px',
      sortable: true,
      selector: (row) => row.createdAt,
      format: (row) => moment(row.createdAt).format('lll'),
    },
    {
      name: 'Status',
      width: '150px',
      sortable: true,
      selector: (row) => row.status,
      format: (row) => STATUS[row.status],
      cell: (row) => (
        <span className={`status ${STATUS_CLASS[row.status]}`}>
          {STATUS[row.status]}
        </span>
      ),
      button: true,
    },
    {
      name: 'Action',
      width: '360px',
      cell: (row) => (
        <div className="action-wrapper flex-end">
          {row.status === '4' && row.copy === '1' && (
            <Appointment request={row} user={citizen} />
          )}
          <Message
            request={row}
            email={citizen.email}
            citizen={citizen}
            side="citizen"
            departments={departments}
            setRequests={setFoi}
          />
          <View
            request={row}
            action={true}
            departments={departments}
            setting={setting}
          />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const handleInput = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  if (error) {
    navigate(-1)
  }

  return (
    <div className="citizen-table">
      <div className="table-border">
        <NotificationContainer />
        <h1 className="qcitizen-title qcaction">QCFoi Requests</h1>
        <div className="table-options">
          <div className="margin-top action-wrapper nav">
            <Menu navigate={navigate} />
            <Refresh refresh={fetchFoi} />
          </div>
          <Filter
            onClick={fetchFoi}
            onChange={handleInput}
            action={true}
            filter={filter}
          >
            <div className="margin-top action-wrapper">
              <Create
                user={citizen}
                departments={departments}
                setRequests={setFoi}
                setUser={setCitizen}
                setting={setting}
              />
            </div>
          </Filter>
        </div>
        <Table
          columns={columns}
          loading={loading}
          data={foi.filter((item) => {
            if (filter.search === '') {
              return item
            } else if (
              item.transactionId
                .toLowerCase()
                .includes(filter.search.toLowerCase())
            ) {
              return item
            }
            return ''
          })}
        />
      </div>
    </div>
  )
}

export default QCFoi
