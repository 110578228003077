import { Modal, Alert } from 'react-bootstrap'

function Body({ show = false, variant, message, children }) {
  return (
    <Modal.Body>
      <Alert show={show} variant={variant}>
        <p>{message}</p>
      </Alert>
      {children}
    </Modal.Body>
  )
}

export default Body
