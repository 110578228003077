import { useContext } from 'react'
import Search from '../Search'
import Date from '../Date'
import Button from '../Button'
import Select from '../Select'
import { UserContext } from '../../../context'

function Filter({
  onChange,
  list = [],
  onClick,
  property,
  foi = false,
  action = false,
  filter,
  children,
}) {
  const { currentUser, currentUserGroups } = useContext(UserContext)

  return (
    <div className="table-filter">
      <Search onChange={onChange} />
      {!action && !currentUser.attributes['custom:department'] && (
        <Select
          name="department"
          label={`${property === 'name' ? 'Department' : 'Violation'}`}
          onChange={onChange}
          value={filter.department}
        >
          <option value="">All</option>
          {list.map((department) => (
            <option value={department.id} key={department.id}>
              {department[property]}
            </option>
          ))}
        </Select>
      )}
      {foi && (
        <Select
          name="status"
          label="Status"
          onChange={onChange}
          defaultValue={filter.status}
        >
          <option value="">All</option>
          {!currentUserGroups.find((x) => x === 'QCFoiProcessor') && (
            <>
              <option value="0">Pending</option>
              <option value="1">Replied</option>
              <option value="2">Forwarded</option>
              <option value="3">Denied</option>
            </>
          )}
          <option value="4">Approved for Processing</option>
          <option value="5">Partially Granted</option>
          <option value="6">Granted</option>
        </Select>
      )}
      <Date
        name="start"
        label="From"
        onChange={onChange}
        defaultValue={filter.start}
      />
      <Date
        name="end"
        label="to"
        onChange={onChange}
        defaultValue={filter.end}
      />
      <div className="search-add">
        <Button onClick={onClick} />
        {children}
      </div>
    </div>
  )
}

export default Filter
