import { useLocation, Outlet, Navigate } from 'react-router-dom'

function CheckGroups({ groups }) {
  const location = useLocation()
  const adminRoutes = {
    '/admin/dashboard': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch',
      'QCFoiAdmin': '/admin/qcfoi',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/settings': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch',
      'QCFoiAdmin': '/admin/qcfoi',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/accounts': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/department': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch',
      'QCFoiAdmin': '/admin/qcfoi',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/qcwatch': {
      'QCActionAdmin': '/admin/qcaction',
      'QCFoiAdmin': '/admin/qcfoi',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/qcaction': {
      'QCWatchAdmin': '/admin/qcwatch',
      'QCFoiAdmin': '/admin/qcfoi',
      'QCFoiApprover': '/admin/qcfoi',
      'QCFoiProcessor': '/admin/qcfoi'
    },
    '/admin/qcfoi': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch'
    },
    '/admin/history': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch'
    },
    '/admin/report': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch'
    },
    '/admin/filelog': {
      'QCActionAdmin': '/admin/qcaction',
      'QCWatchAdmin': '/admin/qcwatch'
    }
  };

  for (const [adminRoute, adminGroupRoutes] of Object.entries(adminRoutes)) {
    if (location.pathname === adminRoute) {
      for (const [group, route] of Object.entries(adminGroupRoutes)) {
        if (groups.includes(group)) {
          return <Navigate to={route} />;
        }
      }
    }
  }

  return <Outlet />;
}

export default CheckGroups;
