import { HiOutlineArrowLeft } from 'react-icons/hi'
import Button from './Button'

function MainMenu({ navigate }) {
  return (
    <Button
      color="blue"
      icon={<HiOutlineArrowLeft />}
      onClick={() => navigate(-1)}
    >
      Main Menu
    </Button>
  )
}

export default MainMenu
