import { memo } from 'react'
import { HiOutlineExternalLink } from 'react-icons/hi'

function Exception() {
  return (
    <>
      <p className="text-center">
        <strong>Exceptions to Right of Access to Information</strong>
      </p>
      <p className="text-center">
        For the guidance of all government offices and instrumentalities covered
        by{' '}
        <a
          className="external"
          href="https://www.officialgazette.gov.ph/2016/07/23/executive-order-no-02-s-2016/"
          target="_blank"
          rel="noopener noreferrer"
        >
          EO No. 2 (s. 2016)
          <HiOutlineExternalLink />
        </a>{' '}
        and the general public, the following are the exceptions to the right of
        access to information as recognized by the Constitution, existing laws,
        or jurisprudence:
      </p>
      <ol>
        <li>Information covered by Executive privilege;</li>
        <li>
          Privileged information relating to national security, defense, or
          international relations;
        </li>
        <li>
          Information concerning law enforcement and protection of public
          personal safety;
        </li>
        <li>
          Information deemed confidential for the protection of the privacy of
          persons and certain individuals such as minors, victims of crimes, or
          the accused;
        </li>
        <li>
          Information, documents or records known by reason of official capacity
          and are deemed as confidential, including those submitted or disclosed
          by entities to government agencies, tribunals, boards, or officers, in
          relation to the performance of their functions, or to inquiries or
          investigation conducted by them in the exercise of their
          administrative, regulatory or quasi-judicial powers;
        </li>
        <li>Prejudicial premature disclosure;</li>
        <li>
          Records of proceedings or information from proceedings which, pursuant
          to law or relevant rules and regulations, are treated as confidential
          or privileged;
        </li>
        <li>
          Matters considered confidential under banking and finance laws, and
          their amendatory laws; and
        </li>
        <li>
          Other exceptions to the right to information under laws,
          jurisprudence, rules and regulation
        </li>
      </ol>
      <p className="text-center text-danger">
        These exceptions only apply to governmental bodies within the control
        and supervision of the Executive department. Unless specifically
        identified, these exceptions may be invoked by all officials, officers,
        or employees in the Executive branch in possession of the relevant
        records or information.
      </p>
    </>
  )
}

export default memo(Exception)
