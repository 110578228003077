import { useState, useEffect, useContext } from 'react'
import { Form } from 'react-bootstrap'
import moment from 'moment-business-days'
import {
  HiOutlineClock,
  HiOutlineMail,
  HiOutlineSwitchHorizontal,
  HiOutlineXCircle,
  HiOutlineCheckCircle,
  HiOutlineClipboardCheck,
  HiOutlineInformationCircle,
  HiOutlineFilter,
} from 'react-icons/hi'
import { UserContext } from '../../context'
import { getQCFoi, getQCDepartment } from '../../database'
import {
  MONTHS,
  STATUS,
  DISPLAY,
  QUARTER,
  MONTHS_FULL,
  QUARTER_MONTH,
} from '../../utilities/Constants'
import { getYears, getDaysInMonth } from '../../utilities/Helpers'
import Bar from '../../components/Chart/Bar'
import Card from '../../components/Card/Admin/Card'
import Pie from '../../components/Chart/Pie'
import Select from '../../components/Filter/Select'
import Export from '../../components/Report/Export/Export'

const INITIAL_BAR = {
  Pending: 0,
  Replied: 0,
  Forwarded: 0,
  Denied: 0,
  'Approved for Processing': 0,
  'Partially Granted': 0,
  Granted: 0,
}

const INITIAL_PIE = {
  'Responded within 1-7 days': 0,
  'Responded within 8-14 days': 0,
  'Responded within 15+ days': 0,
}

const ICON = [
  <HiOutlineClock />,
  <HiOutlineMail />,
  <HiOutlineSwitchHorizontal />,
  <HiOutlineXCircle />,
  <HiOutlineCheckCircle />,
  <HiOutlineClipboardCheck />,
  <HiOutlineClipboardCheck />,
]

function Report() {
  const { currentUser, currentUserGroups } = useContext(UserContext)

  const years = getYears()
  const format = 'YYYY-MM-DD'

  const [requests, setRequests] = useState(INITIAL_BAR)
  const [filter, setFilter] = useState({
    display: 0,
    quarter: '',
    start: 0,
    end: 11,
    year: moment().year(),
    department: currentUser.attributes['custom:department']
      ? currentUser.attributes['custom:department']
      : '',
  })
  const [total, setTotal] = useState(0)
  const [departments, setDepartment] = useState([])
  const [pieData, setPieData] = useState([])
  const [responseData, setResponseData] = useState([])
  const [data, setData] = useState([])
  const [label, setLabel] = useState('Month')
  const [date, setDate] = useState({
    start: moment(`${moment().year()}-01-01`).format(format),
    end: moment(`${moment().year()}-12-31`).format(format),
  })

  useEffect(() => {
    getQCDepartment().then((response) => setDepartment(response))
    fetchData()
  }, [])

  const fetchData = () => {
    if (filter.display === 0) {
      setLabel('Month')
      setDate({
        start: moment(`${filter.year}-${filter.start + 1}-01`, format).format(
          format
        ),
        end: moment(
          `${filter.year}-${filter.end + 1}-${getDaysInMonth(
            filter.end + 1,
            filter.year
          )}`,
          format
        ).format(format),
      })
    } else {
      setLabel('Quarter')
      if (filter.quarter === '') {
        setDate({
          start: moment(`${filter.year}-01-01`).format(format),
          end: moment(`${filter.year}-12-31`).format(format),
        })
      } else {
        setDate({
          start: moment(
            `${filter.year}-${QUARTER_MONTH[filter.quarter][0]}-01`,
            format
          ).format(format),
          end: moment(
            `${filter.year}-${
              QUARTER_MONTH[filter.quarter][1]
            }-${getDaysInMonth(QUARTER_MONTH[filter.quarter][1], filter.year)}`,
            format
          ).format(format),
        })
      }
    }
    getFormattedData()
  }

  const getDataPerStatus = (data, index) => {
    return data.filter((item) => parseInt(item.status) === index)
    //   return data.reduce((count, data) => parseInt(data.status) === index ? ++count : count, 0);
  }

  const getFormattedData = async () => {
    let results = { ...INITIAL_BAR }
    let response = { ...INITIAL_PIE }
    let barData = []
    let startIndex
    let endIndex
    if (filter.display === 0) {
      startIndex = filter.start
      endIndex = filter.end
    } else {
      if (filter.quarter === '') {
        startIndex = 0
        endIndex = 3
      } else {
        startIndex = parseInt(filter.quarter)
        endIndex = parseInt(filter.quarter)
      }
    }
    for (let index = startIndex; index <= endIndex; index++) {
      let start
      let end
      if (filter.display === 0) {
        start = moment(`${filter.year}-${index + 1}-01`, format).format(format)
        end = moment(
          `${filter.year}-${index + 1}-${getDaysInMonth(
            index + 1,
            filter.year
          )}`,
          format
        ).format(format)
      } else {
        start = moment(
          `${filter.year}-${QUARTER_MONTH[index][0]}-01`,
          format
        ).format(format)
        end = moment(
          `${filter.year}-${QUARTER_MONTH[index][1]}-${getDaysInMonth(
            QUARTER_MONTH[index][1],
            filter.year
          )}`,
          format
        ).format(format)
      }
      const requests = await getQCFoi(start, end, filter.department)
      const temp = {}
      STATUS.forEach((status, index) => {
        let data = getDataPerStatus(requests, index)
        results[status] += data.length
        temp[status] = data.length
        if ([3, 5, 6].includes(index) && data.length > 0) {
          let responseCount = getResponse(data)
          response['Responded within 1-7 days'] += responseCount.first
          response['Responded within 8-14 days'] += responseCount.mid
          response['Responded within 15+ days'] += responseCount.last
        }
      })
      barData.push({
        ...temp,
        index: filter.display === 0 ? MONTHS[index] : QUARTER[index],
      })
    }
    setRequests(results)
    setData(barData)
    const total = Object.values(results).reduce(
      (count, value) => count + value,
      0
    )
    setPieData(
      Object.keys(results).map((key) => ({
        id: key,
        label: key,
        value: results[key] / total,
      }))
    )
    const totalResponse = Object.values(response).reduce(
      (count, value) => count + value,
      0
    )
    setResponseData(
      Object.keys(response).map((key) => ({
        id: key,
        label: key,
        value: response[key] / totalResponse,
      }))
    )
    setTotal(total)
  }

  const getResponse = (list) => {
    const response = { first: 0, mid: 0, last: 0 }
    list.forEach((request) => {
      const receiveAt = moment(request.receivedAt)
      const completedAt = moment(request.completeDate)
      const diff = completedAt.businessDiff(receiveAt)
      if (diff >= 0 && diff <= 7) {
        response.first += 1
      }
      if (diff >= 8 && diff <= 14) {
        response.mid += 1
      }
      if (diff > 15) {
        response.last += 1
      }
    })
    return response
  }

  const handleFilter = (e) => {
    if (e.target.name === 'department' || e.target.name === 'quarter') {
      setFilter((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }))
    } else {
      setFilter((prevState) => ({
        ...prevState,
        [e.target.name]: parseInt(e.target.value),
      }))
    }
  }

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">FOI Report</h1>
      <div className="dashboard">
        {STATUS.map((status, index) => (
          <Card
            key={index}
            title={status}
            count={`${requests[status]} Total requests`}
            icon={ICON[index]}
            path={`${
              currentUserGroups.find((x) => x === 'QCFoiProcessor') && index < 4
                ? ''
                : '/admin/qcfoi'
            }`}
            state={{
              ...date,
              status: index.toString(),
              department: filter.department,
            }}
          />
        ))}
        <Card
          title="All"
          count={`${total} Total requests`}
          icon={<HiOutlineInformationCircle />}
          path="/admin/qcfoi"
          state={{ ...date, status: '', department: filter.department }}
        />
      </div>
      <div className="chart-options">
        <div className="chart-filter">
          <div className="report-filter">
            <div className="search-add">
              <Form.Group className="margin-top action-wrapper">
                <Export
                  requests={data}
                  pieData={pieData}
                  responseData={responseData}
                  filename={`${
                    filter.department === ''
                      ? 'All'
                      : departments.find((x) => x.id === filter.department)
                          ?.name
                  } FOI Report`}
                  department={
                    filter.department === ''
                      ? 'All'
                      : departments.find((x) => x.id === filter.department)
                          ?.name
                  }
                  year={filter.year}
                  total={total}
                  label={label}
                />
              </Form.Group>
            </div>
            {!currentUser.attributes['custom:department'] && (
              <Select
                label="Department"
                name="department"
                onChange={handleFilter}
              >
                <option value="">All</option>
                {departments.map((department) => (
                  <option value={department.id} key={department.id}>
                    {department.name}
                  </option>
                ))}
              </Select>
            )}
          </div>
          <div className="report-filter">
            <Select
              label="Display"
              name="display"
              defaultValue={filter.display}
              onChange={handleFilter}
            >
              {DISPLAY.map((display, index) => (
                <option value={index} key={index}>
                  {display}
                </option>
              ))}
            </Select>

            <Select
              label="Quarter"
              name="quarter"
              defaultValue=""
              disabled={filter.display === 0 ? true : false}
              onChange={handleFilter}
            >
              <option value="">All</option>
              {QUARTER.map((quarter, index) => (
                <option value={index} key={index}>
                  {quarter}
                </option>
              ))}
            </Select>
          </div>
          <div className="report-filter">
            <Select
              label="Start Month"
              name="start"
              defaultValue={filter.start}
              disabled={filter.display === 1 ? true : false}
              onChange={handleFilter}
            >
              {MONTHS_FULL.map((month, index) => (
                <option value={index} key={index}>
                  {month}
                </option>
              ))}
            </Select>
            <Select
              label="End Month"
              name="end"
              defaultValue={filter.end}
              disabled={filter.display === 1 ? true : false}
              onChange={handleFilter}
            >
              {MONTHS_FULL.map((month, index) => (
                <option value={index} key={index}>
                  {month}
                </option>
              ))}
            </Select>
          </div>
          <div className="report-filter">
            <Select
              label="Year"
              name="year"
              defaultValue={filter.year}
              onChange={handleFilter}
            >
              {years.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </Select>
            <div className="search-add">
              <Form.Group className="margin-top action-wrapper">
                <button className="action-btn blue" onClick={fetchData}>
                  <HiOutlineFilter />
                  Filter
                </button>
              </Form.Group>
            </div>
          </div>
        </div>
      </div>
      <div className="chart">
        <Bar
          data={data}
          title="FOI Request Statistics"
          keys={STATUS}
          report={true}
          yLabel="Request"
          xLabel={label}
        />
      </div>
      <div className="chart">
        <Pie data={pieData} report={true} />
        <Pie data={responseData} report={true} />
      </div>
    </section>
  )
}

export default Report
