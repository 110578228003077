import React, { useEffect, useState } from 'react'
import { Col, Form } from 'react-bootstrap'

const Input = ({
  name,
  label,
  column = 12,
  required = false,
  state,
  validated = false,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })
  const feedback = document.querySelector(`[name="${name}-feedback"]`)

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (state.length < 1) {
      feedback.style.display = 'block'
      setStatus({ valid: false, invalid: true })
    } else {
      feedback.style.display = 'none'
      setStatus({ valid: true, invalid: false })
    }
  }, [state, validated])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      {label && <Form.Label className="semi-bold">{label}</Form.Label>}
      <Form.Control
        name={name}
        required={required}
        isValid={status.valid}
        isInvalid={status.invalid}
        {...props}
      />
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        This field is required.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Input
