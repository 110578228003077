import React, { useState } from 'react'
import { HiOutlineEye } from 'react-icons/hi'
import moment from 'moment/moment'
import Modal from 'react-bootstrap/Modal'
import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Header from '../../Modal/Header'
import Field from '../../Field/Field'
import Footer from '../../Modal/Footer'

function View({ account, action }) {
  const [show, setShow] = useState(false)

  return (
    <>
      {action ? (
        <Button
          color="blue"
          icon={<HiOutlineEye />}
          onClick={() => setShow(true)}
        >
          View
        </Button>
      ) : (
        <Button link={true} onClick={() => setShow(true)}>
          {account.email}
        </Button>
      )}
      <Modal show={show} onHide={() => setShow(false)} backdrop="static">
        <Header title="Account Details" />
        <Body>
          <Field name="Email">{account.email}</Field>
          <Field name="Role">{account.role}</Field>
          <Field name="Department/Office/Operating Unit">
            {account.departmentName}
          </Field>
          <Field name="Account Status">
            {account.enabled ? 'Enabled' : 'Disabled'}
          </Field>
          <Field name="Created Date">
            {moment(account.createDate).format('lll')}
          </Field>
        </Body>
        <Footer>
          <Button color="gray" onClick={() => setShow(false)}>
            Close
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default View
