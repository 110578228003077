import moment from 'moment/moment'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Refresh from '../../components/Button/Refresh'
import Filter from '../../components/Filter/Admin/Filter'
import Export from '../../components/QCAction/Export/Export'
import Edit from '../../components/QCAction/Form/Edit'
import View from '../../components/QCAction/Form/View'
import Table from '../../components/Table/Table'
import useActionAdmin from '../../hooks/useActionAdmin'
import { INITIAL_FILTER } from '../../utilities/Constants'

function AdminQCAction() {
  const location = useLocation()
  let temp = location.state
  let initialFilter

  if (temp) {
    initialFilter = {
      ...INITIAL_FILTER,
      ...temp,
    }
  } else {
    initialFilter = INITIAL_FILTER
  }

  const [filter, setFilter] = useState(initialFilter)
  const { action, loading, setAction, fetchAction } = useActionAdmin(filter)

  const handleInput = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const columns = [
    {
      name: 'Reference Number',
      width: '200px',
      selector: (row) => row.report.id,
      format: (row) => (
        <View report={row.report} citizen={row.citizen} action={false} />
      ),
      sortable: true,
    },
    {
      name: 'Name',
      selector: (row) => row.citizen.lastName,
      sortable: true,
      format: (row) => (
        <span>{`${row.citizen.lastName}, ${row.citizen.firstName} ${
          row.citizen.middleName ? row.citizen.middleName.charAt(0) + '.' : ''
        }`}</span>
      ),
    },
    {
      name: 'Email',
      selector: (row) => row.citizen.email,
      sortable: true,
    },
    {
      name: 'Remarks / Action Taken',
      selector: (row) => row.report.remarks,
      format: (row) =>
        row.report.remarks
          ? row.report.remarks?.length > 50
            ? row.report.remarks?.substring(0, 50) + '...'
            : row.report.remarks
          : row.report.actionTaken
          ? row.report.actionTaken?.split('/').length > 1
            ? row.report?.actionTaken?.split('/')[1]?.substring(0, 50) + '...'
            : row.report?.actionTaken?.substring(0, 50) + '...'
          : '',
    },
    {
      id: 'createDate',
      name: 'Report Date',
      width: '180px',
      sortable: true,
      selector: (row) => row.report.createdAt,
      format: (row) => moment(row.report.createdAt).format('lll'),
    },
    {
      name: 'Action',
      width: '150px',
      cell: (row) => (
        <div className="action-wrapper">
          <Edit
            report={row?.report}
            citizen={row?.citizen}
            setReports={setAction}
          />
          <View report={row?.report} citizen={row?.citizen} action={true} />
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">QCAction Reports</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchAction} />
          <Export
            reports={action}
            filename={`QCACTION_${filter.start}_${filter.end}_Reports`}
          />
        </div>
        <Filter
          onChange={handleInput}
          onClick={fetchAction}
          action={true}
          filter={filter}
        />
      </div>
      <Table
        columns={columns}
        loading={loading}
        data={action.filter((item) => {
          if (filter.search === '') {
            return item
          } else if (
            item.report.transactionId
              .toLowerCase()
              .includes(filter.search.toLowerCase()) ||
            item.citizen.email
              .toLowerCase()
              .includes(filter.search.toLowerCase())
          ) {
            return item
          }
          return ''
        })}
      />
    </section>
  )
}

export default AdminQCAction
