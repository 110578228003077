import { DataStore } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { QCActionComplaintTypes, QCWatchComplaintTypes } from '../models'

const useComplaintTypes = (module) => {
  const [complaintTypes, setComplaintTypes] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchComplaintTypes = async () => {
      if (!isMounted) {
        return
      }
      setLoading(true)
      try {
        const response =
          module === 'action'
            ? await DataStore.query(QCActionComplaintTypes)
            : await DataStore.query(QCWatchComplaintTypes)
        setComplaintTypes(response)
      } catch (error) {
        console.log(error)
        setError(true)
      }
      setLoading(false)
    }

    fetchComplaintTypes()

    return () => {
      isMounted = false
    }
  }, [module])

  return { complaintTypes, error, loading}
}

export default useComplaintTypes
