import { useState } from 'react'
import { Storage } from 'aws-amplify'
import { STATUS } from '../../../utilities/Constants'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx-color'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import Button from '../../Button/Button'
import Disabled from '../../Button/Disabled'
import moment from 'moment'

function Export({ requests, filename, departments }) {
  const [loading, setLoading] = useState(false)

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = () => {
    setLoading(true)
    requests = requests.map(async (request) => {
      let newState = { ...request.citizen, ...request.request }
      delete newState.id
      delete newState._deleted
      delete newState._version
      delete newState._lastChangedAt
      delete newState.qcitizenID
      delete newState.userId
      delete newState.message
      delete newState.originalDepartment
      delete newState.forwardCount
      delete newState.idPresented
      newState.receivedAt = request.request?.receivedAt
        ? request.request.receivedAt
        : moment(request.request.createdAt).format('YYYY-MM-DD')
      newState.qcdepartmentID = departments.find(
        (x) => x.id === request.request.qcdepartmentID
      )?.name
      newState.status = STATUS[newState.status]
      newState.copy = newState.copy === '0' ? 'Digital Copy' : 'Physical Copy'
      if (request.request.attachment) {
        const attachment = await Storage.get(request.request.attachment[0])
        newState.attachment = attachment
        return newState
      } else {
        return newState
      }
    })
    Promise.all(requests).then(function (results) {
      const ws = XLSX.utils.json_to_sheet(results)
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            'First Name',
            'Middle Name',
            'Last Name',
            'Mobile Number',
            'Email',
            'Address',
            'Birthday',
            'Gender',
            'Title',
            'Organization',
            'Authority',
            'Occupation',
            'QCID Number',
            'Created Date',
            'Updated Date',
            'Requested Information',
            'Purpose of Request',
            'Status',
            'Remarks',
            'Attachment',
            'Requested Copy',
            'Reference Number',
            'Expected Date',
            'Completed Date',
            'Received Date',
            'Department/Operating Unit/Office',
          ],
        ],
        { origin: 'A1' }
      )
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, filename + fileExtension)
      setLoading(false)
    })
  }

  return requests.length <= 0 ? (
    <Disabled color="blue" icon={<HiOutlineDocumentDownload />}>
      Export
    </Disabled>
  ) : (
    <Button
      color="blue"
      loading={loading}
      icon={<HiOutlineDocumentDownload />}
      onClick={exportToCSV}
    >
      {loading ? 'Exporting' : ` Export`}
    </Button>
  )
}

export default Export
