import { useLocation } from 'react-router-dom'
import Card from '../components/Card/Citizen/Card'
import Disclaimer from '../components/Disclaimer/Disclaimer'
import Hero from '../components/Hero/Hero'
import Loading from '../components/Loading/Loading'
import { getQCitizenByUserId } from '../database'
import useUser from '../hooks/useUser'
import Page401 from './Page401'
import Page404 from './Page404'

function Home() {
  let location = useLocation()
  let params = {}

  try {
    params = JSON.parse(decodeURIComponent(location.pathname.substring(1)))
    // Added this line of code to load citizen model to avoid duplicate record
    getQCitizenByUserId(params['user_id'])
  } catch {}

  const { user, error, loading } = useUser(params?.user_id, params?.email)


  return (
    <>
      <Hero />
      <div className="qcitizen">
        <Card
          title="Watch"
          linkName="File Report"
          paragraph="Report businesses and establishments not adhering to Covid-19 guidelines"
          user={user}
          path="qcwatch"
        />
        <Card
          title="Action"
          linkName="File Report"
          paragraph="File a complaint on irregular or delayed government services"
          user={user}
          path="qcaction"
        />
        <Card
          title="FOI"
          linkName="Request Info"
          paragraph="Request for information"
          user={user}
          path="qcfoi"
        />
      </div>
      {!params['transaction_id'] && <Disclaimer />}
    </>
  )
}

export default Home
