import { useReducer } from 'react'
import { setCheckBox } from '../utilities/Helpers'
import { CommonReducer, INITIAL_STATE, ACTION } from '../reducer/CommonReducer'

const useCommon = (data) => {
  const [state, dispatch] = useReducer(CommonReducer, INITIAL_STATE)

  const handleCreate = () => {
    dispatch({
      type: ACTION.HANDLE_CREATE,
      payload: data,
    })
  }

  const handleUpdate = () => {
    dispatch({
      type: ACTION.HANDLE_UPDATE,
      payload: data,
    })
  }

  const handleCancel = () => {
    dispatch({
      type: ACTION.HANDLE_CANCEL,
    })
  }

  const handleClose = () => {
    dispatch({
      type: ACTION.HANDLE_CLOSE,
    })
  }

  const handleInput = (e) => {
    dispatch({
      type: ACTION.HANDLE_INPUT,
      payload: {
        property: e.target.name,
        value: e.target.value,
      },
    })
  }

  const handleCheck = (e) => {
    const newList = setCheckBox(e.target.value, state.data.reasons)
    dispatch({
      type: ACTION.HANDLE_INPUT,
      payload: {
        property: e.target.name,
        value: newList,
      },
    })
  }

  const setLoading = () => {
    dispatch({
      type: ACTION.SET_LOADING,
    })
  }

  const setAlert = (alert) => {
    dispatch({
      type: ACTION.SET_ALERT,
      payload: alert,
    })
  }

  const setValidated = (value) => {
    dispatch({
      type: ACTION.SET_VALIDATED,
      payload: value,
    })
  }

  const checkInputValidity = (required) => {
    for (let item of required) {
      if (state.data[item] === '') {
        return false
      }
    }
    return true
  }

  return {
    state,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleClose,
    handleInput,
    handleCheck,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  }
}

export default useCommon
