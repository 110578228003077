import * as FileSaver from 'file-saver'
import { useState } from 'react'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import * as XLSX from 'xlsx-color'
import { STATUS } from '../../../utilities/Constants'
import Button from '../../Button/Button'
import Disabled from '../../Button/Disabled'

function Export({
  requests,
  pieData,
  responseData,
  filename,
  department,
  year,
  total,
  label
}) {
  const [loading, setLoading] = useState(false)

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const totalColor = {
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: { rgb: 'FFC107' },
    },
  }

  const totalAllColor = {
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: { rgb: '198754' },
      font: { rgb: 'FFFFFF' },
    },
    font: {
      color: { rgb: 'FFFFFF' },
      bold: true,
      italic: false,
      underline: false,
    },
  }

  const exportToCSV = () => {
    const newRequest = requests.map((obj) => {
      const totalPerMonth = [...Object.values(obj).slice(0, -1)].reduce(
        (acc, curr) => acc + curr,
        0
      )
      return [...Object.values(obj).reverse(), totalPerMonth]
    })
    const totalPerStatus = []
    const newPieData = pieData.map(({ id, value }) => {
      totalPerStatus.push(total * (value ? value : 0))
      return [id, `${value ? (100 * value).toFixed(2) : 0}%`]
    })
    const newResponseData = responseData.map(({ id, value }) => [
      id,
      `${value ? (100 * value).toFixed(2) : 0}%`,
    ])

    const ws = XLSX.utils.aoa_to_sheet([
      ['Department/Operating Unit/Office', department],
      ['Year', year],
      [],
      [label, ...STATUS.slice().reverse(), `Total Request Per ${label}`],
      ...newRequest,
      ['Total Request Per Status', ...totalPerStatus.reverse(), total],
      [],
      ['Percentage'],
      ...newPieData,
      [],
      ...newResponseData,
    ])

    let i = 5

    for (; i < requests.length + 5; i++) {
      ws[`I${i}`].s = totalColor
    }

    const letters = ['B', 'C', 'D', 'E', 'F', 'G', 'H']

    letters.forEach((letter) => {
      ws[`${letter}${i}`].s = totalColor
    })

    ws[`I${i}`].s = totalAllColor

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, filename + fileExtension)
    setLoading(false)
  }

  return requests.length <= 0 ? (
    <Disabled color="blue" icon={<HiOutlineDocumentDownload />}>
      Export
    </Disabled>
  ) : (
    <Button
      color="blue"
      loading={loading}
      icon={<HiOutlineDocumentDownload />}
      onClick={exportToCSV}
    >
      {loading ? 'Exporting' : ` Export`}
    </Button>
  )
}

export default Export
