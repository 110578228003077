export const AccountReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_CREATE':
      return {
        ...INITIAL_STATE,
        show: true,
      }
    case 'HANDLE_UPDATE':
      return {
        ...INITIAL_STATE,
        show: true,
        account: action.payload,
      }
    case 'HANDLE_CANCEL':
      return {
        ...state,
        show: false,
      }
    case 'HANDLE_INPUT':
      return {
        ...state,
        account: {
          ...state.account,
          ...action.payload,
        },
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
        validated: false,
        alert: INITIAL_STATE.alert,
      }
    case 'SET_ALERT':
      return {
        ...state,
        loading: false,
        alert: {
          show: true,
          message: action.payload?.message,
          variant: action.payload?.variant,
        },
      }
    case 'SET_VALIDATED':
      return {
        ...state,
        validated: action?.payload ? action.payload : !state.validated,
      }
    default:
      return state
  }
}

export const INITIAL_STATE = {
  show: false,
  alert: {
    show: false,
    variant: '',
    message: '',
  },
  loading: false,
  validated: false,
  account: {
    email: '',
    password: '',
    department: '',
    departmentName: '',
    role: '',
    group: '',
  },
}

export const ACTION = {
  HANDLE_CREATE: 'HANDLE_CREATE',
  HANDLE_UPDATE: 'HANDLE_UPDATE',
  HANDLE_CANCEL: 'HANDLE_CANCEL',
  HANDLE_INPUT: 'HANDLE_INPUT',
  SET_LOADING: 'SET_LOADING',
  SET_ALERT: 'SET_ALERT',
  SET_VALIDATED: 'SET_VALIDATED',
}
