// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { User, FoiSetting, FileCounter, FileLog, ExtendHistory, QCFoiHistory, Message, QCWatchDepartment, QCWatchComplaintTypes, QCWatch, QCActionComplaintTypes, QCAction, QCDepartment, QCFoi, QCitizen } = initSchema(schema);

export {
  User,
  FoiSetting,
  FileCounter,
  FileLog,
  ExtendHistory,
  QCFoiHistory,
  Message,
  QCWatchDepartment,
  QCWatchComplaintTypes,
  QCWatch,
  QCActionComplaintTypes,
  QCAction,
  QCDepartment,
  QCFoi,
  QCitizen
};