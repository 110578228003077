import { Spinner } from 'react-bootstrap'

function Button({
  loading = false,
  color = 'blue',
  type = 'button',
  icon = '',
  link = false,
  children,
  ...props
}) {
  return (
    <button
      type={type}
      className={`${link ? 'link-btn' : 'action-btn'} ${color} ${
        loading ? 'disabled' : ''
      }`}
      disabled={loading ? true : false}
      {...props}
    >
      {loading ? <Spinner animation="border" role="status" size="sm" /> : icon}
      {children}
    </button>
  )
}

export default Button
