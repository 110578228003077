import { Link } from 'react-router-dom'

function Card({ title, paragraph, linkName, user, path }) {
  return (
    <section className="qcitizen-card">
      <div>
        <h2 className="qcitizen-card-title">
          QCitizen
          <br />
          {title}
        </h2>
        <p className="qcitizen-card-subtitle">{paragraph}</p>
      </div>
      <div>
        <Link to={path} state={user} className="qcitizen-card-btn">
          {linkName}
        </Link>
      </div>
    </section>
  )
}

export default Card
