import { memo } from 'react'
import Field from '../Field/Field'

function Citizen({ citizen, foi = false }) {
  return (
    <>
      {foi && (
        <>
          <Field name="Name of Company/Organization">
            {citizen.orgName ? citizen.orgName : 'N/A'}
          </Field>
          <Field name="Authority (e.g. SPA, Board Resolution, Authorization Letter)">
            {citizen.authority ? citizen.authority : 'N/A'}
          </Field>
          <Field name="Title">{citizen.title ? citizen.title : 'N/A'}</Field>
        </>
      )}
      <Field name="Name">{`${citizen.lastName}, ${citizen.firstName} ${
        citizen.middleName ? citizen.middleName.charAt(0) + '.' : ''
      }`}</Field>
      <Field name="Mobile no.">{citizen.mobileNumber}</Field>
      <Field name="Email Address">{citizen.email}</Field>
      {foi && (
        <>
          <Field name="Complete Address">{citizen.address}</Field>
          <Field name="Occupation">
            {citizen.occupation ? citizen.occupation : 'N/A'}
          </Field>
          <Field name="QCitizen ID no.">
            {citizen.qcId ? citizen.qcId : 'N/A'}
          </Field>
        </>
      )}
    </>
  )
}

export default memo(Citizen)
