import { useReducer } from 'react'
import { Auth } from 'aws-amplify'
import { Form, Spinner } from 'react-bootstrap'
import {
  PasswordReducer,
  INITIAL_STATE,
  ACTION,
} from '../../reducer/PasswordReducer'
import Body from '../../components/Modal/Body'
import Footer from '../../components/Modal/Footer'
import Helper from '../../components/Password/Helper'
import Password from '../../components/Input/Password'

function ChangePassword() {
  const required = ['current', 'new', 'confirm']
  const [state, dispatch] = useReducer(PasswordReducer, INITIAL_STATE)

  const handleSubmit = (e) => {
    dispatch({
      type: ACTION.SET_LOADING,
    })
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      dispatch({
        type: ACTION.SET_VALIDATED,
        payload: true,
      })
    }

    e.preventDefault()
    e.stopPropagation()

    if (form.checkValidity() === true && checkInputValidity() === true) {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          return Auth.changePassword(
            user,
            state.password.current,
            state.password.new
          )
        })
        .then(() => {
          dispatch({
            type: ACTION.SET_ALERT,
            payload: {
              variant: 'success',
              message: 'Password has been succesfully changed.',
            },
          })
          dispatch({ type: ACTION.CLEAR_PASSWORD })
        })
        .catch((error) => {
          if (error.code === 'NotAuthorizedException') {
            dispatch({
              type: ACTION.SET_ALERT,
              payload: {
                variant: 'danger',
                message: 'Current password is incorrect.',
              },
            })
          } else {
            dispatch({
              type: ACTION.SET_ALERT,
              payload: {
                variant: 'danger',
                message: error.message,
              },
            })
          }
        })
    } else {
      dispatch({
        type: ACTION.SET_ALERT,
        payload: {
          variant: 'danger',
          message: 'Please fill out required fields.',
        },
      })
    }
  }

  const handleInput = (e) => {
    dispatch({
      type: ACTION.HANDLE_INPUT,
      payload: {
        property: e.target.name,
        value: e.target.value,
      },
    })
  }

  const checkInputValidity = () => {
    for (let item of required) {
      if (
        state.password[item] === '' ||
        state.password[item].length < 8 ||
        state.password[item].match(
          /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z#?!@$%^&*-ñÑ\d]+$/
        ) === null
      ) {
        return false
      }
    }
    if (state.password.new !== state.password.confirm) {
      return false
    }
    return true
  }

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">Change Password</h1>
      <div className="normal-form">
        <Form noValidate onSubmit={handleSubmit}>
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Password
              name="current"
              label="Current Password"
              value={state.password.current}
              state={state.password.current}
              onChange={handleInput}
              validated={state.validated}
              required={true}
            />
            <Password
              name="new"
              label="New Password"
              value={state.password.new}
              state={state.password.new}
              onChange={handleInput}
              validated={state.validated}
              required={true}
            />
            <Password
              name="confirm"
              label="Confirm New Password"
              value={state.password.confirm}
              state={state.password.confirm}
              onChange={handleInput}
              validated={state.validated}
              required={true}
            />
            <Helper state={state.password.new} />
          </Body>
          <Footer>
            <button
              type="submit"
              className={`action-btn blue full ${
                state.loading ? 'disabled' : ''
              }`}
            >
              {state.loading && (
                <Spinner animation="border" role="status" size="sm" />
              )}
              {state.loading ? ' Saving New Password' : ' Save New Password'}
            </button>
          </Footer>
        </Form>
      </div>
    </section>
  )
}

export default ChangePassword
