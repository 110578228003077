import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Col, InputGroup } from 'react-bootstrap'
import { HiOutlineEyeOff, HiOutlineEye } from 'react-icons/hi'

const Password = ({
  name,
  label,
  column = 12,
  state,
  validated = false,
  required,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })
  const [show, setShow] = useState(false)
  const feedback = document.querySelector(`[name="${name}-feedback"]`)

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (
      state.length > 7 &&
      state.match(/^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z#?!@$%^&*-ñÑ\d]+$/) !== null
    ) {
      feedback.style.display = 'none'
      setStatus({ valid: true, invalid: false })
    } else {
      feedback.style.display = 'block'
      setStatus({ valid: false, invalid: true })
    }
  }, [state, validated])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <InputGroup>
        <Form.Control
          type={show ? 'text' : 'password'}
          name={name}
          isValid={status.valid}
          isInvalid={status.invalid}
          required={required}
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$"
          {...props}
        />
        <InputGroup.Text onClick={() => setShow((prevState) => !prevState)}>
          {show ? <HiOutlineEyeOff /> : <HiOutlineEye />}
        </InputGroup.Text>
      </InputGroup>
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        Please use a valid password.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Password
