import { Routes, Route } from 'react-router-dom'
import { withAuthenticator } from '@aws-amplify/ui-react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import { UserProvider } from '../../context'
import Account from './Account'
import ChangePassword from './ChangePassword'
import CheckGroups from '../../utilities/CheckGroups'
import Dashboard from './Dashboard'
import Department from './Department'
import FileLog from './FileLog'
import History from './History'
import Page404 from '../Page404'
import Report from './Report'
import Settings from './Settings'
import SideNav from '../../components/SideNav/SideNav'
import QCAction from './QCAction'
import QCFoi from './QCFoi'
import QCWatch from './QCWatch'

function Admin({ signOut, user }) {
  const groups = user.signInUserSession.accessToken.payload['cognito:groups']

  let indexPage

  if (groups.find((x) => x === 'Admin')) {
    indexPage = <Dashboard />
  } else if (groups.find((x) => x === 'QCActionAdmin')) {
    indexPage = <QCWatch />
  } else if (groups.find((x) => x === 'QCWatchAdmin')) {
    indexPage = <QCAction />
  } else {
    indexPage = <QCFoi />
  }

  return (
    <div className="admin">
      <NotificationContainer />
      <UserProvider value={{ currentUser: user, currentUserGroups: groups }}>
        <SideNav signOut={signOut} groups={groups} />
        <Routes>
          <Route index element={indexPage} />

          <Route element={<CheckGroups groups={groups} />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="qcwatch" element={<QCWatch />} />
            <Route path="qcaction" element={<QCAction />} />
            <Route path="qcfoi" element={<QCFoi />} />
            <Route path="accounts" element={<Account />} />
            <Route path="filelog" element={<FileLog />} />
            <Route path="department" element={<Department />} />
            <Route path="history" element={<History />} />
            <Route path="report" element={<Report />} />
            <Route path="settings" element={<Settings />} />
          </Route>

          <Route path="changepassword" element={<ChangePassword />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </UserProvider>
    </div>
  )
}

export default withAuthenticator(Admin)
