import { useState } from 'react'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import { DisableUser } from '../../../utilities/Requests'
import { updateUser } from '../../../database'
import Button from '../../Button/Button'

function Disable({ account, setUsers }) {
  const [loading, setLoading] = useState(false)

  const handleDisable = async () => {
    setLoading(true)
    try {
      await DisableUser(account.sub)
      const updatedUser = await updateUser(account.id, {
        ...account,
        enabled: false,
      })
      console.log(updatedUser)
      setUsers((prevState) =>
        prevState.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        )
      )
      NotificationManager.success(`${account.email} has been disabled.`)
    } catch (error) {
      NotificationManager.error(
        `Problems occurred while disabling ${account.email}. Please try again later.`
      )
    }
    setLoading(false)
  }

  return (
    <Button
      color="red"
      loading={loading}
      icon={<HiOutlinePencilAlt />}
      onClick={handleDisable}
    >
      Disable
    </Button>
  )
}

export default Disable
