import React from 'react'

function Field({ name, children }) {
  return (
    <p>
      <span className="semi-bold">{name}:</span> {children}
    </p>
  )
}

export default Field
