import './App.css'
import { Routes, Route } from 'react-router-dom'
import { useEffect } from 'react'
import QCAction from './pages/QCAction'
import QCWatch from './pages/QCWatch'
import QCFoi from './pages/QCFoi'
import Home from './pages/Home'
import Admin from './pages/admin/Admin'

function App() {
  useEffect(() => {
    document.title = "People's Corner"
  }, [])

  return (
    <main className="App">
      <Routes>
        <Route path="/*" element={<Home />} />
        <Route path="qcwatch" element={<QCWatch />} />
        <Route path="qcaction" element={<QCAction />} />
        <Route path="qcfoi" element={<QCFoi />} />
        <Route path="admin/*" element={<Admin />} />
      </Routes>
    </main>
  )
}

export default App
