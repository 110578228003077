import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { QCitizen } from '../models'

const useCitizen = (user) => {
  const [citizen, setCitizen] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchCitizen = async () => {
      if (!isMounted) {
        return
      }
      setLoading(true)
      try {
        const response = await DataStore.query(
          QCitizen,
          (c) => c.userId('eq', user?.userId),
          Predicates.ALL,
          {
            sort: (s) => s.createdAt(SortDirection.ASCENDING),
          }
        )

        if (response.length >= 1) {
          setCitizen(response[0])
        } else {
          const newCitizen = await DataStore.save(
            new QCitizen({
              qcId: user.qcidNo,
              ...user,
            })
          )
          setCitizen(newCitizen)
        }
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }

    fetchCitizen()

    return () => {
      isMounted = false
    }
  }, [user])

  return { citizen, error, loading, setCitizen }
}

export default useCitizen
