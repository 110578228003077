import { useState } from 'react'
import { useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

const Check = ({
  name,
  label,
  column = 12,
  list = [],
  state,
  validated = false,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })

  useEffect(() => {
    if (validated && state.length < 1) {
      document.querySelector(`[name="${name}-feedback"]`).style.display =
        'block'
      setStatus({ valid: false, invalid: true })
    } else if (validated && state.length > 0) {
      document.querySelector(`[name="${name}-feedback"]`).style.display = 'none'
      setStatus({ valid: true, invalid: false })
    }
  }, [state, validated])

  return (
    <Form.Group as={Col} className="mb-3 check" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      {list.map((item) => (
        <Form.Check
          type="checkbox"
          key={item.id}
          id={item.id}
          name={name}
          label={item.complaint}
          value={item.complaint}
          isValid={status.valid}
          isInvalid={status.invalid}
          {...props}
        />
      ))}
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        Please select at least one.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Check
