import { useContext } from 'react'
import { UserContext } from '../../../context'
import { Modal, Form } from 'react-bootstrap'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import moment from 'moment/moment'
import {
  UpdateUserAttributes,
  AddUserToGroup,
  RemoveUserFromGroup,
} from '../../../utilities/Requests'
import { updateUser } from '../../../database'
import useAccount from '../../../hooks/useAccount'
import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Field from '../../Field/Field'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'
import Select from '../../Input/Select'

function EditAccount({ account, departments, setUsers, violations }) {
  const { currentUser, currentUserGroups } = useContext(UserContext)
  const required = ['role']
  const {
    state,
    handleUpdate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  } = useAccount(account)

  const handleSubmit = async (e) => {
    setLoading()
    setValidated(false)
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (form.checkValidity() === true && checkInputValidity(required)) {
      try {
        const user = {
          ...state.account,
          department: state.account.department
            ? state.account.department
            : currentUser.attributes['custom:department'],
          departmentName: state.account.departmentName
            ? state.account.departmentName
            : currentUser.attributes['custom:department_name'],
        }
        await UpdateUserAttributes(user)
        const updatedUser = await updateUser(account.id, user)
        if (account.group !== state.account.group) {
          await RemoveUserFromGroup({
            email: account.email,
            group: account.group,
          })
          await AddUserToGroup(state.account)
        }
        setUsers((prevState) =>
          prevState.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
          )
        )
        NotificationManager.success(
          `${account.email} has been successfully updated.`
        )
        document.querySelector('#close').click()
      } catch (error) {
        console.log(error)
        setAlert({
          variant: 'danger',
          message: error.response?.data?.message
            ? error.response.data.message
            : 'Problems occurred while updating account. Please contact administrator',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePencilAlt />} onClick={handleUpdate}>
        Edit
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="Add Account" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Field name="Email">{account.email}</Field>
            <Field name="Account Status">{account.status}</Field>
            <Field name="Created Date">
              {moment(account.createDate).format('lll')}
            </Field>
            <Select
              name="group"
              label="Role"
              onChange={handleInput}
              value={state.account.group}
              validated={state.validated}
              state={state.account.group}
              required={true}
            >
              <option value="" disabled>
                Select Role
              </option>
              {(currentUserGroups.find((x) => x === 'QCWatchAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <option value="QCWatchAdmin">QCWatch Admin</option>
              )}
              {(currentUserGroups.find((x) => x === 'QCActionAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <option value="QCActionAdmin">QCAction Admin</option>
              )}
              {currentUserGroups.find((x) => x === 'Admin') && (
                <option value="QCFoiAdmin">QCFOI Department Admin</option>
              )}
              {(currentUserGroups.find((x) => x === 'QCFoiAdmin') ||
                currentUserGroups.find((x) => x === 'Admin')) && (
                <>
                  <option value="QCFoiApprover">QCFOI Approver</option>
                  <option value="QCFoiProcessor">QCFOI Processor</option>
                </>
              )}
            </Select>
            {['QCFoiAdmin', 'QCFoiApprover', 'QCFoiProcessor'].includes(
              state.account.group
            ) &&
              !currentUser.attributes['custom:department'] && (
                <Select
                  name="department"
                  label="Department/Office/Operating Unit"
                  onChange={handleInput}
                  value={state.account.department}
                  validated={state.validated}
                  state={state.account.department}
                  required={true}
                >
                  <option value="" disabled>
                    Select Department
                  </option>
                  {departments.map((department) => (
                    <option key={department.id} value={department.id}>
                      {department.name}
                    </option>
                  ))}
                </Select>
              )}
            {['QCWatchAdmin'].includes(state.account.group) &&
              !currentUser.attributes['custom:department'] && (
                <Select
                  name="department"
                  label="Department/Office/Operating Unit"
                  onChange={handleInput}
                  value={state.account.department}
                  validated={state.validated}
                  state={state.account.department}
                  required={true}
                >
                  <option value="" disabled>
                    Select Department
                  </option>
                  {violations.map((violation) => (
                    <option
                      key={violation.qcdepartmentID}
                      value={violation.qcdepartmentID}
                    >
                      {
                        departments.find(
                          (x) => x.id === violation.qcdepartmentID
                        )?.name
                      }
                    </option>
                  ))}
                </Select>
              )}
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="blue" type="submit" loading={state.loading}>
              {state.loading ? 'Saving' : 'Save'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default EditAccount
