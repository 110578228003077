import Form from 'react-bootstrap/Form'

function Date({ name, label, ...props }) {
  return (
    <Form.Group controlId={name}>
      <Form.Label>{label}</Form.Label>
      <Form.Control name={name} type="date" {...props} />
    </Form.Group>
  )
}

export default Date
