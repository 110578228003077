import {
  useState,
  useEffect,
  useContext
} from 'react'
import { useLocation } from 'react-router-dom';
import {
  getQCDepartment,
  getQCitizenById,
  getQCFoiByFilter,
  getFoiSetting
} from '../../database'
import NotificationManager from 'react-notifications/lib/NotificationManager';
import moment from 'moment-business-days'
import {
  STATUS,
  STATUS_CLASS,
  INITIAL_FILTER
} from '../../utilities/Constants';
import { UserContext } from '../../context';
import Refresh from '../../components/Button/Refresh';
import View from '../../components/QCFoi/Form/View';
import Edit from '../../components/QCFoi/Form/Edit';
import Export from '../../components/QCFoi/Export/Export';
import Filter from '../../components/Filter/Admin/Filter';
import Extend from '../../components/QCFoi/Form/Extend';
import Message from '../../components/QCFoi/Form/Message';
import Forward from '../../components/QCFoi/Form/Forward';
import Table from '../../components/Table/Table';

function AdminQCFoi() {

  const { currentUser, currentUserGroups } = useContext(UserContext);

  const location = useLocation();
  let temp = location.state;
  let initialFilter;

  if(temp) {
    initialFilter = {
      ...INITIAL_FILTER,
      ...temp
    }
  } else {
    initialFilter = {
      ...INITIAL_FILTER,
      department: "",
      status: ""
    }
  }

  const [requests, setRequests] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [setting, setSetting] = useState({})
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    fetchRequests()
    getQCDepartment()
    .then((value) => setDepartments(value));
    getFoiSetting()
    .then((value) => setSetting(value));
  }, [])

  const fetchRequests = async () => {
    let department = "";
    let processor = false;
    if(currentUser.attributes['custom:department']) {
      department = currentUser.attributes['custom:department'];
    } else {
      department = filter.department;
    }
    if(currentUserGroups.find(x => x === "QCFoiProcessor")) {
      processor = true;
    }
    try {
      const response = await getQCFoiByFilter({...filter, department: department}, processor);
      const requests = await Promise.all(response.map(async (request) => {
        const citizen = await getQCitizenById(request.qcitizenID)
        return {
          citizen : citizen,
          request: request
        }
      }));
      setRequests(requests);
    } catch (error) {
      NotificationManager.error(`Problems occurred when retrieving requests. Please try again later.`);
    }
  }

  const handleFilter = (e) => {
    setFilter(prevState => ({...prevState, [e.target.name]: e.target.value}));
  }

  const columns = [
    {
      name: 'Reference Number',
      selector: row => row.request.transactionId,
      width: "200px",
      format: (row) => <View
        request={ row.request }
        citizen={ row.citizen }
        departments={ departments }
        setting={ setting }
        action={ false }
        admin={ true }
        size="lg"
      />,
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.citizen.lastName,
      sortable: true,
      format: (row) => <span>{`${row.citizen.lastName}, ${row.citizen.firstName} ${row.citizen.middleName
        ? row.citizen.middleName.charAt(0) + "."
        : ""}`
      }</span>
    },
    {
      name: 'Email',
      selector: row => row.citizen.email,
      sortable: true
    },
    {
      name: 'Expected Date',
      width: "150px",
      sortable: true,
      selector: row => row.request.deadline,
      cell: (row) => {
        const received = moment(row.request.receivedAt
          ? row.request.receivedAt
          : row.request.createdAt).businessAdd(setting.expected)
        const deadline = moment(row.request.deadline)
        const today = moment();
        const diff = deadline.businessDiff(today)
        const classDiff = received.businessDiff(today)
        const late = today > deadline
        let classes = "";
        if(classDiff <= 0) {
          classes = "late"
        } else if (classDiff <= 3) {
          classes = "less-than-3days"
        } else if (classDiff <= 5) {
          classes = "less-than-5days"
        } else if (late){
          classes = "late"
        }
        let title = "";
        if(diff === 0) {
          title = "Deadline today";
        } else if ((diff >= 1 && diff <= 15 && !late) || !late) {
          title = `${diff} day/s before deadline`
        } else {
          title = `${Math.abs(diff)} day/s late`
        }
        return (
          <span
            className={`deadline ${ !["3", "5", "6"].includes(row.request.status) ? classes : "" }`}
            title={ !["3", "5", "6"].includes(row.request.status) ? title: "" }
          >
            {row.request.deadline}
          </span>
        )
      },
      button: true,
    },
    {
      id: 'createDate',
      name: 'Request Date',
      width: "180px",
      sortable: true,
      selector: row => row.request.createdAt,
      format: (row) => moment(row.request.createdAt).format('lll')
    },
    {
      name: 'Status',
      width: "150px",
      sortable: true,
      selector: row => row.request.status,
      format: (row) => STATUS[row.request.status],
      cell: (row) => <span className={`status ${STATUS_CLASS[row.request.status]}`}>
        {STATUS[row.request.status]}
      </span>,
      button: true,
    },
    {
      name:"Action",
      width: "200px",
      cell: (row) =>
        <div className='action-wrapper'>
          <Edit
            request={ row.request }
            citizen={ row.citizen }
            departments={ departments }
            setRequests={ setRequests }
            refresh={ fetchRequests }
            setting={ setting }
          />
          <Message
            request={ row.request }
            citizen={ row.citizen }
            departments={ departments }
            setRequests={ setRequests }
            email={ currentUser.attributes.email }
            side="admin"
          />
          <Extend
            request={ row.request }
            citizen={ row.citizen }
            departments={ departments }
            setRequests={ setRequests }
            setting={ setting }
          />
          <Forward
            request={ row.request }
            citizen={ row.citizen }
            departments={ departments }
            refresh={ fetchRequests }
            setting={ setting }
          />
        </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  return (
    <section className='admin-table'>
      <h1 className='admin-title qcitizen-title'>QCFOI Requests</h1>
      <div className='table-options'>
        <div className='margin-top action-wrapper admin-nav'>
          <Refresh refresh={ fetchRequests }/>
          <Export
            requests={ requests }
            filename={`QCFOI_${filter.start}_${filter.end}_Requests`}
            departments={ departments }
          />
        </div>
        <Filter
          onChange={ handleFilter }
          onClick={ fetchRequests }
          list={ departments }
          property="name"
          foi={ true }
          filter={ filter }
        />
      </div>
      <Table
        columns={ columns }
        data={requests.filter((item) => {
          if (filter.search === "") {
            return item;
          } else if (
            item.request.transactionId.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.citizen.email.toLowerCase().includes(filter.search.toLowerCase())) {
            return item;
          }
          return "";
        })}
      />
    </section>
  )
}

export default AdminQCFoi
