import { useRef } from 'react'
import { Form, Modal, Row, Spinner } from 'react-bootstrap'
import {
  createQCFoi,
  updateQCitizen,
  createQCFoiHistory,
  checkUserQCFoi,
} from '../../../database'
import { HiOutlinePlus } from 'react-icons/hi'
import { sendSubmitEmail } from '../../../utilities/Requests'
import moment from 'moment-business-days'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import Button from '../../Button/Button'
import useRequest from '../../../hooks/useRequest'
import Input from '../../Input/Input'
import Tel from '../../Input/Tel'
import TextArea from '../../Input/TextArea'
import Select from '../../Input/Select'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Exception from '../Others/Exception'
import Declaration from '../Others/Declaration'

function Create({ user, departments, setRequests, setUser, setting }) {
  const alertRef = useRef()
  const required = [
    'requestedInformation',
    'purposeOfRequest',
    'copy',
    'qcdepartmentID',
    'transactionId',
  ]

  const {
    state,
    handleCreate,
    handleCancel,
    handleInput,
    handleUser,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
    handleCertification,
  } = useRequest(user)

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (
      form.checkValidity() !== true &&
      checkInputValidity(required) !== true
    ) {
      setAlert(alertRef, {
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
      return
    }

    if (
      (await checkUserQCFoi(state.user.id)) >= 1 &&
      process.env.REACT_APP_ENV !== 'development'
    ) {
      setAlert(alertRef, {
        variant: 'danger',
        message: 'Only 1 request is allowed per day.',
      })
      return
    }

    try {
      const response = await updateQCitizen(user.id, state.user)
      setUser(response)
    } catch {
      NotificationManager.success(`Error updating user details.`)
    }

    try {
      const response = await createQCFoi({
        qcitizenID: state.user.id,
        userId: state.user.userId,
        deadline: moment().businessAdd(setting?.expected).format('YYYY-MM-DD'),
        ...state.request,
      })

      setRequests((prevState) => [response, ...prevState])

      sendSubmitEmail(
        user,
        {
          ...response,
          department: departments.find((x) => x.id === response.qcdepartmentID)
            .name,
        },
        'FOI'
      )

      createQCFoiHistory(
        'Created',
        user.email,
        departments.find((x) => x.id === response.qcdepartmentID).name,
        response
      )

      NotificationManager.success(
        `Your request has been successfully submitted.`
      )

      document.querySelector('#close').click()
    } catch (error) {
      setAlert(alertRef, {
        variant: 'danger',
        message: 'Submission of report failed, please try again later.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePlus />} onClick={handleCreate}>
        Add Request
      </Button>
      {user && (
        <Modal
          show={state.show}
          onHide={handleCancel}
          size="lg"
          backdrop="static"
        >
          <Form noValidate onSubmit={handleSubmit}>
            <Header closeButton title="File Request" innerRef={alertRef} />
            <Body
              show={state.alert.show}
              variant={state.alert.variant}
              message={state.alert.message}
            >
              <Exception />
              <hr />
              <p className="text-center">
                <strong>Requesting Party</strong>
              </p>
              <Input
                name="orgName"
                label="Name of Company or Organization (if applicable)"
                onChange={handleUser}
                defaultValue={state.user.orgName}
                validated={state.validated}
                state={state.user.orgName}
              />
              <Row>
                <Input
                  name="title"
                  label="Title"
                  onChange={handleUser}
                  defaultValue={state.user.title}
                  column={4}
                  validated={state.validated}
                  state={state.user.title}
                />
                <Input
                  name="name"
                  label="Name (Surname, Given Name and Middle Initial)"
                  defaultValue={`${user.lastName}, ${user.firstName} ${
                    user?.middleName !== ''
                      ? user?.middleName?.charAt(0) + '.'
                      : ''
                  }`}
                  readOnly={true}
                  disabled={true}
                  column={8}
                  state=""
                />
              </Row>
              <TextArea
                name="authority"
                label="Authority (if applicable, e.g. SPA, Board Resolution, Authorization Letter)"
                onChange={handleUser}
                rows={2}
                defaultValue={state.user.authority}
                validated={state.validated}
                state={state.user.authority}
              />
              <Input
                name="address"
                label="Complete Address"
                defaultValue={user.address}
                readOnly={true}
                disabled={true}
                state=""
              />
              <Row>
                <Tel
                  name="mobileNumber"
                  label="Cellphone Number"
                  onChange={handleUser}
                  column={6}
                  defaultValue={state.user.mobileNumber}
                  validated={state.validated}
                  state={state.user.mobileNumber}
                  required
                />
                <Input
                  name="email"
                  label="Email"
                  onChange={handleUser}
                  defaultValue={user.email}
                  readOnly={true}
                  disabled={true}
                  column={6}
                  state=""
                />
              </Row>
              <Row>
                <Input
                  name="occupation"
                  label="Occupation"
                  onChange={handleUser}
                  column={6}
                  defaultValue={state.user.occupation}
                  validated={state.validated}
                  state={state.user.occupation}
                />
                <Input
                  name="qcId"
                  label="QCitizen ID no."
                  defaultValue={user.qcId}
                  column={6}
                  readOnly={true}
                  disabled={true}
                  state=""
                />
                {/* <Input
                name="idPresented"
                label="ID Presented"
                onChange={ handleUser }
                defaultValue={ state.user.idPresented }
                column={ 6 }
                validated={ state.validated }
                state={ state.user.idPresented }
              /> */}
              </Row>
              <hr />
              <p className="text-center">
                <strong>Requested Information</strong>
              </p>
              <Select
                name="qcdepartmentID"
                label="Department/Office/Operating Unit"
                onChange={handleInput}
                defaultValue=""
                property="name"
                validated={state.validated}
                state={state.request.qcdepartmentID}
                required={true}
              >
                <option value="" disabled>
                  Select Department
                </option>
                {departments.map((department) => (
                  <option
                    value={department.id}
                    key={department.id}
                    data-code={department.code}
                  >
                    {department.name}
                  </option>
                ))}
              </Select>
              <TextArea
                name="requestedInformation"
                label="Information/Documents/Records requested"
                onChange={handleInput}
                rows={4}
                placeholder="ex: Hello. I would like to request information regarding:&#10;1.) The number of reported cases of COVID-19 in Quezon City from the year 2020 - 2022&#10;2.) The top 5 most affected Barangays"
                validated={state.validated}
                state={state.request.requestedInformation}
                required={true}
              />
              <TextArea
                name="purposeOfRequest"
                label="Specific Purpose"
                onChange={handleInput}
                rows={2}
                placeholder="ex: The requested information will be used in my research paper."
                validated={state.validated}
                state={state.request.purposeOfRequest}
                required={true}
              />
              <Form.Group className="mb-3" controlId="copy">
                <Form.Label>Requested Copy</Form.Label>
                <Form.Check
                  value="0"
                  type="radio"
                  label="Digital Copy (Free)"
                  name="copy"
                  id="check1"
                  defaultChecked
                  onChange={handleInput}
                />
                <Form.Check
                  value="1"
                  type="radio"
                  label="Physical Copy (Additional charges may apply)"
                  name="copy"
                  id="check2"
                  onChange={handleInput}
                />
              </Form.Group>
              <hr />
              <Declaration />
              <Form.Group controlId="certification">
                <Form.Check type="checkbox">
                  <Form.Check.Input
                    type="checkbox"
                    onChange={handleCertification}
                    required
                  />
                  <Form.Check.Label>
                    <strong>
                      Certification. I hereby certify that I have read and
                      understood the Privacy Notice, and agree thereto, and that
                      the information I have provided herein is true and
                      correct.
                    </strong>
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
              <Footer>
                <Button color="red" id="close" onClick={handleCancel}>
                  Cancel
                </Button>
                <button
                  type="submit"
                  className={`action-btn blue ${
                    state.certification ? '' : 'disabled'
                  } ${state.loading ? 'disabled' : ''}`}
                  disabled={state.certification ? '' : true}
                >
                  {state.loading && (
                    <Spinner animation="border" role="status" size="sm" />
                  )}
                  {state.loading ? ' Submitting' : ' Submit'}
                </button>
              </Footer>
            </Body>
          </Form>
        </Modal>
      )}
    </>
  )
}

export default Create
