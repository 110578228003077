import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import {
  HiMenu,
  HiOutlineUserGroup,
  HiOutlineOfficeBuilding,
  HiOutlineDocumentText,
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineLockClosed,
  HiOutlineLogout,
  HiOutlineClock,
  HiOutlineChartBar,
  HiOutlineDocumentReport,
  HiOutlineCog,
} from 'react-icons/hi'

function SideNav({ signOut, groups }) {
  const [collapse, setCollapse] = useState(false)

  const handleResize = () => {
    if (window.innerWidth < 1000) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)
  })

  return (
    <div className={`sidebar ${collapse ? 'sidebar-collapse' : ''}`}>
      <button
        className={`menu-btn ${collapse ? 'menu-btn-collapse' : ''}`}
        type="button"
        aria-label="Menu"
        aria-controls="navigation"
        onClick={() => setCollapse(!collapse)}
      >
        <span>
          <HiMenu />
        </span>
        <span>People's Corner</span>
      </button>
      <nav className="navbar">
        <ul className={`navbar-nav ${collapse ? 'navbar-nav-collapse' : ''}`}>
          {groups.find((x) => x === 'Admin') && (
            <li title="Dashboard">
              <NavLink to="/admin/dashboard">
                <span>
                  <HiOutlineChartBar />
                </span>
                <span>Dashboard</span>
              </NavLink>
            </li>
          )}
          {(groups.find((x) => x === 'Admin') ||
            groups.find((x) => x === 'QCWatchAdmin')) && (
            <li title="QCWatch Report">
              <NavLink to="/admin/qcwatch">
                <span>
                  <HiOutlineExclamationCircle />
                </span>
                <span>QCWatch Report</span>
              </NavLink>
            </li>
          )}
          {(groups.find((x) => x === 'Admin') ||
            groups.find((x) => x === 'QCActionAdmin')) && (
            <li title="QCAction Report">
              <NavLink to="/admin/qcaction">
                <span>
                  <HiOutlineExclamationCircle />
                </span>
                <span>QCAction Report</span>
              </NavLink>
            </li>
          )}
          {(groups.find((x) => x === 'Admin') ||
            groups.find((x) => x === 'QCFoiAdmin') ||
            groups.find((x) => x === 'QCFoiApprover') ||
            groups.find((x) => x === 'QCFoiProcessor')) && (
            <li title="QCFOI Request">
              <NavLink to="/admin/qcfoi">
                <span>
                  <HiOutlineInformationCircle />
                </span>
                <span>QCFOI Request</span>
              </NavLink>
            </li>
          )}
        </ul>
        <ul className={`navbar-nav ${collapse ? 'navbar-nav-collapse' : ''}`}>
          {(groups.find((x) => x === 'Admin') ||
            groups.find((x) => x === 'QCFoiAdmin')) && (
            <li title="Accounts">
              <NavLink to="/admin/accounts">
                <span>
                  <HiOutlineUserGroup />
                </span>
                <span>Accounts</span>
              </NavLink>
            </li>
          )}
          {groups.find((x) => x === 'Admin') && (
            <li title="Department">
              <NavLink to="/admin/department">
                <span>
                  <HiOutlineOfficeBuilding />
                </span>
                <span>Department</span>
              </NavLink>
            </li>
          )}
          {(groups.find((x) => x === 'Admin') ||
            groups.find((x) => x === 'QCFoiAdmin') ||
            groups.find((x) => x === 'QCFoiApprover') ||
            groups.find((x) => x === 'QCFoiProcessor')) && (
            <>
              <li title="File Log">
                <NavLink to="/admin/filelog">
                  <span>
                    <HiOutlineDocumentText />
                  </span>
                  <span>File Log</span>
                </NavLink>
              </li>
              <li title="History">
                <NavLink to="/admin/history">
                  <span>
                    <HiOutlineClock />
                  </span>
                  <span>History</span>
                </NavLink>
              </li>
              <li title="FOI Report">
                <NavLink to="/admin/report">
                  <span>
                    <HiOutlineDocumentReport />
                  </span>
                  <span>Report</span>
                </NavLink>
              </li>
            </>
          )}
          {groups.find((x) => x === 'Admin') && (
            <li title="Department">
              <NavLink to="/admin/settings">
                <span>
                  <HiOutlineCog />
                </span>
                <span>Settings</span>
              </NavLink>
            </li>
          )}
          <li title="Change Password">
            <NavLink to="/admin/changepassword">
              <span>
                <HiOutlineLockClosed />
              </span>
              <span>Change Password</span>
            </NavLink>
          </li>
          <li title="Logout">
            <button onClick={signOut} className="logout">
              <span>
                <HiOutlineLogout />
              </span>
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default SideNav
