import { memo } from 'react'
import Hero from '../components/Hero/Hero'

function Page404() {
  return (
    <div className="page-40X-wrapper">
      <Hero />
      <section className="page-40X">
        <h1 className="page-40X-title" aria-label="404 error">
          4<span>0</span>4 Error
        </h1>
        <p className="page-40X-subtitle">
          Sorry, the page you requested cannot be found.
        </p>
      </section>
    </div>
  )
}

export default memo(Page404)
