import DataTable from 'react-data-table-component'
import Loading from '../Loading/Loading'

const styles = {
  headCells: {
    style: {
      fontSize: '14px',
      paddingLeft: '8px',
      paddingRight: '8px',
      fontWeight: '600',
      textTransform: 'uppercase',
    },
  },
  cells: {
    style: {
      fontSize: '13px',
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
}

function Table({ columns, data, loading, loadingComponent = '' }) {
  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={styles}
      progressPending={loading}
      progressComponent={
        loadingComponent === '' ? <Loading /> : loadingComponent
      }
      defaultSortFieldId="createDate"
      defaultSortAsc={false}
      striped
      pagination
    />
  )
}

export default Table
