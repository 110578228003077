import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

const Tel = ({
  name,
  label,
  column = 12,
  required = false,
  min = 11,
  max = 11,
  state,
  validated = false,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    const feedback = document.querySelector(`[name="${name}-feedback"]`)

    if (state.length < 1) {
      feedback.style.display = 'block'
      setStatus({ valid: false, invalid: true })
    }
    if (state.length > 0) {
      if (
        state.length >= min &&
        state.length <= max &&
        state.match('^[0-9]*$') !== null
      ) {
        feedback.style.display = 'none'
        setStatus({ valid: true, invalid: false })
      } else {
        feedback.style.display = 'block'
        setStatus({ valid: false, invalid: true })
      }
    }
  }, [state, validated])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <Form.Control
        type="tel"
        name={name}
        required={required}
        minLength={min}
        maxLength={max}
        isValid={status.valid}
        isInvalid={status.invalid}
        {...props}
      />
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        This field is required.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Tel
