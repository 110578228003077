import { memo } from 'react'
import { Modal } from 'react-bootstrap'

function Header({ title, innerRef }) {
  return (
    <Modal.Header closeButton ref={innerRef}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
  )
}

export default memo(Header)
