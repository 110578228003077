import { Storage } from 'aws-amplify'
import { Form, Modal } from 'react-bootstrap'
import { HiOutlinePlus } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import * as Yup from 'yup'
import { checkUserQCAction, createQCAction } from '../../../database'
import useReport from '../../../hooks/useReport'
import { sendSubmitEmail } from '../../../utilities/Requests'
import Button from '../../Button/Button'
import Check from '../../Input/Check'
import Input from '../../Input/Input'
import TextArea from '../../Input/TextArea'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'

function Create({ user, complaintTypes, setReports }) {

  const actionSchema = Yup.object().shape({
    complaints: Yup.array().min(1).required('Please select a complaint type.'),
    detailsOfComplaint: Yup.string().required(
      'Please add details of complaint.'
    ),
    attachment: Yup.mixed().test(
      'fileSize',
      'File Size is too large.',
      (value) => {
        if (value?.size) {
          return value.size <= 5000000
        }
        return true
      }
    )
  })

  const {
    state,
    handleCreate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
  } = useReport()

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    try {
      const sanitizedData = await actionSchema.validate(state.report)

      // Check if the user already submitted a report today.
      if (
        (await checkUserQCAction(user.id)) >= 1 &&
        process.env.REACT_APP_ENV !== 'development'
      ) {
        setAlert({
          variant: 'danger',
          message: 'Only 1 report is allowed per day.',
        })
        return
      }

      // Upload to storage if the report has an attachment.
      const file = state.report.attachment?.name
        ? await Storage.put(
            'QCACTION/' + state.report.attachment.name,
            state.report.attachment
          )
        : ''

      const response = await createQCAction({
        ...sanitizedData,
        qcitizenID: user?.id,
        userId: user.userId,
        attachment: file ? file.key : '',
      })
      setReports((prevState) => [response, ...prevState])
      sendSubmitEmail(user, response, 'ACTION')
      NotificationManager.success(
        `Your report has been successfully submitted.`
      )
      document.querySelector('#close').click()
    } catch (error) {
      console.log(error)
      if (error instanceof Yup.ValidationError) {
        setAlert({
          variant: 'danger',
          message: error.errors[0],
        })
      } else {
        setAlert({
          variant: 'danger',
          message: 'Submission of report failed, please try again later.',
        })
      }
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePlus />} onClick={handleCreate}>
        Add Report
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="File a report" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <p className="semi-bold">Complaint</p>
            <Check
              name="complaints"
              label="Administrative Complaint / Case against a government employee or official"
              onChange={handleInput}
              list={complaintTypes}
              state={state.report.complaints}
              validated={state.validated}
            />
            <TextArea
              name="detailsOfComplaint"
              label="Details of Complaint"
              onChange={handleInput}
              rows={4}
              state={state.report.detailsOfComplaint}
              validated={state.validated}
              required={true}
            />
            <Input
              name="attachment"
              label="Proofs/Evidences/Attachments"
              onChange={handleInput}
              type="file"
            />
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="blue" loading={state.loading}>
              {state.loading ? 'Submitting' : 'Submit'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Create
