import Search from '../Search'
import Date from '../Date'
import Button from '../Button'

function Filter({ onChange, onClick, filter, children }) {
  return (
    <div className="table-filter">
      <Search onChange={onChange} />
      <Date
        name="start"
        label="From"
        onChange={onChange}
        defaultValue={filter.start}
      />
      <Date
        name="end"
        label="To"
        onChange={onChange}
        defaultValue={filter.end}
      />
      <div className="search-add">
        <Button onClick={onClick} />
        {children}
      </div>
    </div>
  )
}

export default Filter
