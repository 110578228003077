import moment from 'moment'

export const getYears = () => {
  let date = new Date().getFullYear()
  const years = []

  for (let year = 2022; year <= date; year++) {
    years.push(year)
  }
  return years
}

export const getDaysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate()
}

export const setCheckBox = (value, list) => {
  let newList = [...list]
  let index = newList.indexOf(value)
  if (index !== -1) {
    newList.splice(index, 1)
  } else {
    newList = [...newList, value]
  }
  return newList
}

// Function that with camel case an object as parameter
export const camelCase = (obj) => {
  const newObj = {}
  Object.keys(obj).forEach((key) => {
    const newKey = key.replace(/_([a-z])/g, (g) => g[1].toUpperCase())
    newObj[newKey] = obj[key]
  })
  return newObj
}

export const startDate = (start) => {
  return moment(`${start}T00:00:00.000Z`).subtract(8, 'hours').toISOString()
}

export const endDate = (end) => {
  return moment(`${end}T23:59:59.999Z`).subtract(8, 'hours').toISOString()
}

export const getMessage = (status, department = '') => {
  let message = ''
  switch (status) {
    case 'accepted':
      message = `Hi, Thank you for submitting an FOI request. After consideration, we would like to inform you that your FOI request has been forwarded to ${department}, which is the most appropriate department to determine whether to release the information/document that you are requesting and will take with the necessary action over your FOI request.`
      break
    case 'denied':
      message = `Hi, Thank you for submitting an FOI request. After consideration, we regret to inform you that your request has been denied. Please check you email to see the reason for the denial of your request.
      If you are dissatisfied with this response, you may submit a Verified Written Appeal within fifteen (15) calendar days from the date when you received this letter denying your request, or from the lapse of the Processing Period, or any extension thereof, in accordance with Section 13.1, Rule IV of the Quezon City FOI Manual.
      The Office of the City Mayor shall rule on the appeal within fifteen (15) working days from the filing of the appeal. In all cases, the failure of the Office of the City Mayor to rule on the appeal within the foregoing period shall be deemed a denial of the appeal.
      In case of the denial of the appeal, or the lapse of the period to decide on the appeal, the Requesting Party may file the appropriate action in the proper courts in accordance with the Rules of Court.
      `
      break
    case 'forwarded':
      message = `Hi, Thank you for submitting an FOI request. After consideration, we would like to inform you that there are no records found that is responsive to the request as far as our Office or Department is concerned. We will forward this FOI request to the appropriate Office or Department through the most expeditious manner. The appropriate office will inform you that the request has already been forwarded to them and will take with the necessary action over your FOI request.`
      break
    default:
      break
  }
  return message
}
