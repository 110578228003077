import { useReducer } from 'react'
import { NotificationManager } from 'react-notifications'
import {
  RequestReducer,
  INITIAL_STATE,
  ACTION,
} from '../reducer/RequestReducer'
import { Storage } from 'aws-amplify'

function useRequest(user = {}, request = {}) {
  const [state, dispatch] = useReducer(RequestReducer, INITIAL_STATE)

  const handleView = async () => {
    if (request.attachment) {
      try {
        const response = await Storage.get(request.attachment)
        dispatch({
          type: ACTION.SET_ATTACHMENT,
          payload: response,
        })
      } catch (error) {
        NotificationManager.error(
          `Problems occurred when retrieving attachment. Please try again later.`
        )
      }
    }
    dispatch({ type: ACTION.HANDLE_VIEW })
  }

  const handleCreate = () => {
    dispatch({
      type: ACTION.HANDLE_CREATE,
      payload: user,
    })
  }

  const handleUpdate = () => {
    dispatch({
      type: ACTION.HANDLE_UPDATE,
      payload: {
        ...request,
        attachment: null,
      },
    })
    handleView()
  }

  const handleCancel = () => {
    dispatch({
      type: ACTION.HANDLE_CANCEL,
    })
  }

  const handleClose = () => {
    dispatch({
      type: ACTION.HANDLE_CLOSE,
    })
  }

  const handleInput = (e) => {
    if (e.target.name === 'qcdepartmentID') {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: e.target.value,
        },
      })
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: 'transactionId',
          value: e.target.options[e.target.selectedIndex].dataset.code + '-',
        },
      })
    } else if (e.target.name === 'attachment') {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: e.target.files[0],
        },
      })
    } else {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          property: e.target.name,
          value: e.target.value,
        },
      })
    }
  }

  const handleUser = (e) => {
    dispatch({
      type: ACTION.HANDLE_USER,
      payload: {
        property: e.target.name,
        value: e.target.value,
      },
    })
  }

  const setLoading = () => {
    dispatch({
      type: ACTION.SET_LOADING,
    })
  }

  const setAlert = (alertRef, alert) => {
    dispatch({
      type: ACTION.SET_ALERT,
      payload: alert,
    })
    alertRef.current?.scrollIntoView({ block: 'start', behavior: 'smooth' })
  }

  const setValidated = (value) => {
    dispatch({
      type: ACTION.SET_VALIDATED,
      payload: value,
    })
  }

  const setAttachment = () => {
    dispatch({
      type: ACTION.SET_ATTACHMENT,
      payload: '',
    })
  }

  const checkInputValidity = (required) => {
    for (let item of required) {
      if (state.request[item] === '') {
        return false
      }
    }
    return true
  }

  const handleCertification = () => {
    dispatch({ type: ACTION.HANDLE_CERTIFICATION })
  }

  return {
    state,
    handleView,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleClose,
    handleInput,
    handleUser,
    setLoading,
    setAlert,
    setValidated,
    setAttachment,
    checkInputValidity,
    handleCertification,
  }
}

export default useRequest
