import { useContext, useRef } from 'react'
import { Storage } from 'aws-amplify'
import { UserContext } from '../../../context'
import { HiOutlinePencilAlt, HiOutlineTrash } from 'react-icons/hi'
import { Modal, Form } from 'react-bootstrap'
import {
  updateQCFoi,
  updateQCFoiStatus,
  createMessage,
  createQCFoiHistory,
  createFileLog,
} from '../../../database'
import {
  sendUpdateEmail,
  sendDeniedRequestEmail,
  sendAcceptRequestEmail,
} from '../../../utilities/Requests'
import { STATUS_CLASS, STATUS } from '../../../utilities/Constants'
import { getMessage } from '../../../utilities/Helpers'
import { filesize } from 'filesize'
import useRequest from '../../../hooks/useRequest'
import Button from '../../Button/Button'
import TextArea from '../../Input/TextArea'
import Select from '../../Input/Select'
import File from '../../Input/File'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Field from '../../Field/Field'
import Citizen from '../../Details/Citizen'
import Request from '../../Details/Request'
import Forward from './Forward'
import Deny from './Deny'
import NotificationManager from 'react-notifications/lib/NotificationManager'

function Edit({
  request,
  citizen,
  departments,
  setRequests,
  refresh,
  setting,
}) {
  const { currentUser, currentUserGroups } = useContext(UserContext)

  const alertRef = useRef()

  const {
    state,
    handleUpdate,
    handleCancel,
    handleClose,
    handleInput,
    setLoading,
    setAlert,
    setAttachment,
  } = useRequest(citizen, request)

  const handleSubmit = async (e) => {
    setLoading()
    e.preventDefault()
    e.stopPropagation()

    if (
      state.request.status > 4 &&
      state.request.copy === '0' &&
      state.request.attachment === null &&
      state.attachment === ''
    ) {
      setAlert(alertRef, {
        variant: 'danger',
        message:
          'Please select a file before tagging the request as Granted or Partially Granted.',
      })
      return
    }

    let file = ''
    if (state.request.attachment?.name) {
      file = await Storage.put(
        `QCFOI/${request.qcdepartmentID}/` + state.request.attachment.name,
        state.request.attachment
      )
      createFileLog({
        filename: state.request.attachment.name,
        transactionId: request.transactionId,
        size: filesize(state.request.attachment.size),
        qcfoiID: request.id,
        email: currentUser.attributes.email,
        department: departments.find((x) => x.id === request.qcdepartmentID)
          ?.name,
      })
    }

    try {
      const response = await updateQCFoi(request, {
        ...state.request,
        attachment: file ? file.key : request.attachment,
      })
      if (response.status === '3') {
        handlePostUpdate(response, 'denied')
      } else {
        handlePostUpdate(response, 'update')
      }
      NotificationManager.success('Request has been successfully updated.')
      document.querySelector('#close').click()
    } catch (error) {
      setAlert(alertRef, {
        variant: 'danger',
        message:
          'Problem occurred when changing the request. Please try again later.',
      })
    }
  }

  const handleAccept = async () => {
    setLoading()
    try {
      const response = await updateQCFoiStatus(request.id, '0', 'citizen')
      handlePostUpdate(response, 'accepted')
      NotificationManager.success('The request has been successfully accepted.')
      document.querySelector('#close').click()
    } catch (error) {
      setAlert(alertRef, {
        variant: 'danger',
        message:
          'Problem occurred when changing the request. Please try again later.',
      })
    }
  }

  const handleDenied = async () => {
    setLoading()
    try {
      const response = await updateQCFoiStatus(request.id, '3', 'citizen')
      handlePostUpdate(response, 'denied')
      NotificationManager.success('The request has been denied.')
      document.querySelector('#close').click()
    } catch (error) {
      setAlert(alertRef, {
        variant: 'danger',
        message:
          'Problem occurred when changing the request. Please try again later.',
      })
    }
  }

  const handlePostUpdate = (response, update = '') => {
    setRequests((prevState) =>
      prevState.map((item) =>
        item.request.id === response.id ? { ...item, request: response } : item
      )
    )
    const department = departments.find(
      (x) => x.id === response.qcdepartmentID
    ).name
    const prevDepartment = departments.find(
      (x) => x.id === request.qcdepartmentID
    ).name
    createQCFoiHistory(
      'Updated',
      currentUser.attributes.email,
      department,
      response,
      { ...request, department: prevDepartment }
    )
    if (update === 'accepted') {
      sendAcceptRequestEmail(citizen, response, department)
    } else if (update === 'denied') {
      sendDeniedRequestEmail(citizen, response, department)
    } else {
      sendUpdateEmail(citizen, response, 'FOI', request)
      return
    }
    createMessage({
      email: currentUser.attributes.email,
      qcfoiID: response.id,
      message: getMessage(update, department),
    })
  }

  return (
    <>
      <Button
        color="blue"
        icon={<HiOutlinePencilAlt />}
        onClick={handleUpdate}
        title="Edit"
      >
        <span className="btn-expand">Edit</span>
      </Button>
      <Modal
        show={state.show}
        onHide={handleCancel}
        backdrop="static"
        size="lg"
        id="request"
      >
        <Header closeButton title="Edit Request" innerRef={alertRef} />
        <Body
          show={state.alert.show}
          variant={state.alert.variant}
          message={state.alert.message}
        >
          <Citizen citizen={citizen} foi={true} />
          <hr />
          <Request request={request} departments={departments} edit={true} />
          {/* {(!currentUserGroups.find(x => x === "QCFoiProcessor") && state.request.status !== "2") &&
            <Form.Group className="mb-3" controlId="copy" onChange={ handleInput }>
              <Form.Label><span className='semi-bold'>Requested Copy:</span></Form.Label>
              <Form.Check value="0" type="radio" label="Digital Copy" name="copy" id="check1" defaultChecked={state.request.copy === "0" ? true : false}/>
              <Form.Check value="1" type="radio" label="Physical Copy" name="copy" id="check2" defaultChecked={state.request.copy === "1" ? true : false}/>
            </Form.Group>
          } */}
          {(currentUserGroups.find((x) => x === 'QCFoiProcessor') ||
            state.request.status === '2') && (
            <Field name="Status">
              {state.request.copy === '0' ? 'Digital Copy' : 'Physical Copy'}
            </Field>
          )}
          {state.request.status !== '2' ? (
            <Select
              name="status"
              label="Status"
              onChange={handleInput}
              validated={state.validated}
              state={state.request.status}
              defaultValue={state.request.status}
            >
              {!currentUserGroups.find((x) => x === 'QCFoiProcessor') && (
                <>
                  <option value="0">Pending</option>
                  {state.request.status === '3' && (
                    <option value="3">Denied</option>
                  )}
                </>
              )}
              <option value="4">Approved for Processing</option>
              <option value="5">Partially Granted</option>
              <option value="6">Granted</option>
            </Select>
          ) : (
            <Field name="Status">
              <span className={`status ${STATUS_CLASS[state.request.status]}`}>
                {STATUS[state.request.status]}
              </span>
            </Field>
          )}
          {state.request.status !== '2' ? (
            <TextArea
              name="remarks"
              label="Remarks"
              onChange={handleInput}
              rows={2}
              validated={state.validated}
              state={state.request.remarks}
              defaultValue={state.request.remarks}
            />
          ) : (
            state.request.remarks && (
              <Field name="Remarks">{state.request.remarks}</Field>
            )
          )}
          {state.request.status > 4 && state.request.copy === '0' && (
            <File
              name="attachment"
              label="Information/Documents/Records requested"
              onChange={handleInput}
              validated={state.validated}
              state={state.request.attachment}
            >
              {state.attachment && state.request.status > 4 && (
                <>
                  <p className="attachment">
                    <a
                      className="download"
                      href={state.attachment}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {request.attachment?.split('/')[2]}
                    </a>
                    <button className="action-btn red" onClick={setAttachment}>
                      <HiOutlineTrash />
                    </button>
                  </p>
                  <Form.Label>
                    <span className="semi-bold">Change attachment:</span>
                  </Form.Label>
                </>
              )}
            </File>
          )}
        </Body>

        <Footer>
          {state.request.status === '2' ? (
            <>
              <Button color="gray" id="close" onClick={handleClose}>
                Close
              </Button>
              <Deny
                request={request}
                citizen={citizen}
                departments={departments}
                setRequests={setRequests}
                form={true}
                setting={setting}
              />
              <Forward
                request={request}
                citizen={citizen}
                departments={departments}
                refresh={refresh}
                form={true}
                setting={setting}
              />
              <Button
                color="blue"
                loading={state.loading}
                onClick={handleAccept}
              >
                {state.loading ? 'Accepting' : 'Accept'}
              </Button>
            </>
          ) : (
            <>
              <Button color="gray" id="close" onClick={handleClose}>
                Close
              </Button>
              <Deny
                request={request}
                citizen={citizen}
                departments={departments}
                setRequests={setRequests}
                form={true}
                setting={setting}
              />
              <Button
                color="blue"
                loading={state.loading}
                onClick={handleSubmit}
              >
                {state.loading ? 'Saving' : 'Save'}
              </Button>
            </>
          )}
        </Footer>
      </Modal>
    </>
  )
}

export default Edit
