import { memo } from 'react'
import { STATUS, STATUS_CLASS } from '../../utilities/Constants'
import { HiDownload } from 'react-icons/hi'
import moment from 'moment/moment'
import Field from '../Field/Field'

function Request({
  request,
  attachment,
  departments,
  edit = false,
  admin = false,
  setting,
}) {
  return (
    <>
      {request?.remarks && !edit && (
        <Field name="Remarks">
          <span className="important">{request.remarks}</span>
        </Field>
      )}
      <Field name="Reference Number">{request.transactionId}</Field>
      {admin && (
        <Field name="Date Received">
          {moment(request.receivedAt).format('YYYY-MM-DD')}
        </Field>
      )}
      <Field name="Department">
        {request?.department
          ? request?.department
          : departments.find((x) => x.id === request.qcdepartmentID)?.name}
      </Field>
      <Field name="Information/Documents/Records requested">
        {request.requestedInformation}
      </Field>
      <Field name="Specific Purpose">{request.purposeOfRequest}</Field>
      <Field name="Requested Copy">
        {request.copy === '0' ? 'Digital Copy' : 'Physical Copy'}
      </Field>
      {!edit && (
        <>
          {admin && (
            <Field name="Deadline">
              {moment(request.deadline).format('YYYY-MM-DD')}
            </Field>
          )}
          <Field name="Status">
            <span className={`status ${STATUS_CLASS[request.status]}`}>
              {STATUS[request.status]}
            </span>
          </Field>
          {request.attachment && request.copy === '0' && request.status > 4 && (
            <>
              <Field name="Copy of Information/Documents/Records requested"></Field>
              {admin && (
                <a
                  className="download"
                  href={attachment}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {request.attachment?.split('/')[2]} <HiDownload />
                </a>
              )}
              {!admin && (
                <>
                  {moment(request.completeDate).add(
                    setting?.expiration,
                    'days'
                  ) >= moment() ? (
                    <>
                      <a
                        className="download mb-3"
                        href={attachment}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {request.attachment?.split('/')[2]} <HiDownload />
                      </a>
                      <p className="text-danger">
                        The attachment will only be available until{' '}
                        {moment(request.completeDate)
                          .add(setting?.expiration, 'days')
                          .format('lll')}
                        .
                      </p>
                    </>
                  ) : (
                    <p className="text-danger">
                      The attachment is no longer available.
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  )
}

export default memo(Request)
