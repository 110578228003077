import { DataStore } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { QCAction } from '../models'
import { endDate, startDate } from '../utilities/Helpers'

const useAction = (filter) => {
  const [action, setAction] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  const fetchAction = async () => {
    setLoading(true)
    try {
      const query = (c) =>
        c
          .createdAt('ge', startDate(filter.start))
          .createdAt('le', endDate(filter.end))
          .userId(filter.userId ? 'eq' : 'ne', filter.userId || null)

      const response = await DataStore.query(QCAction, query)

      setAction(response)
    } catch (error) {
      console.log(error)
      setError(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAction()
  }, [])

  return { action, error, loading, setAction, fetchAction }
}

export default useAction
