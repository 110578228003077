import { useContext } from 'react'
import { Modal, Form } from 'react-bootstrap'
import {
  createMessage,
  createQCFoiHistory,
  updateQCFoiStatus,
} from '../../../database'
import { sendDeniedRequestEmail } from '../../../utilities/Requests'
import useCommon from '../../../hooks/useCommon'
import { getMessage } from '../../../utilities/Helpers'
import { DENY_REASON, FOI_EXCEPTION } from '../../../utilities/Constants'
import { UserContext } from '../../../context'
import Button from '../../Button/Button'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import TextArea from '../../Input/TextArea'
import NotificationManager from 'react-notifications/lib/NotificationManager'

function Deny({ request, citizen, departments, setRequests }) {
  const { currentUser, currentUserGroups } = useContext(UserContext)
  const {
    state,
    handleUpdate,
    handleClose,
    handleInput,
    handleCheck,
    setLoading,
    setAlert,
  } = useCommon({
    reasons: [],
    others: '',
  })

  const handleDeny = async () => {
    setLoading()
    if (state.data.reasons.length > 0 || state.data.others !== '') {
      const response = await updateQCFoiStatus(request.id, '3', 'citizen')
      setRequests((prevState) =>
        prevState.map((item) =>
          item.request.id === response.id
            ? { ...item, request: response }
            : item
        )
      )
      sendDeniedRequestEmail(citizen, request, state.data)
      createMessage({
        email: currentUser.attributes.email,
        qcfoiID: response.id,
        message: getMessage('denied'),
      })
      createQCFoiHistory(
        'Updated',
        currentUser.attributes.email,
        departments.find((x) => x.id === response.qcdepartmentID).name,
        response
      )
      NotificationManager.success('The request has been denied.')
      document.querySelector('#close').click()
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please choose denial reason.',
      })
    }
  }

  const showModal = () => {
    document.querySelector('#request').style.display = 'none'
    handleUpdate()
  }

  const closeModal = () => {
    document.querySelector('#close').click()
    handleClose()
  }

  return (
    <>
      {!currentUserGroups.find((x) => x === 'QCFoiProcessor') &&
        request.status !== '3' && (
          <Button color="red" title="Deny" onClick={showModal}>
            Deny
          </Button>
        )}
      <Modal
        show={state.show}
        onHide={closeModal}
        backdrop="static"
        size="lg"
      >
        <Header title="Deny Request" />
        <Body
          show={state.alert.show}
          variant={state.alert.variant}
          message={state.alert.message}
        >
          <Form.Group className="mb-3 check" controlId="reason">
            <Form.Label className="semi-bold">Reason for Denial</Form.Label>
            {DENY_REASON.map((item, id) =>
              id === 0 ? (
                <Form.Check type="checkbox" id={id} key={id}>
                  <Form.Check.Input
                    type="checkbox"
                    name="reasons"
                    value={item}
                    onChange={handleCheck}
                  />
                  <Form.Check.Label>
                    {item}
                    <small className="foi-exception">
                      <ol>
                        {FOI_EXCEPTION.map((exception, index) => (
                          <li key={index}> {exception} </li>
                        ))}
                      </ol>
                    </small>
                  </Form.Check.Label>
                </Form.Check>
              ) : (
                <Form.Check
                  type="checkbox"
                  key={id}
                  id={id}
                  name="reasons"
                  label={item}
                  value={item}
                  onChange={handleCheck}
                />
              )
            )}
          </Form.Group>
          <TextArea
            name="others"
            label="Others"
            onChange={handleInput}
            rows={2}
            validated={state.validated}
            state={state.data.explanation}
            value={state.data.explanation}
          />
        </Body>
        <Footer>
          <Button color="red" id="close" onClick={closeModal}>
            Cancel
          </Button>
          <Button color="blue" loading={state.loading} onClick={handleDeny}>
            {state.loading ? 'Denying' : 'Deny'}
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default Deny
