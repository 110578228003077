import moment from 'moment'
import { useState } from 'react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer'
import { useLocation, useNavigate } from 'react-router-dom'
import Menu from '../components/Button/Menu'
import Refresh from '../components/Button/Refresh'
import Filter from '../components/Filter/Citizen/Filter'
import Create from '../components/QCAction/Form/Create'
import View from '../components/QCAction/Form/View'
import Table from '../components/Table/Table'
import useAction from '../hooks/useAction'
import useCitizen from '../hooks/useCitizen'
import useComplaintTypes from '../hooks/useComplaintTypes'
import { INITIAL_FILTER } from '../utilities/Constants'

function QCAction() {
  const location = useLocation()
  let temp = location.state
  const { complaintTypes } = useComplaintTypes('action')
  const { citizen, error } = useCitizen(temp)
  const [filter, setFilter] = useState({
    ...INITIAL_FILTER,
    userId: temp?.userId,
  })
  const { action, loading, setAction, fetchAction } = useAction(filter)

  const navigate = useNavigate()

  const columns = [
    {
      name: 'Reference Number',
      width: '200px',
      selector: (row) => row.id,
      format: (row) => <View report={row} action={false} />,
      sortable: true,
    },
    {
      name: 'Details of Complaint',
      selector: (row) => row.detailsOfComplaint,
      format: (row) =>
        row.detailsOfComplaint?.length > 50
          ? row.detailsOfComplaint?.substring(0, 50) + '...'
          : row.detailsOfComplaint,
    },
    {
      name: 'Remarks / Action Taken',
      selector: (row) => row.remarks,
      format: (row) =>
        row.remarks
          ? row.remarks?.length > 50
            ? row.remarks?.substring(0, 50) + '...'
            : row.remarks
          : row.actionTaken
          ? row.actionTaken?.split('/').length > 1
            ? row?.actionTaken?.split('/')[1]?.substring(0, 50) + '...'
            : row?.actionTaken?.substring(0, 50) + '...'
          : '',
    },
    {
      id: 'createDate',
      name: 'Report Date',
      width: '180px',
      selector: (row) => row.createdAt,
      format: (row) => moment(row.createdAt).format('lll'),
      sortable: true,
    },
    {
      name: 'Action',
      cell: (row) => <View report={row} action={true} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ]

  const handleInput = (e) => {
    setFilter((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  if (error) {
    navigate(-1)
  }

  return (
    <div className="citizen-table">
      <div className="table-border">
        <NotificationContainer />
        <h1 className="qcitizen-title">QCAction Reports</h1>
        <div className="table-options">
          <div className="margin-top action-wrapper nav">
            <Menu navigate={navigate} />
            <Refresh refresh={fetchAction} />
          </div>
          <Filter
            onClick={fetchAction}
            onChange={handleInput}
            action={true}
            filter={filter}
          >
            <div className="margin-top action-wrapper">
              <Create
                user={citizen}
                complaintTypes={complaintTypes}
                setReports={setAction}
              />
            </div>
          </Filter>
        </div>
        <Table
          columns={columns}
          loading={loading}
          data={action.filter((item) => {
            if (filter.search === '') {
              return item
            } else if (
              item.transactionId
                .toLowerCase()
                .includes(filter.search.toLowerCase())
            ) {
              return item
            }
            return ''
          })}
        />
      </div>
    </div>
  )
}

export default QCAction
