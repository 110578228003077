import React, { useState } from 'react'
import { HiOutlineEye } from 'react-icons/hi'
import moment from 'moment/moment'
import Modal from 'react-bootstrap/Modal'

import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Field from '../../Field/Field'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'

function ViewDepartment({ department, action }) {
  const [show, setShow] = useState(false)

  return (
    <>
      {action ? (
        <Button
          color="blue"
          icon={<HiOutlineEye />}
          onClick={() => setShow(true)}
        >
          View
        </Button>
      ) : (
        <Button link={true} onClick={() => setShow(true)}>
          {department.name}
        </Button>
      )}
      <Modal show={show} onHide={() => setShow(false)} backdrop="static">
        <Header title="Department Details" />
        <Body>
          <Field name="Code">{department.code}</Field>
          <Field name="Department/Operating Unit/Office">
            {department.name}
          </Field>
          <Field name="Created Date">
            {moment(department.createdAt).format('lll')}
          </Field>
        </Body>
        <Footer>
          <Button color="gray" onClick={() => setShow(false)}>
            Close
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default ViewDepartment
