import {
  useState,
  useEffect
} from 'react'
import moment from 'moment/moment';
import {
  HiOutlineExclamationCircle,
  HiOutlineInformationCircle,
  HiOutlineCalendar,
  HiOutlineFilter
} from 'react-icons/hi';
import {
  getQCAction,
  getQCWatch,
  getQCFoi
} from '../../database'
import {
  MONTHS,
  MONTHS_FULL
} from '../../utilities/Constants';
import {
  getYears,
  getDaysInMonth
} from '../../utilities/Helpers';
import Bar from '../../components/Chart/Bar'
import Button from '../../components/Filter/Button';
import Card from '../../components/Card/Admin/Card';
import Pie from '../../components/Chart/Pie';
import Select from '../../components/Filter/Select';

function Dashboard() {

  const years = getYears();
  const format = "YYYY-MM-DD";

  const [filter, setFilter] = useState({start: 0, end: 11, year: moment().year()})
  const [watches, setWatches] = useState(0);
  const [actions, setActions] = useState(0);
  const [fois, setFois] = useState(0);
  const [todaysData, setTodaysData] = useState(0);
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [date, setDate] = useState({
    start: moment(`${moment().year()}-01-01`).format(format),
    end: moment(`${moment().year()}-12-31`).format(format)
  });

  useEffect(() => {
    fetchData();
    fetchTodaysData();
  }, [])

  const fetchData = async () => {
    let watchTotal = 0;
    let actionTotal = 0;
    let foiTotal = 0;
    let allData = [];
    for (let index = filter.start; index <= filter.end; index++) {
      const start = moment(`${filter.year}-${index+1}-01`, format).format(format);
      const end = moment(`${filter.year}-${index+1}-${getDaysInMonth(index+1, filter.year)}`, format).format(format);
      const watchData = await getQCWatch(start, end);
      watchTotal += watchData.length;
      const actionData = await getQCAction(start, end);
      actionTotal += actionData.length;
      const foiData = await getQCFoi(start, end);
      foiTotal += foiData.length
      allData.push({"index": MONTHS[index], "QCWatch": watchData.length, "QCAction": actionData.length, "QCFOI": foiData.length});
    }
    setWatches(watchTotal);
    setActions(actionTotal);
    setFois(foiTotal);
    setData(allData);
    setPieData([
      {"id": "QCWatch", "label": "QCWatch", "value": watchTotal / (watchTotal + actionTotal + foiTotal)},
      {"id": "QCAction", "label": "QCAction", "value": actionTotal / (watchTotal + actionTotal + foiTotal)},
      {"id": "QCFOI", "label": "QCFOI", "value": foiTotal / (watchTotal + actionTotal + foiTotal)}
    ])
  }

  const fetchTodaysData = async () => {
    const currentDate = moment().format(format);
    const watchData = await getQCWatch(currentDate, currentDate);
    const actionData = await getQCAction(currentDate, currentDate);
    const foiData = await getQCFoi(currentDate, currentDate);
    setTodaysData(watchData.length + actionData.length + foiData.length);
  }

  const handleFilter = (e) => {
    setFilter(prevState => ({...prevState, [e.target.name]: parseInt(e.target.value)}))
    setDate({
      start: moment(`${filter.year}-${filter.start+1}-01`, format).format(format),
      end: moment(`${filter.year}-${filter.end+1}-${getDaysInMonth(filter.end+1, filter.year)}`, format).format(format)
    })
  }

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">Dashboard</h1>
      <div className="dashboard">
        <Card
          title="QCitizen Watch"
          count={`${watches} Total reports`}
          icon={<HiOutlineExclamationCircle />}
          path="/admin/qcwatch"
          state={date}
        />
        <Card
          title="QCitizen Action"
          count={`${actions} Total reports`}
          icon={<HiOutlineExclamationCircle />}
          path="/admin/qcaction"
          state={date}
        />
        <Card
          title="QCitizen FOI"
          count={`${fois} Total requests`}
          icon={<HiOutlineInformationCircle />}
          path="/admin/qcfoi"
          state={date}
        />
        <Card
          title="Today"
          count={`${todaysData} Total reports / requests`}
          icon={<HiOutlineCalendar />}
        />
      </div>
      <div className="chart-options">
        <div className="chart-filter admin-nav">
          <Select
            name="start"
            label="Start Month"
            onChange={handleFilter}
            defaultValue={filter.start}
          >
            {MONTHS_FULL.map((month, index) => (
              <option value={index} key={index}>
                {month}
              </option>
            ))}
          </Select>
          <Select
            name="end"
            label="End Month"
            onChange={handleFilter}
            defaultValue={filter.end}
          >
            {MONTHS_FULL.map((month, index) => (
              <option value={index} key={index}>
                {month}
              </option>
            ))}
          </Select>
          <Select
            name="year"
            label="Year"
            onChange={handleFilter}
            defaultValue={filter.year}
          >
            {years.map((year, index) => (
              <option value={year} key={index}>
                {year}
              </option>
            ))}
          </Select>
          <div className='search-add'>
            <Button onClick={fetchData}>
              <HiOutlineFilter /> Filter
            </Button>
          </div>
        </div>
      </div>
      <div className="chart">
        <Bar data={data} keys={['QCWatch', 'QCAction', 'QCFOI']} />
      </div>
      <div className="chart">
        <Pie data={pieData} />
      </div>
    </section>
  )
}

export default Dashboard
