import { Link } from 'react-router-dom'

function Card({ title, count, icon, path = '', ...props }) {
  return (
    <Link to={path} {...props} className="dashboard-card">
      <section>
        <h2 className="dashboard-card-title">{title}</h2>
        <p className="count">{count}</p>
      </section>
      {icon}
    </Link>
  )
}

export default Card
