import { HiOutlinePencilAlt } from 'react-icons/hi'
import { Modal, Form } from 'react-bootstrap'
import NotificationManager from 'react-notifications/lib/NotificationManager'

import useCommon from '../../../hooks/useCommon'
import { updateQCDepartment } from '../../../database'
import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'
import Input from '../../Input/Input'

function EditDepartment({ department, setDepartments }) {
  const required = ['code', 'name']

  const {
    state,
    handleUpdate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  } = useCommon(department)

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (
      form.checkValidity() === true &&
      checkInputValidity(required) === true
    ) {
      try {
        const response = await updateQCDepartment(department.id, state.data)
        setDepartments((prevState) =>
          prevState.map((item) => (item.id === response.id ? response : item))
        )
        NotificationManager.success(
          `${response.name} has been successfully updated.`
        )
        document.querySelector('#close').click()
      } catch (error) {
        setAlert({
          variant: 'danger',
          message:
            'Problem occurred when creating Department/Operating Unit/Office. Please try again later.',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePencilAlt />} onClick={handleUpdate}>
        Edit
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="Edit Department" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Input
              name="code"
              label="Code"
              onChange={handleInput}
              required={true}
              value={state.data.code}
              validated={state.validated}
              state={state.data.code}
            />
            <Input
              name="name"
              label="Department/Operating Unit/Office"
              required={true}
              onChange={handleInput}
              value={state.data.name}
              validated={state.validated}
              state={state.data.name}
            />
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="blue" type="submit" loading={state.loading}>
              {state.loading ? 'Saving' : 'Save'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default EditDepartment
