import {
  useState,
  useEffect,
  useContext
} from 'react'
import moment from 'moment/moment';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { INITIAL_FILTER } from '../../utilities/Constants';
import { UserContext } from '../../context';
import {
  getQCDepartment,
  getFileLog
} from '../../database'
import Filter from '../../components/Filter/Admin/Filter';
import Refresh from '../../components/Button/Refresh';
import Table from '../../components/Table/Table';

function QCFoiHistory() {

  const { currentUser } = useContext(UserContext);

  const [logs, setLogs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filter, setFilter] = useState({
    ...INITIAL_FILTER,
    department: ""
  });


  useEffect(() => {
    if(departments.length < 1) {
      getQCDepartment()
      .then((value) => setDepartments(value));
    } else {
      fetchFileLog();
    }
  }, [departments]);

  const fetchFileLog = async () => {
    let department = "";
    if(currentUser.attributes['custom:department']) {
      department = currentUser.attributes['custom:department'];
    } else {
      department = filter.department;
    }
    try {
      const response = await getFileLog({...filter, department: departments.find(x => x.id === department)?.name});
      setLogs(response);
    } catch (error) {
      NotificationManager.error(`Problems occurred when retrieving reports. Please try again later.`);
    }
  }

  const handleInput = (e) => {
    setFilter(prevState => ({...prevState, [e.target.name]: e.target.value}));
  }

  const columns = [
    {
      name: 'Uploaded by',
      selector: row => row.email,
      width: "300px",
      sortable: true,
    },
    {
      name: 'Filename',
      // width: "180px",
      selector: row => row.filename,
      sortable: true,
    },
    {
      name: 'Size',
      width: "180px",
      selector: row => row.size,
      sortable: true,
    },
    {
      id: 'createDate',
      name: 'Upload date',
      selector: row => moment(row.createdAt).format('lll'),
      width: "200px",
      sortable: true
    },
    {
      name: 'Attached to',
      selector: row => row.transactionId,
      width: "200px",
      sortable: true,
    },
  ];

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">File Log</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchFileLog} />
        </div>
        <Filter
          onChange={handleInput}
          list={departments}
          onClick={fetchFileLog}
          foi={false}
          property="name"
          filter={filter}
        />
      </div>
      <Table
        columns={columns}
        data={logs.filter((item) => {
          if (filter.search === '') {
            return item
          } else if (
            item.filename.toLowerCase().includes(filter.search.toLowerCase()) ||
            item.transactionId
              .toLowerCase()
              .includes(filter.search.toLowerCase())
          ) {
            return item
          }
          return ''
        })}
      />
    </section>
  )
}

export default QCFoiHistory
