import { DataStore, Predicates, SortDirection } from 'aws-amplify'
import { useEffect, useState } from 'react'
import { QCDepartment, QCWatchDepartment } from '../models'

const useDepartment = (module) => {
  const [departments, setDepartments] = useState([])
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchComplaintTypes = async () => {
      if (!isMounted) {
        return
      }
      setLoading(true)
      try {
        const response =
          module === 'watch'
            ? await DataStore.query(QCWatchDepartment)
            : await DataStore.query(QCDepartment, Predicates.ALL, {
                sort: (s) => s.name(SortDirection.ASCENDING),
              })
        setDepartments(response)
      } catch (error) {
        console.log(error)
        setError(true)
      }
      setLoading(false)
    }

    fetchComplaintTypes()

    return () => {
      isMounted = false
    }
  }, [module])

  return { departments, error, loading }
}

export default useDepartment
