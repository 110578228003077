import React from 'react'
import { useState, useEffect } from 'react'
import { Form, Col } from 'react-bootstrap'

const Number = ({
  name,
  label,
  column = 12,
  required = false,
  state,
  validated = false,
  min = 0,
  max = 15,
  ...props
}) => {
  const [status, setStatus] = useState({ valid: false, invalid: false })
  const feedback = document.querySelector(`[name="${name}-feedback"]`)

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (state === '' || state < min || state > max) {
      feedback.style.display = 'block'
      setStatus({ valid: false, invalid: true })
    } else {
      feedback.style.display = 'none'
      setStatus({ valid: true, invalid: false })
    }
  }, [state, validated])

  return (
    <Form.Group as={Col} className="mb-3" controlId={name} sm={column}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <Form.Control
        type="number"
        name={name}
        pattern="\d"
        required={required}
        isValid={status.valid}
        isInvalid={status.invalid}
        min={min}
        max={max}
        {...props}
      />
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        Please provide a valid input.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Number
