import { Storage } from 'aws-amplify'
import { Alert, Modal } from 'react-bootstrap'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import { updateQCAction } from '../../../database'
import useReport from '../../../hooks/useReport'
import { sendUpdateEmail } from '../../../utilities/Requests'
import Button from '../../Button/Button'
import Citizen from '../../Details/Citizen'
import Report from '../../Details/Report'
import Input from '../../Input/Input'
import TextArea from '../../Input/TextArea'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'

function Edit({ report, citizen, setReports }) {
  const {
    state,
    handleUpdate,
    handleMouseMove,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
  } = useReport(report)

  const handleSubmit = async () => {
    setLoading()

    if (
      (state.report.remarks === '' || state.report.remarks === null) &&
      (state.report.actionTaken === '' || state.report.actionTaken === null)
    ) {
      setAlert({
        variant: 'danger',
        message: 'Please add a remarks or action taken before saving.',
      })
      return
    }

    // Upload to storage if the report has an attachment.
    const file = state.report.actionTaken?.name
      ? await Storage.put(
          'QCACTION/' + state.report.actionTaken.name,
          state.report.actionTaken
        )
      : ''

    try {
      const response = await updateQCAction(report.id, {
        remarks: state.report.remarks,
        actionTaken: file ? file.key : '',
      })
      setReports((prevState) =>
        prevState.map((item) =>
          item.report?.id === response.id
            ? { citizen: item.citizen, report: response }
            : item
        )
      )
      sendUpdateEmail(citizen, response, 'ACTION', report)
      NotificationManager.success(`Report has been successfully saved.`)
      document.querySelector('#close').click()
    } catch (error) {
      setAlert({
        variant: 'danger',
        message:
          'Problem occurred when saving the report. Please try again later.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePencilAlt />} onClick={handleUpdate}>
        Edit
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Header title="Edit Report" />
        <Body>
          <Citizen citizen={citizen} />
          <hr />
          <Report
            report={report}
            attachment={state.attachment}
            zoom={state.zoom}
            handleMouseMove={handleMouseMove}
            view="action"
            edit={true}
          />
          <Alert show={state.alert.show} variant={state.alert.variant}>
            <p>{state.alert.message}</p>
          </Alert>
          <TextArea
            name="remarks"
            label="Remarks/Action Taken"
            onChange={handleInput}
            rows={2}
            value={state.report.remarks}
            state={state.report.remarks}
          />
          <Input name="actionTaken" onChange={handleInput} type="file" />
        </Body>
        <Footer>
          <Button color="red" id="close" onClick={handleCancel}>
            Cancel
          </Button>
          <Button color="blue" loading={state.loading} onClick={handleSubmit}>
            {state.loading ? 'Saving' : 'Save'}
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default Edit
