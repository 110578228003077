import { Storage } from 'aws-amplify'
import { Form, Modal } from 'react-bootstrap'
import { HiOutlinePlus } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import * as Yup from 'yup'
import { checkUserQCWatch, createQCWatch } from '../../../database'
import useReport from '../../../hooks/useReport'
import { sendSubmitEmail } from '../../../utilities/Requests'
import Button from '../../Button/Button'
import Check from '../../Input/Check'
import Input from '../../Input/Input'
import Select from '../../Input/Select'
import TextArea from '../../Input/TextArea'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'

function Create({ user, complaintTypes, departments, setReports }) {
  const watchSchema = Yup.object().shape({
    qcwatchdepartmentID: Yup.string().required('Please select a complaint.'),
    complaints: Yup.array().min(1).required('Please select a complaint type.'),
    detailsOfComplaint: Yup.string().required(
      'Please add details of complaint.'
    ),
    attachment: Yup.mixed().test(
      'fileSize',
      'File Size is too large.',
      (value) => {
        if (value?.size) {
          return value.size <= 5000000
        }
        return true
      }
    ),
  })

  const {
    state,
    handleCreate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
  } = useReport({}, complaintTypes)

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }
    e.preventDefault()
    e.stopPropagation()

    try {
      const sanitizedData = await watchSchema.validate(state.report)

      // Check if the user already submitted a report today.
      if (
        (await checkUserQCWatch(user.id)) >= 1 &&
        process.env.REACT_APP_ENV !== 'development'
      ) {
        setAlert({
          variant: 'danger',
          message: 'Only 1 report is allowed per day.',
        })
        return
      }

      // Upload to storage if the report has an attachment.
      const file = state.report.attachment?.name
        ? await Storage.put(
            'QCACTION/' + state.report.attachment.name,
            state.report.attachment
          )
        : ''

      const response = await createQCWatch({
        ...sanitizedData,
        qcitizenID: user?.id,
        userId: user?.userId,
        attachment: file ? file.key : '',
      })
      setReports((prevState) => [response, ...prevState])
      sendSubmitEmail(
        user,
        {
          ...response,
          violation: departments?.find(
            (x) => x.id === response?.qcwatchdepartmentID
          )?.violationName,
        },
        'WATCH'
      )
      NotificationManager.success(
        `Your report has been successfully submitted.`
      )
      document.querySelector('#close').click()
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        setAlert({
          variant: 'danger',
          message: error.errors[0],
        })
      } else {
        setAlert({
          variant: 'danger',
          message: 'Submission of report failed, please try again later.',
        })
      }
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePlus />} onClick={handleCreate}>
        Add Report
      </Button>
      <Modal
        show={state.show}
        onHide={handleCancel}
        backdrop="static"
        size="lg"
      >
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="File a report" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Select
              name="qcwatchdepartmentID"
              label="Complaint"
              onChange={handleInput}
              margin={state.complaints.length > 0 ? false : true}
              validated={state.validated}
              state={state.report.qcwatchdepartmentID}
              required={true}
              value={state.report.qcwatchdepartmentID}
            >
              <option value="" disabled>
                Select Violation
              </option>
              {departments.map((department) => (
                <option value={department.id} key={department.id}>
                  {department.violationName}
                </option>
              ))}
            </Select>
            {state.complaints.length > 0 && (
              <Check
                name="complaints"
                onChange={handleInput}
                list={state.complaints}
                state={state.report.complaints}
                validated={state.validated}
              />
            )}
            <TextArea
              name="detailsOfComplaint"
              label="Details of Complaint (Brief narration of Facts/Details of events leading to the complaint. State in a sequential/chronological order.)"
              onChange={handleInput}
              rows={4}
              state={state.report.detailsOfComplaint}
              validated={state.validated}
              required={true}
            />
            <Input
              name="attachment"
              label="Proofs/Evidences/Attachments"
              onChange={handleInput}
              type="file"
            />
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button type="submit" color="blue" loading={state.loading}>
              {state.loading ? 'Submitting' : 'Submit'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Create
