import { useEffect, useState } from 'react'
import { camelCase } from '../utilities/Helpers'

const useUser = (userId, email) => {
  const [user, setUser] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true

    const fetchUser = async () => {
      if (!isMounted) {
        return
      }

      setLoading(true)
      try {
        const headers = {
          'Content-Type': 'application/json',
          security_token: process.env.REACT_APP_SECURITY_TOKEN,
        }

        const accessTokenRes = await fetch(
          `${process.env.REACT_APP_APPOINTMENT}/generate_token`,
          {
            method: 'POST',
            headers,
          }
        )

        const accessToken = await accessTokenRes.json()

        const userDetailsRes = await fetch(
          `${process.env.REACT_APP_APPOINTMENT}/get_user_details`,
          {
            method: 'POST',
            headers,
            body: JSON.stringify({
              email,
              user_id: userId,
              qcid: true,
              access_token: accessToken.access_token,
            }),
          }
        )

        const userDetails = await userDetailsRes.json()

        if (userDetails.success) {
          setUser({userId, ...camelCase(userDetails)})
        } else {
          setError(true)
        }
      } catch (error) {
        setError(true)
      }
      setLoading(false)
    }

    fetchUser()

    return () => {
      isMounted = false
    }
  }, [userId, email])

  return { user, error, loading }
}

export default useUser
