import { API, Auth } from 'aws-amplify'
import moment from 'moment/moment'
import { FOI_EXCEPTION, STATUS } from './Constants'
import { camelCase } from './Helpers'

export async function createCognitoUser({
  email,
  password,
  department,
  departmentName,
  role,
  group,
}) {
  let apiName = 'AdminQueries'
  let path = '/createUser'
  let myInit = {
    body: {
      username: email.toLowerCase(),
      email: email.toLowerCase(),
      password: password,
      department: department ? department : 'N/A',
      department_name: departmentName ? departmentName : 'N/A',
      role: role ? role : 'N/A',
      group: group ? group : 'N/A',
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export async function UpdateUserAttributes({
  sub,
  department,
  departmentName,
  role,
  group,
}) {
  let apiName = 'AdminQueries'
  let path = '/updateUserAttributes'
  let myInit = {
    body: {
      username: sub,
      department: department ? department : 'N/A',
      department_name: departmentName ? departmentName : 'N/A',
      role: role ? role : 'N/A',
      group: group ? group : 'N/A',
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export async function GetUser(userId) {
  let apiName = 'AdminQueries'
  let path = `/getUser?username=${userId}`
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.get(apiName, path, myInit)
}

export async function ListGroupsForUser(userId) {
  let apiName = 'AdminQueries'
  let path = `/listGroupsForUser?username=${userId}`
  let myInit = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.get(apiName, path, myInit)
}

export async function AddUserToGroup({ email, group }) {
  let apiName = 'AdminQueries'
  let path = '/addUserToGroup'
  let myInit = {
    body: {
      username: email,
      groupname: group,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export async function RemoveUserFromGroup({ email, group }) {
  let apiName = 'AdminQueries'
  let path = '/removeUserFromGroup'
  let myInit = {
    body: {
      username: email,
      groupname: group,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export async function DisableUser(userId) {
  let apiName = 'AdminQueries'
  let path = '/disableUser'
  let myInit = {
    body: {
      username: userId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export async function EnableUser(userId) {
  let apiName = 'AdminQueries'
  let path = '/enableUser'
  let myInit = {
    body: {
      username: userId,
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  }
  return await API.post(apiName, path, myInit)
}

export function sendSubmitEmail(user, details, format) {
  const subject = `${details.transactionId}: QCitizen ${format}`

  const bodyDetails = {
    WATCH: `<strong>${details.violation}</strong>\
              <ul>${details.complaints
                ?.map((complaint) => `<li>${complaint}</li>`)
                .join('')}</ul>\
              <strong>Details of complaint:</strong> ${details.detailsOfComplaint?.replace(
                /\n/g,
                '<br/>'
              )}`,
    ACTION: `<strong>Administrative Complaint / Case against a government employee or official</strong>\
              <ul>${details.complaints
                ?.map((complaint) => `<li>${complaint}</li>`)
                .join('')}</ul>\
              <strong>Details of complaint:</strong> ${details.detailsOfComplaint?.replace(
                /\n/g,
                '<br/>'
              )}`,
    FOI: `<strong>Department/Office/Operating Unit:</strong> ${
      details.department
    }<br/>\
            <strong>Information/Documents/Records requested:</strong> ${details.requestedInformation?.replace(
              /\n/g,
              '<br/>'
            )}<br/>\
            <strong>Specific Purpose:</strong> ${details.purposeOfRequest?.replace(
              /\n/g,
              '<br/>'
            )}<br/>\
            <strong>Requested Copy:</strong> ${
              details.copy === '0' ? 'Digital Copy' : 'Physical Copy'
            }<br/>\
            <strong>Request Status:</strong> Pending`,
  }

  const body = `Hello ${user.firstName} ${
    user.middleName ? user.middleName.charAt(0) + '. ' : ''
  }${user.lastName}<br/><br/>\
  You have successfully submitted a QCitizen ${format} ${
    format === 'FOI' ? 'request' : 'report'
  }.<br/><br/>\
  <strong>Reference number:</strong> ${details.transactionId}<br/>\
  <strong>Date of ${format === 'FOI' ? 'request' : 'report'}:</strong> ${moment(
    details.createdAt
  ).format('lll')}<br/><br/>\
  ${bodyDetails[format]}\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export function sendUpdateEmail(user, details, format, prev) {
  const subject = `${details.transactionId}: QCitizen ${format}`

  const body = `Hello ${user.firstName} ${
    user.middleName ? user.middleName.charAt(0) + '. ' : ''
  }${user.lastName}<br/><br/>\
  Your QCitizen ${format} ${
    format === 'FOI' ? 'request' : 'report'
  } has been updated.<br/><br/>\
  <strong>Reference number:</strong>  ${details.transactionId}<br/>\
  <strong>Updated at:</strong> ${moment(details.updatedAt).format('lll')}<br/>\
  ${
    details.remarks && prev.remarks !== details.remarks
      ? `<strong>Remarks:</strong> ${details.remarks?.replace(
          /\n/g,
          '<br/>'
        )}<br/>`
      : ''
  }\
  ${
    format === 'FOI' && prev.copy !== details.copy
      ? `<strong>Requested Copy:</strong> ${
          details.copy === '0' ? 'Digital Copy' : 'Physical Copy'
        }<br/>`
      : ''
  }\
  ${
    format === 'FOI' && prev.status !== details.status
      ? `<strong>Request Status:</strong> ${STATUS[details.status]}<br/>`
      : ''
  }\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export function sendMessageUpdateEmail(user, details, message = '') {
  const subject = `${details.transactionId}: QCitizen FOI`

  const body = `Hello ${user.firstName} ${
    user.middleName ? user.middleName.charAt(0) + '. ' : ''
  }${user.lastName}<br/><br/>\
  Your QCitizen FOI has been updated.<br/><br/>\
  <strong>Reference number:</strong>  ${details.transactionId}<br/>\
  <strong>Updated at:</strong> ${moment(details.updatedAt).format('lll')}<br/>\
  <strong>Message:</strong> ${message}<br/>\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export function sendDeniedRequestEmail(user, details, data) {
  const subject = `${details.transactionId}: QCitizen FOI`

  const reasons = `<ul>${data?.reasons
    ?.map((complaint) =>
      complaint === 'The requested information falls within the FOI exception;'
        ? `<li>${complaint} <ol>${FOI_EXCEPTION.map(
            (exception) => `<li>${exception}</li>`
          ).join('')}</ol></li>`
        : `<li>${complaint}</li>`
    )
    .join('')}${data?.others !== '' ? `<li>${data.others}</li>` : ''}</ul>`

  const body = `Hi, Thank you for submitting an FOI request. After consideration, \
  we regret to inform you that your request has been denied.<br/><br/>Reason for denial:<br/>${
    data?.reasons.length > 1 || data?.others !== '' ? `${reasons}<br/>` : ''
  }\
  If you are dissatisfied with this response, you may submit a Verified Written Appeal within fifteen (15) \
  calendar days from the date when you received this letter denying your request, \
  or from the lapse of the Processing Period, or any extension thereof, in accordance with Section 13.1, Rule IV of the Quezon City FOI Manual.<br/><br/>\
  The Office of the City Mayor shall rule on the appeal within fifteen (15) working \
  days from the filing of the appeal. In all cases, the failure of the Office of the City Mayor \
  to rule on the appeal within the foregoing period shall be deemed a denial of the appeal.<br/><br/>\
  In case of the denial of the appeal, or the lapse of the period to decide on the appeal, \
  the Requesting Party may file the appropriate action in the proper courts in accordance with the Rules of Court.\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export function sendTransferRequestEmail(user, details) {
  const subject = `${details.transactionId}: QCitizen FOI`

  const body = `Hi, Thank you for submitting an FOI request. After consideration, \
  we would like to inform you that there are no records found that is responsive to the request as far as our Office or Department is concerned. \
  We will forward this FOI request to the appropriate Office or Department through the most expeditious manner. \
  The appropriate office will inform you that the request has already been forwarded to them and will take with the necessary action over your FOI request.\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export function sendAcceptRequestEmail(user, details, department) {
  const subject = `${details.transactionId}: QCitizen FOI`

  const body = `Hi, Thank you for submitting an FOI request. After consideration, \
  we would like to inform you that your FOI request has been forwarded to ${department}, \
  which is the most appropriate department to determine whether to release the information/document \
  that you are requesting and will take with the necessary action over your FOI request.\
  <br/><br/><br/><i>This is an automatically generated email – please do not reply.</i>`

  const options = {
    method: 'POST',
    headers: {
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    },
    body: JSON.stringify({
      email_subject: subject,
      email_body: body,
      email_to: user.email,
    }),
  }
  fetch(
    `${process.env.REACT_APP_APPOINTMENT}/qceservices_send_email_api`,
    options
  )
}

export async function getUserInfo(userId, email) {
  try {
    const headers = {
      'Content-Type': 'application/json',
      security_token: process.env.REACT_APP_SECURITY_TOKEN,
    }

    const accessTokenRes = await fetch(
      `${process.env.REACT_APP_APPOINTMENT}/generate_token`,
      {
        method: 'POST',
        headers,
      }
    )

    const accessToken = await accessTokenRes.json()

    const userDetailsRes = await fetch(
      `${process.env.REACT_APP_APPOINTMENT}/get_user_details`,
      {
        method: 'POST',
        headers,
        body: JSON.stringify({
          email,
          user_id: userId,
          qcid: true,
          access_token: accessToken.access_token,
        }),
      }
    )

    const userDetails = await userDetailsRes.json()

    console.log(camelCase(userDetails))

    return camelCase(userDetails)
  } catch (error) {
    console.log(error)
    return null
  }
}
