import { useState } from 'react'
import { HiOutlineCalendar } from 'react-icons/hi'
import { Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { INITIAL_ALERT } from '../../utilities/Constants'
import Body from '../Modal/Body'
import Button from '../Button/Button'
import Header from '../Modal/Header'
import Field from '../Field/Field'
import Footer from '../Modal/Footer'

function Appointment({ request, user }) {
  const params = {
    address: user.address,
    name: `${user.firstName} ${user.middleName} ${user.lastName}`,
    email: user.email,
    department: process.env.REACT_APP_APPOINTMENT_DEPARTMENT,
    type_id: process.env.REACT_APP_APPOINTMENT_TYPE_ID,
    user_id: user.userId,
    phone: user.mobileNumber,
    reference_no: request.transactionId,
  }

  const [alert, setAlert] = useState(INITIAL_ALERT)
  const [loading, setLoading] = useState(false)
  const [loadingCancel, setLoadingCancel] = useState(false)
  const [appointment, setAppointment] = useState(false)
  const [viewAppointment, setViewAppointment] = useState(false)
  const [data, setData] = useState({})

  const fetchAppointment = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_APPOINTMENT}/get_foi_appointment_data/${request.transactionId}`
    ).then((response) => response.json())
    setData(response)
  }

  const cancelAppointment = async (notes) => {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        reference_no: data?.appointment_data?.reference_no,
        notes: notes,
      }),
    }
    return (
      await fetch(
        `${process.env.REACT_APP_APPOINTMENT}/apply_cancel_appointment`,
        requestOptions
      )
    ).json()
  }

  useEffect(() => {
    fetchAppointment()
  }, [])

  const handleClose = () => {
    setAppointment(false)
    fetchAppointment()
  }

  const handleView = () => {
    setViewAppointment(true)
    setAlert(INITIAL_ALERT)
  }

  const handleCancel = () => {
    setLoadingCancel(true)
    cancelAppointment('Unable to pick up foi request.').then((response) => {
      setLoadingCancel(false)
      if (response.status === 'success') {
        setAlert({ show: true, message: response.message, variant: 'success' })
        fetchAppointment()
      } else {
        setAlert({ show: true, message: response.message, variant: 'danger' })
      }
    })
  }

  const handleReschedule = () => {
    setLoading(true)
    cancelAppointment('Reschedule of appointment.').then(() => {
      setLoading(false)
      document.querySelector('#close').click()
      setAppointment(true)
    })
  }

  return (
    <>
      {data.status === 'error' ||
      data.appointment_data?.status === 'Cancelled' ? (
        <button
          className="action-btn view"
          onClick={() => setAppointment(true)}
        >
          <HiOutlineCalendar />
          <span>Set an Appointment</span>
        </button>
      ) : (
        <button className="action-btn view" onClick={handleView}>
          <HiOutlineCalendar />
          <span>View Appointment</span>
        </button>
      )}
      <Modal
        show={appointment}
        onHide={handleClose}
        size="lg"
        backdrop="static"
      >
        <Header title="Set an Appointment" />
        <Body>
          <iframe
            title="Appointment System"
            src={`${process.env.REACT_APP_APPOINTMENT_FORM}/${JSON.stringify(
              params
            )}`}
            style={{ width: '100%', minHeight: 800 }}
          ></iframe>
        </Body>
      </Modal>

      <Modal
        show={viewAppointment}
        onHide={() => setViewAppointment(false)}
        backdrop="static"
      >
        <Header title="Appointment Details" />
        <Body show={alert.show} variant={alert.variant} message={alert.message}>
          <Field name="Name">{data?.appointment_data?.name}</Field>
          <Field name="Email">{data?.appointment_data?.email}</Field>
          <Field name="Mobile No">{data?.appointment_data?.phone}</Field>
          <hr />
          <Field name="Appointment Name">
            {data?.appointment_data?.appointment_name}
          </Field>
          <Field name="Appointment Reference No">
            {data?.appointment_data?.reference_no}
          </Field>
          <Field name="Appointment Address.">
            {data?.appointment_data?.appointment_address}
          </Field>
          <Field name="Appointment Date">{data?.appointment_data?.date}</Field>
          <Field name="Appointment Status">
            <span
              className={`status ${data?.appointment_data?.status.toLowerCase()}`}
            >
              {data?.appointment_data?.status}
            </span>
          </Field>
          {data.appointment_data?.status === 'Upcoming' && (
            <p className="text-danger">
              Note: Clicking the reschedule button will cancel your previous
              appointment.
            </p>
          )}
        </Body>
        <Footer>
          <Button
            color="gray"
            id="close"
            onClick={() => setViewAppointment(false)}
          >
            Close
          </Button>
          {data.appointment_data?.status === 'Upcoming' && (
            <>
              <Button
                color="red"
                loading={loadingCancel}
                onClick={handleCancel}
              >
                {loadingCancel ? ' Cancelling' : ' Cancel'}
              </Button>
              <Button color="blue" loading={loading} onClick={handleReschedule}>
                {loading ? 'Rescheduling' : 'Reschedule'}
              </Button>
            </>
          )}
          {data.appointment_data?.status === 'Missed' && (
            <Button color="blue" loading={loading} onClick={handleReschedule}>
              {loading ? 'Rescheduling' : 'Reschedule'}
            </Button>
          )}
        </Footer>
      </Modal>
    </>
  )
}

export default Appointment
