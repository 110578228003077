import Modal from 'react-bootstrap/Modal'
import { HiOutlineEye } from 'react-icons/hi'
import moment from 'moment/moment'
import uesRequest from '../../../hooks/useRequest'
import Loading from '../../Loading/Loading'
import Button from '../../Button/Button'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Request from '../../Details/Request'
import Field from '../../Field/Field'

function History({ request, action, departments, admin, size }) {
  const { state, handleView, handleClose } = uesRequest({}, request)

  return (
    <>
      {action ? (
        <Button color="blue" icon={<HiOutlineEye />} onClick={handleView}>
          View
        </Button>
      ) : (
        <Button link={true} onClick={handleView}>
          {request.transactionId}
        </Button>
      )}
      <Modal
        show={state.show}
        onHide={handleClose}
        backdrop="static"
        size={size}
      >
        <Header title="Summary of Request" />
        <Body>
          <Field name={`${request.action} by`}>{request.initiator}</Field>
          <Field name={`${request.action} at`}>
            {moment(request.createdAt).format('lll')}
          </Field>
          <hr />
          {state.loading ? (
            <Loading />
          ) : (
            <Request
              request={request}
              attachment={state.attachment}
              departments={departments}
              admin={admin}
            />
          )}
        </Body>
        <Footer>
          <Button color="gray" onClick={handleClose}>
            Close
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default History
