import {
  useState,
  useEffect
} from 'react'
import { getQCDepartment } from '../../database';
import { Form } from 'react-bootstrap';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import moment from 'moment/moment';
import Refresh from '../../components/Button/Refresh';
import Create from '../../components/Department/Form/Create';
import View from '../../components/Department/Form/View';
import Edit from '../../components/Department/Form/Edit';
import Table from '../../components/Table/Table';

function QCDepartment() {

  const [departments, setDepartments] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchDepartments()
  }, [])

  const fetchDepartments = async () => {
    try {
      const response = await getQCDepartment();
      setDepartments(response);
    } catch (error) {
      NotificationManager.error(`Problems occurred when retrieving departments. Please try again later.`);
    }
  }

  const columns = [
    {
      name: 'Code',
      selector: row => row.code,
      width: "250px",
      sortable: true
    },
    {
      id: 'Department',
      name: 'Department/Operating Unit/Office',
      sortable: true,
      selector: row => row.name,
      format: (row) => <View department={row} action={false} />,
    },
    {
      name: 'Created Date',
      width: "180px",
      sortable: true,
      selector: row => row.createdAt,
      format: (row) => moment(row.createdAt).format('lll')
    },
    {
      name:"Action",
      width: "150px",
      cell: (row) =>
        <div className='action-wrapper'>
          <Edit department={ row } setDepartments={ setDepartments } />
          <View department={ row } action={ true }/>
        </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">QCFOI Departments</h1>
      <div className="table-options">
        <div className="margin-top action-wrapper admin-nav">
          <Refresh refresh={fetchDepartments} />
        </div>
        <div className="table-filter reverse">
          <Form.Group controlId="search">
            <Form.Label>Search</Form.Label>
            <Form.Control
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search..."
            />
          </Form.Group>
          <Form.Group className="margin-top action-wrapper admin-nav">
            <Create setDepartments={setDepartments} />
          </Form.Group>
        </div>
      </div>
      <Table
        columns={columns}
        data={departments.filter((item) => {
          if (search === '') {
            return item
          } else if (
            item.code.toLowerCase().includes(search.toLowerCase()) ||
            item.name.toLowerCase().includes(search.toLowerCase())
          ) {
            return item
          }
          return ''
        })}
      />
    </section>
  )
}

export default QCDepartment
