import { useEffect, useState } from 'react'

function PasswordHelper({ state }) {
  const [valid, setValid] = useState({
    len: false,
    digit: false,
    char: false,
  })

  useEffect(() => {
    if (state.length > 7) {
      setValid((prevState) => ({ ...prevState, len: true }))
    } else {
      setValid((prevState) => ({ ...prevState, len: false }))
    }

    if (state.match(/\d/)) {
      setValid((prevState) => ({ ...prevState, digit: true }))
    } else {
      setValid((prevState) => ({ ...prevState, digit: false }))
    }

    if (state.match(/\w/)) {
      setValid((prevState) => ({ ...prevState, char: true }))
    } else {
      setValid((prevState) => ({ ...prevState, char: false }))
    }
  }, [state])

  return (
    <small className="password-helper">
      <ul>
        <li
          className={`${
            state.length > 0 ? (valid.len ? 'valid' : 'invalid') : ''
          }`}
        >
          Password must contain at least 8 characters.
        </li>
        <li
          className={`${
            state.length > 0 ? (valid.digit ? 'valid' : 'invalid') : ''
          }`}
        >
          Password must contain at least 1 digit, 0-9.
        </li>
        <li
          className={`${
            state.length > 0 ? (valid.char ? 'valid' : 'invalid') : ''
          }`}
        >
          Password must contain at least 1 alphabet, a-z or A-Z.
        </li>
      </ul>
    </small>
  )
}

export default PasswordHelper
