import { Storage } from 'aws-amplify'
import * as FileSaver from 'file-saver'
import { useState } from 'react'
import { HiOutlineDocumentDownload } from 'react-icons/hi'
import * as XLSX from 'xlsx-color'
import Button from '../../Button/Button'
import Disabled from '../../Button/Disabled'

function Export({ reports, filename }) {
  const [loading, setLoading] = useState(false)

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const exportToCSV = () => {
    setLoading(true)
    reports = reports.map(async (report) => {
      let newState = { ...report.citizen, ...report.report }
      delete newState.id
      delete newState._deleted
      delete newState._version
      delete newState._lastChangedAt
      delete newState.qcitizenID
      delete newState.userId
      delete newState.title
      delete newState.orgName
      delete newState.authority
      delete newState.occupation
      delete newState.idPresented
      newState.complaints = report.report.complaints.join(', ')
      if (report.report.attachment) {
        const attachment = await Storage.get(report.report.attachment)
        newState.attachment = attachment
      }
      if (report.report.actionTaken) {
        const actionTaken = await Storage.get(report.report.actionTaken)
        newState.remarks = newState.remarks
          ? `${newState.remarks} ${actionTaken}`
          : actionTaken
      }
      delete newState.actionTaken
      return newState
    })
    Promise.all(reports).then(function (results) {
      const ws = XLSX.utils.json_to_sheet(results)
      XLSX.utils.sheet_add_aoa(
        ws,
        [
          [
            'First Name',
            'Middle Name',
            'Last Name',
            'Mobile Number',
            'Email',
            'Address',
            'Birthday',
            'Gender',
            'QCID Number',
            'Created Date',
            'Updated Date',
            'Reference Number',
            'Complaints',
            'Details of Complaints',
            'Attachment',
            'Remarks',
          ],
        ],
        { origin: 'A1' }
      )
      const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, filename + fileExtension)
      setLoading(false)
    })
  }

  return reports.length <= 0 ? (
    <Disabled color="blue" icon={<HiOutlineDocumentDownload />}>
      Export
    </Disabled>
  ) : (
    <Button
      color="blue"
      loading={loading}
      icon={<HiOutlineDocumentDownload />}
      onClick={exportToCSV}
    >
      {loading ? 'Exporting' : ` Export`}
    </Button>
  )
}

export default Export
