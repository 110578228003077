function Disabled({
  color = 'blue',
  type = 'button',
  icon = '',
  children,
  ...props
}) {
  return (
    <button
      type={type}
      className={`action-btn ${color} disabled`}
      disabled
      {...props}
    >
      {icon}
      {children}
    </button>
  )
}

export default Disabled
