import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'

function Select({
  label,
  name,
  children,
  state,
  validated = false,
  required = false,
  margin = true,
  ...props
}) {
  const [status, setStatus] = useState({ valid: false, invalid: false })

  useEffect(() => {
    if (!required) {
      return
    }

    if (!validated) {
      return
    }

    if (state?.length > 1) {
      document.querySelector(`[name="${name}-feedback"]`).style.display = 'none'
      setStatus({ valid: true, invalid: false })
    } else if (state?.length < 1) {
      document.querySelector(`[name="${name}-feedback"]`).style.display =
        'block'
      setStatus({ valid: false, invalid: true })
    }
  }, [required, validated, state])

  return (
    <Form.Group className={`${margin ? 'mb-3' : ''}`} controlId={name}>
      <Form.Label className="semi-bold">{label}</Form.Label>
      <Form.Select
        name={name}
        required={required}
        isValid={status.valid}
        isInvalid={status.invalid}
        {...props}
      >
        {children}
      </Form.Select>
      <Form.Control.Feedback type="invalid" name={`${name}-feedback`}>
        This field is required.
      </Form.Control.Feedback>
    </Form.Group>
  )
}

export default Select
