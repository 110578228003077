import { useContext } from 'react'
import { HiOutlineSwitchHorizontal } from 'react-icons/hi'
import { Modal } from 'react-bootstrap'
import moment from 'moment-business-days'
import {
  updateQCFoiDepartment,
  createMessage,
  createQCFoiHistory,
} from '../../../database'
import { sendTransferRequestEmail } from '../../../utilities/Requests'
import useCommon from '../../../hooks/useCommon'
import { getMessage } from '../../../utilities/Helpers'
import { UserContext } from '../../../context'
import Button from '../../Button/Button'
import Disabled from '../../Button/Disabled'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Select from '../../Input/Select'
import Field from '../../Field/Field'
import NotificationManager from 'react-notifications/lib/NotificationManager'

function Forward({
  request,
  citizen,
  departments,
  refresh,
  form = false,
  setting,
}) {
  const { currentUser } = useContext(UserContext)
  const {
    state,
    handleUpdate,
    handleClose,
    handleInput,
    setLoading,
    setAlert,
  } = useCommon({ department: request.qcdepartmentID })

  const handleForward = async () => {
    setLoading()
    const deadline = moment().businessAdd(setting.expected).format('YYYY-MM-DD')
    if (request.forwardCount === parseInt(setting.limit)) {
      const response = await updateQCFoiDepartment(
        request.id,
        request.originalDepartment,
        deadline
      )
      handlePostForward(response)
      NotificationManager.success(
        `The request has been successfully transferred.`
      )
      document.querySelector('#close').click()
    } else if (request.forwardCount < parseInt(setting.limit)) {
      if (request.qcdepartmentID === state.data.department) {
        setAlert({
          variant: 'danger',
          message:
            'Please choose a different department to forward the request.',
        })
      } else {
        const response = await updateQCFoiDepartment(
          request.id,
          state.data.department,
          deadline
        )
        handlePostForward(response)
        NotificationManager.success(
          `The request has been successfully transferred.`
        )
        document.querySelector('#close').click()
      }
    }
  }

  const showModal = () => {
    document.querySelector('#request').style.display = 'none'
    handleUpdate()
  }

  const closeModal = () => {
    document.querySelector('#close').click()
    handleClose()
  }

  const handlePostForward = (response) => {
    sendTransferRequestEmail(citizen, response)
    createMessage({
      email: currentUser.attributes.email,
      qcfoiID: response.id,
      message: getMessage('forwarded'),
    })
    createQCFoiHistory(
      'Updated',
      currentUser.attributes.email,
      departments.find((x) => x.id === response.qcdepartmentID).name,
      response
    )
    refresh()
  }

  return (
    <>
      {form ? (
        <Button color="blue" title="Forward" onClick={showModal}>
          Forward
        </Button>
      ) : request.status > 2 ? (
        <Disabled
          color="blue"
          title="Forward"
          icon={<HiOutlineSwitchHorizontal />}
        >
          <span className="btn-expand">Forward</span>
        </Disabled>
      ) : (
        <Button
          color="blue"
          title="Forward"
          icon={<HiOutlineSwitchHorizontal />}
          onClick={handleUpdate}
        >
          <span className="btn-expand">Forward</span>
        </Button>
      )}
      <Modal show={state.show} onHide={closeModal} backdrop="static">
        <Header title="Forward Request" />
        <Body
          show={state.alert.show}
          variant={state.alert.variant}
          message={state.alert.message}
        >
          {request.forwardCount < setting?.limit ? (
            <>
              <Field name="Note">
                <span className="text-danger">
                  Only forward the request to the correct
                  Department/Office/Operating Unit.
                </span>
              </Field>
              <Select
                name="department"
                label="Forward to"
                onChange={handleInput}
                defaultValue={state.data.department}
                state={state.data.department}
                validated={state.validated}
                required={true}
              >
                {departments.map((department) => (
                  <option
                    key={department.id}
                    value={department.id}
                    data={department.code}
                  >
                    {department.name}
                  </option>
                ))}
              </Select>
            </>
          ) : request.forwardCount === parseInt(setting?.limit) ? (
            <p>{`This request has been forwarded ${setting.limit} times. Click 'forward' if you want to forward it to the original department it was sent to.`}</p>
          ) : (
            <p>This request has reached the limit of forwards allowed.</p>
          )}
        </Body>
        <Footer>
          {request.status > 2 ||
          request.forwardCount > parseInt(setting?.limit) ? (
            <>
              <Button color="gray" id="close" onClick={closeModal}>
                Close
              </Button>
            </>
          ) : (
            <>
              <Button color="gray" id="close" onClick={closeModal}>
                Close
              </Button>
              <Button
                color="blue"
                loading={state.loading}
                onClick={handleForward}
              >
                {state.loading ? 'Forwarding' : 'Forward'}
              </Button>
            </>
          )}
        </Footer>
      </Modal>
    </>
  )
}

export default Forward
