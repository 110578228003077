export const RequestReducer = (state, action) => {
  switch (action.type) {
    case 'HANDLE_VIEW':
      return {
        ...state,
        loading: false,
        show: true,
      }
    case 'HANDLE_CREATE':
      return {
        ...INITIAL_STATE,
        user: action.payload,
        show: true,
      }
    case 'HANDLE_UPDATE':
      return {
        ...INITIAL_STATE,
        show: true,
        request: action.payload,
      }
    case 'HANDLE_CANCEL':
      return {
        ...state,
        show: false,
      }
    case 'HANDLE_CLOSE':
      return {
        ...state,
        show: false,
      }
    case 'HANDLE_INPUT':
      return {
        ...state,
        request: {
          ...state.request,
          [action.payload.property]: action.payload.value,
        },
      }
    case 'HANDLE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.property]: action.payload.value,
        },
      }
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
        validated: false,
        alert: INITIAL_STATE.alert,
      }
    case 'SET_ALERT':
      return {
        ...state,
        loading: false,
        alert: {
          show: true,
          message: action.payload?.message,
          variant: action.payload?.variant,
        },
      }
    case 'SET_VALIDATED':
      return {
        ...state,
        validated: action?.payload ? action.payload : !state.validated,
      }
    case 'SET_ATTACHMENT':
      return {
        ...state,
        attachment: action.payload,
      }
    case 'HANDLE_CERTIFICATION':
      return {
        ...state,
        certification: action?.payload ? action.payload : !state.certification,
      }
    default:
      return state
  }
}

export const INITIAL_STATE = {
  show: false,
  alert: {
    show: false,
    variant: '',
    message: '',
  },
  loading: false,
  validated: false,
  certification: false,
  request: {
    requestedInformation: '',
    purposeOfRequest: '',
    copy: '0',
    qcdepartmentID: '',
    transactionId: '',
  },
  user: {
    authority: '',
    mobileNumber: '',
    title: '',
    orgName: '',
    idPresented: '',
    occupation: '',
  },
  attachment: '',
}

export const ACTION = {
  HANDLE_VIEW: 'HANDLE_VIEW',
  HANDLE_CREATE: 'HANDLE_CREATE',
  HANDLE_UPDATE: 'HANDLE_UPDATE',
  HANDLE_CANCEL: 'HANDLE_CANCEL',
  HANDLE_CLOSE: 'HANDLE_CLOSE',
  HANDLE_INPUT: 'HANDLE_INPUT',
  HANDLE_USER: 'HANDLE_USER',
  SET_LOADING: 'SET_LOADING',
  SET_ALERT: 'SET_ALERT',
  SET_VALIDATED: 'SET_VALIDATED',
  SET_ATTACHMENT: 'SET_ATTACHMENT',
  HANDLE_CERTIFICATION: 'HANDLE_CERTIFICATION',
}
