import Form from 'react-bootstrap/Form'
import { HiOutlineSearch } from 'react-icons/hi'
import Search from '../Button/Button'

function Button({ onClick }) {
  return (
    <Form.Group className="margin-top action-wrapper">
      <Search color="blue" icon={<HiOutlineSearch />} onClick={onClick}>
        Search
      </Search>
    </Form.Group>
  )
}

export default Button
