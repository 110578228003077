import { useReducer } from 'react'
import {
  AccountReducer,
  INITIAL_STATE,
  ACTION,
} from '../reducer/AccountReducer'

const useAccount = (account = {}) => {
  const [state, dispatch] = useReducer(AccountReducer, INITIAL_STATE)

  const handleCreate = () => {
    dispatch({
      type: ACTION.HANDLE_CREATE,
    })
  }

  const handleUpdate = () => {
    dispatch({
      type: ACTION.HANDLE_UPDATE,
      payload: {
        sub: account.sub,
        email: account.email,
        department: account.department,
        departmentName: account.departmentName,
        role: account.role,
        group: account.group,
      },
    })
  }

  const handleCancel = () => {
    dispatch({
      type: ACTION.HANDLE_CANCEL,
    })
  }

  const handleInput = (e) => {
    if (e.target.name === 'group') {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          [e.target.name]: e.target.value,
          role: e.target[e.target.selectedIndex].text,
          department: '',
          departmentName: '',
        },
      })
    } else if (e.target.name === 'department') {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          [e.target.name]: e.target.value,
          departmentName: e.target[e.target.selectedIndex].text,
        },
      })
    } else {
      dispatch({
        type: ACTION.HANDLE_INPUT,
        payload: {
          [e.target.name]: e.target.value,
        },
      })
    }
  }

  const setLoading = () => {
    dispatch({
      type: ACTION.SET_LOADING,
    })
  }

  const setAlert = (alert) => {
    dispatch({
      type: ACTION.SET_ALERT,
      payload: alert,
    })
  }

  const setValidated = (value) => {
    dispatch({
      type: ACTION.SET_VALIDATED,
      payload: value,
    })
  }

  const checkInputValidity = (required) => {
    for (let item of required) {
      if (state.account[item] === '') {
        return false
      }
    }
    return true
  }

  return {
    state,
    handleCreate,
    handleUpdate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  }
}

export default useAccount
