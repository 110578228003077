import { useContext } from 'react'
import {
  updateQCFoiDeadline,
  createQCFoiHistory,
  createExtendRecord,
} from '../../../database'
import { HiOutlineCalendar } from 'react-icons/hi'
import { Modal, Form } from 'react-bootstrap'
import { EXTEND_REASON } from '../../../utilities/Constants'
import { UserContext } from '../../../context'
import useCommon from '../../../hooks/useCommon'
import Button from '../../Button/Button'
import Disabled from '../../Button/Disabled'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Number from '../../Input/Number'
import Select from '../../Input/Select'
import Field from '../../Field/Field'
import TextArea from '../../Input/TextArea'
import moment from 'moment-business-days'
import NotificationManager from 'react-notifications/lib/NotificationManager'

function Extend({ citizen, request, departments, setRequests, setting }) {
  const { currentUser } = useContext(UserContext)

  const required = ['email', 'days', 'reason', 'qcfoiID']
  const {
    state,
    handleUpdate,
    handleCancel,
    handleClose,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  } = useCommon({
    email: currentUser.attributes.email,
    days: 3,
    reason: '',
    explanation: '',
    qcfoiID: request.id,
  })

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (
      form.checkValidity() === true &&
      checkInputValidity(required) === true
    ) {
      try {
        const response = await updateQCFoiDeadline(request, {
          deadline: moment(request.deadline)
            .businessAdd(state.data.days)
            .format('YYYY-MM-DD'),
        })
        createExtendRecord(state.data)
        createQCFoiHistory(
          'Updated',
          state.data.email,
          departments.find((x) => x.id === response.qcdepartmentID).name,
          response
        )
        setRequests((prevState) =>
          prevState.map((item) =>
            item.request.id === response.id
              ? { ...item, request: response }
              : item
          )
        )
        NotificationManager.success('Deadline has been successfully extended.')
        document.querySelector('#close').click()
      } catch (error) {
        setAlert({
          variant: 'danger',
          message:
            'Problem occurred when extending request. Please try again later.',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  return (
    <>
      {['0', '1', '4'].includes(request.status) ? (
        <Button
          color="blue"
          title="Extend"
          icon={<HiOutlineCalendar />}
          onClick={handleUpdate}
        >
          <span className="btn-expand">Extend</span>
        </Button>
      ) : (
        <Disabled color="blue" title="Extend" icon={<HiOutlineCalendar />}>
          <span className="btn-expand">Extend</span>
        </Disabled>
      )}
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="Extend Deadline" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Field
              name={`Maximun day${
                setting.extension > 1 ? 's' : ''
              } of extension`}
            >
              <span className="text-danger">
                {setting.extension} day{setting.extension > 1 ? 's' : ''}
              </span>
            </Field>
            <Field name="Date received">
              {request.recieveAt
                ? request.recieveAt
                : moment(request.createdAt).format('YYYY-MM-DD')}
            </Field>
            <Field name="Current deadline">{request.deadline}</Field>
            <Number
              name="days"
              label="Extend (Business day/s)"
              onChange={handleInput}
              defaultValue={state.data.days}
              state={parseInt(state.data.days)}
              validated={state.validated}
              min={1}
              max={setting.extension}
              required={true}
            />
            <Field name="New Deadline">
              {moment(request.deadline)
                .businessAdd(state.data.days)
                .format('YYYY-MM-DD')}
            </Field>
            <Select
              name="reason"
              label="Reason for extension"
              onChange={handleInput}
              defaultValue=""
              state={state.data.reason}
              validated={state.validated}
              required={true}
            >
              <option value="" disabled>
                Select reason
              </option>
              {EXTEND_REASON.map((reason, index) => (
                <option value={reason} key={index}>
                  {reason}
                </option>
              ))}
            </Select>
            <TextArea
              name="explanation"
              label="Others"
              onChange={handleInput}
              rows={2}
              validated={state.validated}
              state={state.data.explanation}
              value={state.data.explanation}
            />
          </Body>
          <Footer>
            <Button color="gray" id="close" onClick={handleClose}>
              Close
            </Button>
            <Button type="submit" color="blue" loading={state.loading}>
              {state.loading ? 'Extending' : 'Extend'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Extend
