import { useState, useRef, useEffect } from 'react'
import { HiOutlineMail } from 'react-icons/hi'
import { Modal, Form } from 'react-bootstrap'
import NotificationManager from 'react-notifications/lib/NotificationManager'
import { sendMessageUpdateEmail } from '../../../utilities/Requests'
import {
  getMessage,
  createMessage,
  updateQCFoiStatus,
  createQCFoiHistory,
} from '../../../database'
import Button from '../../Button/Button'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'

function Message({ request, email, citizen, side, departments, setRequests }) {
  const [show, setShow] = useState(false)
  const [messages, setMessages] = useState([])

  const message = useRef()
  const bottom = useRef()

  useEffect(() => {
    bottom.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const handleMessage = async () => {
    if (message.current.value !== '') {
      try {
        const msg = await createMessage({
          email: email,
          qcfoiID: request.id,
          message: message.current.value,
        })
        setMessages((prevState) => [...prevState, msg])
        let response
        if (side === 'citizen') {
          response = await updateQCFoiStatus(request.id, '0', 'admin')
          setRequests((prevState) =>
            prevState.map((item) => (item.id === response.id ? response : item))
          )
        } else {
          response = await updateQCFoiStatus(request.id, '1', 'citizen')
          sendMessageUpdateEmail(citizen, request, msg.message)
          setRequests((prevState) =>
            prevState.map((item) =>
              item.request.id === response.id
                ? { ...item, request: response }
                : item
            )
          )
        }
        createQCFoiHistory(
          'Updated',
          email,
          departments.find((x) => x.id === response.qcdepartmentID).name,
          response
        )
        message.current.value = ''
      } catch (error) {
        NotificationManager.error(
          `Problems occurred when sending the message. Please try again later.`
        )
      }
    }
  }

  const fetchMessage = async (e) => {
    e.preventDefault()
    try {
      const response = await getMessage(request.id)
      if (request.message === side) {
        const response = await updateQCFoiStatus(request.id, null, null)
        if (side === 'citizen') {
          setRequests((prevState) =>
            prevState.map((item) => (item.id === response.id ? response : item))
          )
        } else {
          setRequests((prevState) =>
            prevState.map((item) =>
              item.request.id === response.id
                ? { ...item, request: response }
                : item
            )
          )
        }
      }
      setMessages(response)
    } catch (error) {
      NotificationManager.error(
        `Problems occurred when retrieving messages. Please try again later.`
      )
    }
    setShow(true)
  }

  return (
    <>
      <button
        className="action-btn view message-btn"
        onClick={fetchMessage}
        title="Messages"
      >
        <HiOutlineMail />
        <span
          className={`${
            (side === 'admin' && request.message === 'admin') ||
            (side === 'citizen' && request.message === 'citizen')
              ? 'message-badge'
              : 'hide-message-badge'
          }`}
        ></span>
        <span className={`${side === 'admin' ? 'btn-expand' : ''}`}>
          Messages
        </span>
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
      >
        <Header title="Request Message" />
        <Body>
          {messages.length > 0 ? (
            <div className="messages">
              {messages.map((message, index) => (
                <p
                  key={index}
                  className={`${
                    side === 'citizen'
                      ? message.email === citizen.email
                        ? 'right'
                        : 'left'
                      : message.email === citizen.email
                      ? 'left'
                      : 'right'
                  }`}
                >
                  {message.message}
                </p>
              ))}
              <div ref={bottom} />
            </div>
          ) : (
            <div>There are no messages to display.</div>
          )}
          {request.status < 2 && (
            <Form.Group className="mb-3" controlId="message">
              <Form.Label className="semi-bold">Message</Form.Label>
              <Form.Control as="textarea" rows={2} ref={message} />
            </Form.Group>
          )}
        </Body>
        <Footer>
          <Button color="gray" onClick={() => setShow(false)}>
            Close
          </Button>
          {request.status < 2 && (
            <Button color="blue" onClick={handleMessage}>
              Send
            </Button>
          )}
        </Footer>
      </Modal>
    </>
  )
}

export default Message
