import moment from 'moment/moment'

export const STATUS = [
  'Pending',
  'Replied',
  'Forwarded',
  'Denied',
  'Approved for Processing',
  'Partially Granted',
  'Granted',
]

export const STATUS_CLASS = [
  'pending',
  'replied',
  'forwarded',
  'denied',
  'approved',
  'partially-granted',
  'granted',
]

export const INITIAL_ALERT = {
  show: false,
  message: '',
  variant: '',
}

export const INITIAL_FILTER = {
  search: '',
  start: moment().format('YYYY-MM-DD'),
  end: moment().format('YYYY-MM-DD'),
}

export const EXTEND_REASON = [
  'Requires extensive search',
  'Examination of voluminous records',
  'Occurrence of fortuitous cases',
]

export const DENY_REASON = [
  'The requested information falls within the FOI exception;',
  'The Quezon City Government is not the custodian of the information being requested.',
  'The request cannot be processed due to insufficient details/information supplied by the requester.',
  'Request is denied due to invalid purpose.',
  'This is not the right platform for the request.',
]

export const FOI_EXCEPTION = [
  'Information covered by Executive privilege;',
  'Privileged information relating to national security, defense, or international relations;',
  'Information concerning law enforcement and protection of public personal safety;',
  'Information deemed confidential for the protection of the privacy of persons and certain individuals such as minors, victims of crimes, or the accused;',
  'Information, documents or records known by reason of official capacity and are deemed as confidential, including those submitted or disclosed by entities to government agencies, tribunals, boards, or officers, in relation to the performance of their functions, or to inquiries or investigation conducted by them in the exercise of their administrative, regulatory or quasi-judicial powers;',
  'Prejudicial premature disclosure which would likely, or significantly frustrate the implementation of a proposed official action, except such department, office, agency, or City Unit has already disclosed to the public the content or nature of its proposed action, or where the department, office, agency, or City Unit is required by law to make such disclosure on its own initiative prior to taking final official action on such proposal;',
  'Records of proceedings or information from proceedings which, pursuant to law or relevant rules and regulations, are treated as confidential or privileged;',
  'Matters considered confidential under banking and finance laws, and their amendatory laws; or',
  'Other exceptions to the right of information under laws, jurisprudence, and rules and regulations such as Attorney-client privilege existing between City lawyers and their client.',
]

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const MONTHS_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const DISPLAY = ['Monthly', 'Quarterly']

export const QUARTER = [
  '1st Quarter',
  '2nd Quarter',
  '3rd Quarter',
  '4th Quarter',
]

export const QUARTER_MONTH = [
  [1, 3],
  [4, 6],
  [7, 9],
  [10, 12],
]

export const FILE_EXTENSIONS = [
  'png',
  'jpeg',
  'jpg',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'csv',
  'pdf',
]
