import { useState, useEffect } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { getFoiSetting, updateFoiSetting } from '../../database'
import Body from '../../components/Modal/Body'
import Footer from '../../components/Modal/Footer'
import Number from '../../components/Input/Number'
import useCommon from '../../hooks/useCommon'

function Settings() {
  const required = ['limit', 'expected', 'extension', 'expiration']
  const [data, setData] = useState({
    expected: '',
    extension: '',
    expiration: '',
    limit: '',
  })
  const { state, setLoading, setAlert, setValidated } = useCommon()

  useEffect(() => {
    const fetchData = async () => {
      const response = await getFoiSetting()
      setData(response)
    }

    fetchData()
  }, [])

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (form.checkValidity() === true && checkInputValidity() === true) {
      try {
        const response = await updateFoiSetting(data.id, data)
        setData(response)
        setAlert({
          variant: 'success',
          message: 'FOI Setting has been updated.',
        })
      } catch (error) {
        setAlert({
          variant: 'danger',
          message:
            'Problem occurred when updating settings. Please try again later.',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  const handleInput = (e) => {
    setData((prevState) => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  const checkInputValidity = () => {
    for (let item of required) {
      if (data[item] === '' || data[item] === '0' || data[item] === 0) {
        return false
      }
    }
    return true
  }

  return (
    <section className="admin-table">
      <h1 className="admin-title qcitizen-title">FOI Settings</h1>
      <div className="normal-form">
        <Form noValidate onSubmit={handleSubmit}>
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          ></Body>
          <Number
            name="expected"
            label="Deadline (Business day/s upon receival of request)"
            onChange={handleInput}
            value={data?.expected}
            state={data?.expected}
            validated={state.validated}
            min={1}
            max={365}
            required={true}
          />
          <Number
            name="extension"
            label="Maximum day/s of extension (Business day/s)"
            onChange={handleInput}
            value={data?.extension}
            state={data?.extension}
            validated={state.validated}
            min={1}
            max={365}
            required={true}
          />
          <Number
            name="expiration"
            label="Attachment expiration (Calendar day/s)"
            onChange={handleInput}
            value={data?.expiration}
            state={data?.expiration}
            validated={state.validated}
            min={1}
            max={365}
            required={true}
          />
          <Number
            name="limit"
            label="Forward limit"
            onChange={handleInput}
            value={data?.limit}
            state={data?.limit}
            validated={state.validated}
            min={1}
            max={15}
            required={true}
          />
          <Footer>
            <button
              type="submit"
              className={`action-btn blue full ${
                state.loading ? 'disabled' : ''
              }`}
            >
              {state.loading && (
                <Spinner animation="border" role="status" size="sm" />
              )}
              {state.loading ? ' Updating settings' : ' Update settings'}
            </button>
          </Footer>
        </Form>
      </div>
    </section>
  )
}

export default Settings
