import React from 'react'
import Form from 'react-bootstrap/Form'

function Search({ ...props }) {
  return (
    <Form.Group controlId="search">
      <Form.Label>Search</Form.Label>
      <Form.Control
        name="search"
        placeholder="Search Reference No."
        {...props}
      />
    </Form.Group>
  )
}

export default Search
