import { Modal } from 'react-bootstrap'
import { HiOutlineEye } from 'react-icons/hi'
import useReport from '../../../hooks/useReport'
import Button from '../../Button/Button'
import Header from '../../Modal/Header'
import Body from '../../Modal/Body'
import Footer from '../../Modal/Footer'
import Citizen from '../../Details/Citizen'
import Report from '../../Details/Report'

function View({ report, citizen, action, departments }) {
  const { state, handleView, handleClose, handleMouseMove } = useReport(report)

  return (
    <>
      {action ? (
        <Button color="blue" icon={<HiOutlineEye />} onClick={handleView}>
          View
        </Button>
      ) : (
        <Button link={true} onClick={handleView}>
          {report.transactionId}
        </Button>
      )}
      <Modal show={state.show} onHide={handleClose} backdrop="static">
        <Header closeButton title="Summary of Report" />
        <Body>
          {citizen && (
            <>
              <Citizen citizen={citizen} />
              <hr />
            </>
          )}
          <Report
            report={report}
            departments={departments}
            attachment={state.attachment}
            zoom={state.zoom}
            handleMouseMove={handleMouseMove}
            view="watch"
          />
        </Body>
        <Footer>
          <Button color="gray" onClick={handleClose}>
            Close
          </Button>
        </Footer>
      </Modal>
    </>
  )
}

export default View
