import { useState } from 'react'
import { HiOutlinePencilAlt } from 'react-icons/hi'
import { NotificationManager } from 'react-notifications'
import { EnableUser } from '../../../utilities/Requests'
import { updateUser } from '../../../database'
import Button from '../../Button/Button'

function Enable({ account, setUsers }) {
  const [loading, setLoading] = useState(false)

  const handleEnable = async () => {
    setLoading(true)
    try {
      await EnableUser(account.sub)
      const updatedUser = await updateUser(account.id, {
        ...account,
        enabled: true,
      })
      setUsers((prevState) =>
        prevState.map((user) =>
          user.id === updatedUser.id ? updatedUser : user
        )
      )
      NotificationManager.success(`${account.email} has been enabled.`)
    } catch (error) {
      NotificationManager.error(
        `Problems occurred while enabling ${account.email}. Please try again later.`
      )
    }
  }

  return (
    <Button
      color="blue"
      loading={loading}
      icon={<HiOutlinePencilAlt />}
      onClick={handleEnable}
    >
      Enable
    </Button>
  )
}

export default Enable
