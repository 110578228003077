import { HiOutlinePlus } from 'react-icons/hi'
import { Modal, Form } from 'react-bootstrap'
import NotificationManager from 'react-notifications/lib/NotificationManager'

import useCommon from '../../../hooks/useCommon'
import { createQCDepartment } from '../../../database'
import Body from '../../Modal/Body'
import Button from '../../Button/Button'
import Footer from '../../Modal/Footer'
import Header from '../../Modal/Header'
import Input from '../../Input/Input'

function Create({ setDepartments }) {
  const required = ['code', 'name']

  const {
    state,
    handleCreate,
    handleCancel,
    handleInput,
    setLoading,
    setAlert,
    setValidated,
    checkInputValidity,
  } = useCommon({ code: '', name: '' })

  const handleSubmit = async (e) => {
    setLoading()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
    }

    e.preventDefault()
    e.stopPropagation()

    if (
      form.checkValidity() === true &&
      checkInputValidity(required) === true
    ) {
      try {
        const response = await createQCDepartment(state.data)
        setDepartments((prevState) => [response, ...prevState])
        NotificationManager.success(
          `${response.name} has been successfully created.`
        )
        document.querySelector('#close').click()
      } catch (error) {
        setAlert({
          variant: 'danger',
          message:
            'Problem occurred when creating Department/Operating Unit/Office. Please try again later.',
        })
      }
    } else {
      setAlert({
        variant: 'danger',
        message: 'Please fill out required fields.',
      })
    }
  }

  return (
    <>
      <Button color="blue" icon={<HiOutlinePlus />} onClick={handleCreate}>
        Add
      </Button>
      <Modal show={state.show} onHide={handleCancel} backdrop="static">
        <Form noValidate onSubmit={handleSubmit}>
          <Header title="Add Department" />
          <Body
            show={state.alert.show}
            variant={state.alert.variant}
            message={state.alert.message}
          >
            <Input
              name="code"
              label="Code"
              onChange={handleInput}
              required={true}
              value={state.data.code}
              validated={state.validated}
              state={state.data.code}
            />
            <Input
              name="name"
              label="Department/Operating Unit/Office"
              required={true}
              onChange={handleInput}
              value={state.data.name}
              validated={state.validated}
              state={state.data.name}
            />
          </Body>
          <Footer>
            <Button color="red" id="close" onClick={handleCancel}>
              Cancel
            </Button>
            <Button color="blue" type="submit" loading={state.loading}>
              {state.loading ? 'Adding' : ' Add'}
            </Button>
          </Footer>
        </Form>
      </Modal>
    </>
  )
}

export default Create
